import { baseUrl } from './util';
import { cacheOrCatch } from './cacheOrCatch';
import request from '@/utils/request';
import { setLocaleQuery, efFetch } from '@/utils';

/**
 * 创建产品注册记录 （warranty注册记录）
 * @param data 请求参数
 */
export const createProductRegistration = (
    data: {
        buyTime: string;
        buyWay: string;
        productType: string;
        sn: string;
        phone?: string;
        region: string;
    },
    locale?: string,
) => {
    return request(`${baseUrl(locale)}/productRegistration${setLocaleQuery(locale)}`, {
        method: 'post',
        data,
        needToken: true,
    });
};

/**
 * 获取购买渠道列表
 */
export const getBuyWays = (params: any, locale?: string) => {
    return request(`${baseUrl(locale)}/productRegistration/buyWay/appid/list${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
    });
};

/**
 * 根据sn码获得sku
 * @param sn 产品sn
 */
export const getSkuBySN = (sn: string, locale?: string) => {
    return request.get(`${baseUrl(locale)}/snVerify/getProductName${setLocaleQuery(locale)}`, {
        params: {
            sn,
        },
    });
};

/**
 * 获取产品名称列表
 * @param name 品类名
 */
export const getProducts = (name?: string, locale?: string) => {
    return request.get(`${baseUrl(locale)}/productRegistration/productName/list${setLocaleQuery(locale)}`, {
        params: {
            name,
        },
    });
};

/**
 * 产品品类 => 2022-4-24 切换cms
 */
export const queryCatagoryTree = (params: any, locale?: string) => {
    const requestQuery = request(`${baseUrl(locale)}/productCatagory/cms/queryCatagoryTree${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
    });
    return cacheOrCatch({
        requestQuery,
        cachePath: `product/categoryTree/${locale}.json`,
    });
};

export const queryCatagoryTreeNew = (locale?: string) => {
    const requestQuery = request(`${baseUrl(locale)}/productCatagory/cms/productTree${setLocaleQuery(locale)}`, {
        method: 'get',
    });
    return cacheOrCatch({
        requestQuery,
        cachePath: `product/categoryTree/${locale}.json`,
    });
};

/**
 * 产品品类及参数配置
 */
export const productCatagory = (params: any, locale?: string) => {
    const requestQuery = request(`${baseUrl(locale)}/productCatagory/cms${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
    });
    return cacheOrCatch({
        requestQuery,
        cachePath: `product/category/${locale}/${params?.productCatagoryId}.json`,
    });
};

export const productDetailData = (params: any, locale?: string) => {
    const requestQuery = efFetch(`${baseUrl(locale)}/product/detail/data?handle=${params.handle}&locale=${locale}`, {
        method: 'get',
    });

    return requestQuery;
};

/**
 * Spu查询列表 => 2022-4-24 切换cms
 */
export const spuList = (params: any, locale?: string) => {
    const requestQuery = request(`${baseUrl(locale)}/spu/cms${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
    });
    return cacheOrCatch({
        requestQuery,
        cachePath: `product/spuList/${locale}.json`,
    });
};

/**
 * Spu查询详情=> 2022-4-24 切换cms
 */
export const spuDetail = (params: any, locale?: string) => {
    const requestQuery = request(`${baseUrl(locale)}/spu/cms/queryById${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
    });
    return cacheOrCatch({
        requestQuery,
        cachePath: `product/detail/${locale}/${params?.spuId}.json`,
    });
};

/**
 * 根据产品id查询对应的FAQ => 2022-4-24 切换cms
 */
export const productFaqBySpuId = (params: any, locale?: string) => {
    return request(`${baseUrl(locale)}/faqInfoForWeb/faqInfo/v2/bySpuId${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
    });
};

/**
 * 根据产品id查询对应的downloads(包含tutorial video）
 */
export const productDwonloads = (params: { spuIds: string; classify?: 'VIDEO_COURSE' }, locale?: string) => {
    // 解决sourceId丢失导致获取全部数据
    const fakeId = '1562721273280900000'; // 1562721273280901121
    params.spuIds = params.spuIds || fakeId;
    return request(`${baseUrl(locale)}/cms/downloadInfoForWeb/downloadInfo/v2${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
    });
};

/**
 * 配件Spu列表
 */
export const queryAccessories = (params: any, locale?: string) => {
    return request(`${baseUrl(locale)}/spu/cms/queryAccessories${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
    });
};

/**
 * 配件页查询Spu配件目录列表
 */
export const querySpuAccessoryCatagoryList = (params: any, locale?: string) => {
    return request(`${baseUrl(locale)}/spu/querySpuAccessoryCatagoryList${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
    });
};

/**
 * 获取产品名称列表
 */
export const getDevice = (params: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/productRegistration/list${setLocaleQuery(locale)}`, {
        params,
        needToken: true,
    });
};

/**
 * 获取产品名称列表 new
 */
export const getDeviceNew = (params: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/productRegistration/newPersonalCenterList${setLocaleQuery(locale)}`, {
        params,
        needToken: true,
    });
};

/**
 *  产品对比页可选下拉框值列表
 */
export const queryComparisonProductList = (params: any, locale?: string) => {
    return request(`${baseUrl(locale)}/spu/cms/queryComparisonProductList${setLocaleQuery(locale)}`, {
        method: 'get',
        responseType: 'text',
        params,
    });
};

/**
 *  产品对比页可选下拉框值列表
 */
export const queryComparisonProductInfo = (data: any, locale?: string) => {
    return request(`${baseUrl(locale)}/spu/cms/queryComparisonProductInfo${setLocaleQuery(locale)}`, {
        method: 'post',
        data,
    });
};

/*
 * 根据产品id（shopify）查询价格
 */
export const getProductShopifyPrice = (params: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/ProductCaculation/price/product${setLocaleQuery(locale)}`, {
        params,
    });
};

/**
 * 产品安规列表查询-前台
 */
export const getSafetyRegulation = (locale: string) => {
    return request.get(`${baseUrl(locale)}/productSafetyRegulation${setLocaleQuery(locale)}`, {
        params: {
            page: 1,
            size: 100,
        },
    });
};
