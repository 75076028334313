import { TagManager, FBManager, SizmekManager, BdManager, PtManager, EmarsysManager, LineManager } from '@/utils/analytics-module';
import { loginUser as getLoginUser } from '@/utils/cookie';
import { getABTestPageVersion } from '@/utils/ABTest/getABTestPageVersion';
import { sha256Func } from '@/utils/analytics-module/sha256';

const parseLocaleUrl = (url: string) => {
    const locales: any = {
        cn: 'CN',
        us: 'US',
        jp: 'JP',
        ca: 'CA',
        uk: 'UK',
        au: 'AU',
        fr: 'FR',
        de: 'DE',
        eu: 'EU',
        kr: 'KR',
        es: 'ES',
        it: 'IT',
        cis: 'RU',
        za: 'ZA',
        sa: 'SA',
        tw: 'TW',
        mx: 'MX',
        br: 'BR',
    };
    const pathArr = url.split('/');
    const pathLocale = pathArr[1].toLowerCase();
    let hasLocale = false;
    let locale = 'us';
    if (locales.hasOwnProperty(pathLocale)) {
        hasLocale = true;
        locale = pathLocale;
    }
    locale = locales[locale];
    return {
        locale,
        hasLocale,
        pathArr,
    };
};

const CONFIG: any = {
    DOMAINS: [
        'http://localhost:3005',
        'https://www.ecoflow.com',
        'https://website-uat.ecoflow.com',
        'https://website-uat-4.ecoflow.com',
        'http://website-uat-4.ecoflow.com',
        'https://website-uat-5.ecoflow.com',
        'http://website-uat-5.ecoflow.com',
        'https://release-singleweek.iot-ecoflow.com',
        'https://release-doubleweek.iot-ecoflow.com',
        'https://release-eu.ecoflow.com',
        'https://website-uat-kammel-b.ecoflow.com',
        'https://website-uat-kammel-a.ecoflow.com',
        'https://website-uat-developer.ecoflow.com',
    ],
    GTM_ID: {
        US: 'GTM-PMPQLNN',
        EN: 'GTM-PMPQLNN',
        JP: 'GTM-PDR8N7V',
        CA: 'GTM-M9TGLZ2',
        UK: 'GTM-TKM9J9C',
        AU: 'GTM-T8LK4VN',
        FR: 'GTM-M8GKLX5',
        DE: 'GTM-MXK5BQ2',
        EU: 'GTM-W3QRM8L',
        KR: 'GTM-TPSBKB2',
        ES: 'GTM-NRWKJ9R',
        IT: 'GTM-KXRK7C7',
        CN: 'GTM-TW73XTD',
        ZA: 'GTM-KV9HHJM',
        SA: 'GTM-M2TMT6F2',
        BR: 'GTM-5BFSTBWZ',
    },
    FB_ID: {
        US: '182014695629011',
        EN: '182014695629011',
        JP: '611304829798122',
        CA: '4239709826137331',
        UK: '364072588531787',
        AU: '1884128091784935',
        FR: '580944900167870',
        DE: '1089495335331428',
        EU: '438910761474318',
        KR: '302799751895503',
        ES: '426562246067781',
        IT: '3526024194287909',
        ZA: '760831741867713',
    },
    BAIDU_ID: {
        CN: 'd79f9829e485d25de39b7f266ab203db',
    },
    EM_ID: {
        US: '106C1F6822C56CE6',
        EN: '106C1F6822C56CE6',
        JP: '187B0303CEBB90E4',
        CA: '106C1F6822C56CE6',
        UK: '1CE836D8D1F0563F',
        AU: '16DBDC90A8924FFA',
        FR: '1CE836D8D1F0563F',
        DE: '1CE836D8D1F0563F',
        EU: '1CE836D8D1F0563F',
        KR: '187B0303CEBB90E4',
        ES: '1CE836D8D1F0563F',
        IT: '1CE836D8D1F0563F',
        ZA: '16DBDC90A8924FFA',
    },
    LINE_ID: {
        JP: '7a08d550-718c-4404-a781-8b48049e1384', // 仅jp
    },
};

const isValid = () => {
    const { origin, pathname } = window.location;
    const { locale } = parseLocaleUrl(pathname);
    // 台湾TW去掉埋点
    return CONFIG.DOMAINS.includes(origin) && !['TW'].includes(locale);
};

const defaultExport = {
    init: () => {
        if (isValid()) {
            window.canPushGTM = true;
            const { pathname } = window.location;
            const { locale, hasLocale, pathArr } = parseLocaleUrl(pathname);

            if (hasLocale) pathArr.splice(1, 1);
            const ABTestObj = getABTestPageVersion(locale, pathArr.join('/') || '/'); //AB测试
            const page_name = pathArr.join('/') || '/';

            window.dataLayer = window.dataLayer || [];
            const dataA1: any = {
                country_code: locale || 'US',
                page_name: pathArr.join('/') || '/',
            };
            if (ABTestObj.isABTest) {
                if (page_name === '/') {
                    //首页
                    dataA1.page_version = ABTestObj.page_version_index || '';
                } else {
                    dataA1.page_version = ABTestObj.page_version;
                }
            }
            window?.dataLayer?.push(dataA1);
            TagManager.initialize({ gtmId: CONFIG.GTM_ID[locale] || CONFIG.GTM_ID['US'] });
            FBManager.initialize({ fbId: CONFIG.FB_ID[locale] || CONFIG.FB_ID['US'] });
            SizmekManager.initialize(locale);
            PtManager.initialize(locale, pathname);
            EmarsysManager.initialize({ merchantId: CONFIG.EM_ID[locale] });
            if (locale === 'JP') {
                LineManager.initialize({ ldId: CONFIG.LINE_ID[locale] || CONFIG.LINE_ID['JP'], locale });
            }
            if (locale === 'US' || locale === 'CA') {
                // FloodlightManager.initialize({ gtmId: CONFIG.GTM_ID[locale] || CONFIG.GTM_ID['US'] });
                window?.dataLayer?.push(['js', new Date()]);
                window?.dataLayer?.push(['config', CONFIG.FB_ID[locale] || CONFIG.FB_ID['US']]);
            }
            const dataA2: any = {
                event: 'PageView',
                page_location: location.href,
                page_path: location.pathname + location.search,
                page_title: document.title || 'EcoFlow - POWER A NEW WORLD | EcoFlow',
            };
            if (ABTestObj.isABTest) {
                if (page_name === '/') {
                    //首页
                    dataA2.page_version = ABTestObj.page_version_index || '';
                } else {
                    dataA2.page_version = ABTestObj.page_version;
                }
            }
            window?.dataLayer?.push(dataA2);
            // Emarsys
            window.ScarabQueue.push(['availabilityZone', `website ${locale}`]);
            const loginUser = getLoginUser();
            if (loginUser?.token) {
                window.ScarabQueue.push(['setEmail', loginUser?.user?.email]);
            }
            window.ScarabQueue.push(['go']);
            if (['DE', 'FR'].includes(locale)) {
                const userEmail = loginUser?.user?.email || '';
                if (userEmail) {
                    const sha256Email = sha256Func(userEmail) || undefined;
                    console.log('sha256Func(userEmail)', sha256Email);
                    window?.dataLayer?.push({ sha256_email_address: sha256Email });
                } else {
                    window?.dataLayer?.push({ sha256_email_address: undefined });
                }
            }
        }
    },
    initBd: () => {
        // 初始化百度统计
        if (isValid()) {
            window.canPushGTM = true;
            BdManager.initialize({ baiduId: CONFIG.BAIDU_ID['CN'] });
        }
    },
    pushPageView: () => {
        if (isValid()) {
            window?.dataLayer?.push({
                event: 'PageView',
                page_location: location.href,
                page_path: location.pathname + location.search,
                page_title: document.title || 'EcoFlow - POWER A NEW WORLD | EcoFlow',
            });
        }
    },
    push: (dataLayer: any) => {
        const { pathname } = window.location;
        const { locale } = parseLocaleUrl(pathname);
        if ('canPushGTM' in window && isValid() && locale !== 'TW') {
            for (const key in dataLayer) {
                // 可能报错哦
                dataLayer[key] = typeof dataLayer[key] === 'string' ? dataLayer[key].replaceAll('\n', ' ') : dataLayer[key];
            }

            // 中东站埋点 ga4Event
            if (location.href.includes('/sa/') && typeof dataLayer === 'object') {
                try {
                    const ga4Data = {
                        event: 'ga4Event',
                        event_name: dataLayer.event_name || '',
                        event_parameters: dataLayer,
                    };
                    delete ga4Data.event_parameters['event'];
                    delete ga4Data.event_parameters['event_name'];
                    TagManager.dataLayer({ dataLayer: ga4Data });
                } catch (error) {}
            } else {
                TagManager.dataLayer({ dataLayer });
            }
        }
    },
    getDataLayer: ({ event = 'uaEvent', bannerName = '', position = '', type = '', eventName = 'select_banner' }: any) => {
        const ret = {
            event,
            event_name: eventName,
            banner_name: bannerName,
            position,
            button_name: type,
        };
        return ret;
    },
    EmPush: (params: any) => {
        window.ScarabQueue = window.ScarabQueue || [];
        window.ScarabQueue.push(params);
        if (!Array.isArray(window.ScarabQueue)) {
            window.ScarabQueue.push(['go']);
        }
    },
    LinePush: (type: any) => {
        const { pathname } = window.location;
        const { locale } = parseLocaleUrl(pathname);
        window?._lt &&
            window?._lt(
                'send',
                'cv',
                {
                    type,
                },
                [CONFIG.LINE_ID[locale] || CONFIG.LINE_ID['JP']],
            );
    },
};

export default defaultExport;
