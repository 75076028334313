import { Helmet } from 'react-helmet';

export default function OneTrustCookie({ locale = '' }: any) {
    // if (typeof window !== 'undefined' && window.location.href.indexOf('http://10.20.101.85:3005/') > -1) return <Helmet></Helmet>;

    return (
        <Helmet>
            {locale === 'eu' && (
                <script type="text/javascript" src="https://cdn-apac.onetrust.com/consent/4074621e-35d2-4229-a8da-da5e8d27f74e/OtAutoBlock.js"></script>
            )}
            {locale === 'eu' && (
                <script
                    src="https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script="4074621e-35d2-4229-a8da-da5e8d27f74e"
                ></script>
            )}
            {locale === 'de' && (
                <script type="text/javascript" src="https://cdn-apac.onetrust.com/consent/afc32dfc-3867-48ae-b646-349866e97417/OtAutoBlock.js"></script>
            )}
            {locale === 'de' && (
                <script
                    src="https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script="afc32dfc-3867-48ae-b646-349866e97417"
                ></script>
            )}
            {locale === 'fr' && (
                <script type="text/javascript" src="https://cdn-apac.onetrust.com/consent/82015cc0-5e02-43ab-b816-a62b6175e99a/OtAutoBlock.js"></script>
            )}
            {locale === 'fr' && (
                <script
                    src="https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script="82015cc0-5e02-43ab-b816-a62b6175e99a"
                ></script>
            )}
            {locale === 'uk' && (
                <script type="text/javascript" src="https://cdn-apac.onetrust.com/consent/5638b0ec-a07b-4f6f-b1a7-d27e840f0e12/OtAutoBlock.js"></script>
            )}
            {locale === 'uk' && (
                <script
                    src="https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script="5638b0ec-a07b-4f6f-b1a7-d27e840f0e12"
                ></script>
            )}
            {locale === 'it' && (
                <script type="text/javascript" src="https://cdn-apac.onetrust.com/consent/c41c15e6-d939-47eb-9b75-e033fb47dc5e/OtAutoBlock.js"></script>
            )}
            {locale === 'it' && (
                <script
                    src="https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script="c41c15e6-d939-47eb-9b75-e033fb47dc5e"
                ></script>
            )}
            {locale === 'es' && (
                <script type="text/javascript" src="https://cdn-apac.onetrust.com/consent/4f0236c5-f9ba-42e0-9ea4-1087d7fa4a3d/OtAutoBlock.js"></script>
            )}
            {locale === 'es' && (
                <script
                    src="https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script="4f0236c5-f9ba-42e0-9ea4-1087d7fa4a3d"
                ></script>
            )}
        </Helmet>
    );
}
