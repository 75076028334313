'use client';
export const w_desktopMax = 1440;

export const w_desktop = 1024;

export const w_tablet = 768;

export const w_mobile = 375;

// 根据宽度设定是否小于1024,用于判定是否使用移动端
export const getIsPadWidth = () => {
    let flag = false;
    if (typeof document !== 'undefined') {
        const width = document.documentElement.clientWidth;
        if (width <= w_desktop) {
            flag = true;
        }
    }
    return flag;
};

// 根据宽度设定是否小于768,用于判定是否使用移动端
export const getIsMobileWidth = () => {
    let flag = false;
    if (typeof document !== 'undefined') {
        const width = document.documentElement.clientWidth;
        if (width <= w_tablet) {
            flag = true;
        }
    }
    return flag;
};

// 根据宽度设定是否小于768,用于判定是否使用移动端
// 不包含 768
export const getIsMobileWidth2 = () => {
    let flag = false;
    if (typeof document !== 'undefined') {
        const width = document.documentElement.clientWidth;
        if (width < w_tablet) {
            flag = true;
        }
    }
    return flag;
};
