import cookie from 'react-cookies';

export const saveCookieForShopify = (href: string | undefined, locale: string) => {
    if (!locale || !href) return href;
    const isShopifyLink = href.includes(`${locale}.ecoflow.com`);
    if (!isShopifyLink) return href;
    try {
        cookie.save(
            '__ecoflow_shopify_ref',
            JSON.stringify({ brand_referrer: `EcoFlow ${locale.toUpperCase()}`, page_path_referrer: location?.pathname, timestamp: Date.now() }),
            { path: '/', domain: '.ecoflow.com' },
        );
    } catch (e) {
        console.error(e);
    }
    return href;
};
