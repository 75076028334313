import { baseUrl } from './util';
import request from '@/utils/request';
import { setLocaleQuery } from '@/utils';

/**
 * 提交空调众筹评论
 */
export const SubmitMainComment = (data: any, locale?: string) => {
    return request.post(`${baseUrl(locale)}/commentContent${setLocaleQuery(locale)}`, {
        data,
        needToken: true,
    });
};

/**
 * 新增众筹子评论
 */
export const SubmitSubComment = (data: any, locale?: string) => {
    return request.post(`${baseUrl(locale)}/commentContent/sub${setLocaleQuery(locale)}`, {
        data,
        needToken: true,
    });
};

/**
 * 获取空调众筹评论主列表
 */
export const GetMainCommentsList = (data: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/commentContent${setLocaleQuery(locale)}`, {
        params: data,
        needToken: true,
    });
};

/**
 * 众筹评论loadmore查询
 */
export const MainCommentLoadMore = (data: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/commentContent/loadMore${setLocaleQuery(locale)}`, {
        params: data,
        needToken: true,
    });
};

/**
 * 删除自己未被审批的主评论
 */
export const DeleteMainComment = (data: any, locale?: string) => {
    return request(`${baseUrl(locale)}/commentContent${setLocaleQuery(locale)}`, {
        method: 'delete',
        data: data,
        needToken: true,
    });
};

/**
 * 空调众筹子评论loadMore查询
 */
export const SubcommentLoadMore = (data: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/commentContent/subLoadMore${setLocaleQuery(locale)}`, {
        params: data,
        needToken: true,
    });
};

/**
 * 查询众筹的sku对应销量
 */
export const getBundlesSale = (data: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/crowdFunding/sku/sales${setLocaleQuery(locale)}`, {
        params: data,
    });
};

/**
 * 查询众筹页访问流量
 */
export const getAccessFlow = (data: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/crowdFunding/sku/flow${setLocaleQuery(locale)}?`, {});
};

/**
 * 查询众筹页访问流量
 */
export const postAccessFlow = (data: any, locale?: string) => {
    return request.post(`${baseUrl(locale)}/crowdFunding/sku/flow${setLocaleQuery(locale)}`, {});
};

/**
 * 更新众筹页访问流量
 */
export const updateAccessFlow = (locale?: string) => {
    return request.post(`${baseUrl(locale)}/crowdFunding/sku/flow${setLocaleQuery(locale)}`, {});
};

/**
 * 查询众筹页访问流量
 */
export const actIdGetAccessFlow = (data: any, locale?: string) => {
    return request(`${baseUrl(locale)}/crowdFunding/flow/activity${setLocaleQuery(locale)}`, {
        method: 'get',
        params: data,
    });
};

/**
 * 更新众筹页访问流量
 */
export const actIdUpdateAccessFlow = (data: any, locale?: string) => {
    return request.post(`${baseUrl(locale)}/crowdFunding/flow/activity${setLocaleQuery(locale)}`, {
        params: data,
    });
};

/**
 * 页面初始化时获取库存
 */
export const getInitInventory = (locale: string) => {
    return request.get(`${baseUrl(locale)}/crowdFunding/activityInventory${setLocaleQuery(locale)}`, {
        needToken: true,
    });
};
