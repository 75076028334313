import { getHostApi } from '@/utils';
import { getLocalStorage } from '@/utils/localStorage';

export const baseUrl = (locale = '') => {
    let appInfo: any = {};
    if (typeof window !== 'undefined') {
        appInfo = getLocalStorage('appInfo') || {};
    }

    const apiObj = getHostApi(locale || appInfo.locale);
    return apiObj.NEXT_PUBLIC_API_HOST;
};

export const loginUrl = (locale?: string) => {
    let appInfo: any = {};
    if (typeof window !== 'undefined') {
        appInfo = getLocalStorage('appInfo') || {};
    }
    const apiObj = getHostApi(locale || appInfo.locale);
    return apiObj.NEXT_PUBLIC_LOGIN_HOST;
};
