import { baseUrl } from './util';
import request from '@/utils/request';
import { setLocaleQuery } from '@/utils';

// 提交问卷
export const submitQuestion = (data: any, locale: string) => {
    return request(`${baseUrl(locale)}/web/questionnaire/saveQuestionnaire${setLocaleQuery(locale)}`, {
        method: 'post',
        data,
    });
};
