import { baseUrl } from './util';
import request from '@/utils/request';
import { setLocaleQuery } from '@/utils/setLocaleQuery';

// 获取规则
export const getBladeCalculator = (locale: string) => {
    return request.get(`${baseUrl(locale)}/bladeChecklist${setLocaleQuery(locale)}`);
};

// 创建checklist
export const createBladeCalculator = (data: any, locale: string) => {
    return request.post(`${baseUrl(locale)}/bladeChecklist${setLocaleQuery(locale)}`, { data });
};

// 发送邮件
export const updateBladeCalEmail = (data: any, locale: string) => {
    return request.put(`${baseUrl(locale)}/bladeChecklist${setLocaleQuery(locale)}`, {
        data,
    });
};
