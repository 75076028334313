import _get from 'lodash/get';
import cookie from 'react-cookies';

// 2022-11-18 因为cookie爆栈，替换cookie为localStorage
// 获取当前用户cookie
const HOMEBATTERY_REDIRECT = process.env.NEXT_PUBLIC_HOME_BATTERY_DOMAIN;

export const SHOPIFY_LOGOUT_KEY = 'shopfify_logout_source';

export const loginUser = () => {
    return cookie.load('userInfo');
    // return JSON.parse(window?.localStorage?.getItem('userInfo') || '{}');
};

export function getTopDomain(url?: string) {
    if (!url) {
        return '';
    }
    const urlObject = new URL(url);
    const domain = urlObject.hostname;

    const topLevelDomain = domain.split('.').slice(-2).join('.');
    return topLevelDomain === 'localhost' ? '' : `.${topLevelDomain}`;
}

// 用户登录，保存cookie
// IS_BATTERY_DOMAIN 是否是家储登录
export const onLogin = (user: any, IS_BATTERY_DOMAIN = false) => {
    const IS_PROD = typeof window !== 'undefined' && process.env.NEXT_PUBLIC_MODY === 'prd' && window.location.hostname === 'www.ecoflow.com';
    const IS_DEV = typeof window !== 'undefined' && window.location.origin?.startsWith('http://');
    const domain = IS_BATTERY_DOMAIN ? getTopDomain(HOMEBATTERY_REDIRECT) : IS_DEV ? '' : '.ecoflow.com';
    let opts = { path: '/' };

    if (domain) {
        opts = { ...opts, ...{ domain } };
    }
    cookie.save('userInfo', user, opts);
    if (!IS_PROD) {
        cookie.save('userInfo', user, {
            path: '/',
            domain: '.iot-ecoflow.com',
        });
    }
    // window?.localStorage?.setItem('userInfo', JSON.stringify(user || {}));
    // GA 埋点不再通过 dataLayer 明文上报 user_id，移至 cookie
    const uid = _get(user, 'user.userId', '');
    if (uid !== '') {
        cookie.save('ef_uid', uid, {
            path: '/',
            domain: '.ecoflow.com',
        });
        if (!IS_PROD) {
            cookie.save('ef_uid', uid, {
                path: '/',
                domain: '.iot-ecoflow.com',
            });
        }
    }
};

// 401 失效,删除 cookie
export const clearLoginData = () => {
    cookie.remove('userInfo', { path: '/' });
    cookie.remove('userInfo', { path: '/', domain: '.ecoflow.com' }); // 删除从shopify登陆的cookie
    cookie.remove('userInfo', { path: '/', domain: '.iot-ecoflow.com' });
    cookie.remove('us-token');

    // 移除 GA 埋点专用的 uid
    cookie.remove('ef_uid', {
        path: '/',
        domain: '.ecoflow.com',
    });

    // window?.localStorage?.removeItem('userInfo');
    // window?.localStorage?.removeItem('us-token');
    removeShopifyToken();
};

/**
 * @param locale 站点
 * @param  isFromShopify 对来自shopify的用户
 * @description 用户登出，删除cookie
 * */
export const logout = (locale = 'us', isFromShopify = false) => {
    const shopifySource = sessionStorage.getItem(SHOPIFY_LOGOUT_KEY);

    clearLoginData();

    // 对来源shopify的链接，退出后需同时退出shopify的登录
    if (shopifySource && isFromShopify) {
        sessionStorage.removeItem(SHOPIFY_LOGOUT_KEY);
        window.location.href = shopifySource;
    } else {
        window.location.href = `/${locale}/login`;
    }
};

// 用户登出，删除cookie
export const logoutBySwitchSite = (locale = 'us') => {
    cookie.remove('userInfo', { path: '/' });
    cookie.remove('userInfo', { path: '/', domain: '.ecoflow.com' });
    cookie.remove('userInfo', { path: '/', domain: '.iot-ecoflow.com' });
    cookie.remove('us-token');

    // 移除 GA 埋点专用的 uid
    cookie.remove('ef_uid', {
        path: '/',
        domain: '.ecoflow.com',
    });

    removeShopifyToken();
    window.location.href = `/${locale}`;
};

export const getAppInfo = () => {
    return cookie.load('appInfo');
    // return JSON.parse(window?.localStorage?.getItem('appInfo') || '{}');
};
export const removeAppInfo = () => {
    cookie.remove('appInfo');
    // window?.localStorage?.removeItem('appInfo');
};

// shopfiy回跳官网重定向页面，清空shopify token
export const removeShopifyToken = () => {
    return cookie.remove('shopfiy_token', { path: '/' });
    // return window?.localStorage?.removeItem('shopfiy_token');
};

// 用户是否登陆
export const getIsLogin = () => {
    const userinfo = cookie.load('userInfo');
    // const userinfo: any = JSON.parse(window?.localStorage?.getItem('userinfo') || '{}');
    return !!userinfo?.token;
};
