import { getCookieUser } from './cookieFn';
import { getBrowserIdFromCanvas } from './utils/getBrowserIdFromCanvas';
import { getCookie } from './utils/getCookie';
import { getDeviceHeight, getDeviceWidth } from './utils/getDeviceWidth';
import { getPageSource } from './utils/getPageSource';
import { getStringRandom } from './utils/getStringRandom';
import { getTraceId } from './utils/getTraceid';
import { getLocalObj } from './utils/locales';

const NEXT_PUBLIC_BH_HOST_test = 'https://burying-point-api-sit.iot-ecoflow.com';
const NEXT_PUBLIC_BH_HOST_prod = 'https://api-burying-point.ecoflow.com';
const NEXT_PUBLIC_MODY = process.env.NEXT_PUBLIC_MODY;
const NEXT_IS_PRD = NEXT_PUBLIC_MODY === 'prd' || NEXT_PUBLIC_MODY === 'staging' || NEXT_PUBLIC_MODY === 'uat';
const base1 = NEXT_IS_PRD ? NEXT_PUBLIC_BH_HOST_prod : NEXT_PUBLIC_BH_HOST_test;
const IS_OPEN_GTM = true;
let __leave_timer: any = null;
const device_id_key = 'ef_device_id_01'; // 设备ID
const package_id_key = 'ef_package_id_01'; // 访问ID
const BhStat = {
    createVisitorUrl: `${base1}/gtm1/visitor/web/create`, //创建用户
    updateVisitorUrl: `${base1}/gtm1/visitor/web/update`, //更新用户ID

    baseUrl1: `${base1}/gtm1/events-m2/web/create`, //主动型事件-GA4
    baseUrl2: `${base1}/gtm1/events-m1/web/create`, //主动型事件-自有

    createVisitTimeUrl: `${base1}/gtm1/pages/web/create`, //创建页面路径事件
    updateVisitTimeUrl: `${base1}/gtm1/pages/web/update`, //更新页面路径结束时间

    id: 'ecoflow',
    locale: '',
    page_id: '',
    hasInit: false,
    userId: '',
    // 设备ID
    getDeviceId: async () => {
        const device_id_cookie = getCookie(device_id_key); // cookie的 device_id,用于不同域名共用
        const device_id_storage = localStorage.getItem(device_id_key); //将cookie下的 device_id转到 storage
        let device_id = device_id_storage;
        // 2个id都不存在,生成一个新的id
        if (!device_id) {
            device_id = await getBrowserIdFromCanvas();
        }

        // 设置 cookie 的 device_id
        if (!device_id_cookie) {
            //document.cookie = `device_id_2=${device_id};`;
        }
        // 设置 localStorage 的 device_id
        if (!device_id_storage && device_id) {
            localStorage.setItem(device_id_key, device_id);
        }

        return device_id;
    },
    // 访问ID
    getPackageId: () => {
        let package_id = sessionStorage.getItem(package_id_key);
        if (!package_id) {
            package_id = getTraceId(32);
            sessionStorage.setItem(package_id_key, package_id);
        }

        return package_id;
    },
    // 用户ID
    getUserId: () => {
        if (BhStat.userId) {
            return BhStat.userId;
        }
        const info = getCookieUser() || {};

        BhStat.userId = info?.user?.userId || '';
        return BhStat.userId;
    },
    // 页面ID
    getPageId: () => {
        if (BhStat.page_id) {
            return BhStat.page_id;
        }
        const packageId = BhStat.getPackageId();
        //const page_id_1 = localStorage.getItem('gtm_page_id');
        const string1 = getStringRandom(15); //随机字符串
        const page_id_2 = `${packageId}_${string1}`;
        //return page_id_1 || page_id_2;
        BhStat.page_id = page_id_2;
        return page_id_2;
    },
    init: ({ locale }: { locale: string }) => {
        if (IS_OPEN_GTM) {
            BhStat.hasInit = true;
            BhStat.locale = locale;
            BhStat.page_id = BhStat.getPageId();
            BhStat.createVisitor();
            BhStat.enterPage();
            BhStat.leaveSetInterval();
        }
    },
    // 进入页面
    enterPage: async () => {
        BhStat.send(
            {
                project: BhStat.id,
                page_id: BhStat.page_id,
                device_id: await BhStat.getDeviceId(),
                package_id: BhStat.getPackageId(),
                page_href: location.href,
                source: getPageSource() || BhStat.id,
            },
            BhStat.createVisitTimeUrl,
        );
    },
    // 离开页面|更新离开时间
    leavePage: async () => {
        BhStat.send(
            {
                page_id: BhStat.page_id,
                package_id: BhStat.getPackageId(),
            },
            BhStat.updateVisitTimeUrl,
        );
    },
    // 设置定时器,每20秒发送一次
    leaveSetInterval: async () => {
        clearInterval(__leave_timer);
        __leave_timer = setInterval(() => {
            BhStat.leavePage();
        }, 10 * 1000);
    },
    push: async ({ event = 'PageView', sendUrl = '', ...reset }: any) => {
        const page_source = getPageSource() || BhStat.id;
        const device_id = await BhStat.getDeviceId();
        const data = {
            event: event,
            project: BhStat.id,
            device_id: device_id,
            package_id: BhStat.getPackageId(),
            page_id: BhStat.getPageId(),
            page_href: location.href,
            source: page_source,
            ...reset,
        };
        if (data.page_href) {
            BhStat.send(data, sendUrl);
        }

        //navigator.sendBeacon(BhStat.baseUrl1, JSON.stringify(data));
    },
    // 创建访问者
    createVisitor: async () => {
        const device_id = await BhStat.getDeviceId();
        const package_id = BhStat.getPackageId();
        const user_id = BhStat.getUserId();
        const device_w = getDeviceWidth();
        const device_h = getDeviceHeight();
        const page_source = getPageSource() || BhStat.id;
        const data = {
            project: BhStat.id,
            device_id,
            package_id,
            user_id,

            device_w,
            device_h,

            page_href: location.href,
            source: page_source,
        };

        BhStat.send(data, BhStat.createVisitorUrl);
        //navigator.sendBeacon(BhStat.baseUrl1, JSON.stringify(data));
    },
    send: async (data: any, sendUrl?: any) => {
        if (!BhStat.locale) {
            return;
        }
        const localeItem = getLocalObj(BhStat.locale);
        const app_id = localeItem.appId || 16;
        try {
            const formData = new URLSearchParams();
            for (const key in data) {
                formData.append(key, data[key]);
            }
            formData.append('app_id', `${app_id}`);
            navigator.sendBeacon(sendUrl || BhStat.baseUrl1, formData); //formData);
        } catch (e) {}
    },
};

export default BhStat;
