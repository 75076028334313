'use strict';

var _Snippets = require("./snippets");
var _require = require("./index"),
  LineManager = _require.LineManager;
var _interopRequireDefault = function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
};
var _Snippets2 = _interopRequireDefault(_Snippets);
var lineManager = {
  line: function line() {
    var snippets = _Snippets2.default.line.tags();
    var createScript = function createScript() {
      var script = document.createElement('script');
      script.innerHTML = snippets.script;
      return script;
    };
    return {
      createScript: createScript
    };
  },
  initialize: function initialize(_ref) {
    var ltId = _ref.ltId,
      locale = _ref.locale;
    var line = this.line();
    document.head.insertBefore(line.createScript(), document.head.childNodes[0]);
    _lt('init', {
      customerType: 'lap',
      tagId: ltId
    });
    _lt('send', 'pv', [ltId]);
  }
};
module.exports = lineManager;