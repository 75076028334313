'use client'

export function getCookie(cname: string) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(name)==0) return c.substring(name.length,c.length);
    }
    return "";
}

const timeA = 7 * 24 * 60 * 60 * 1000;//cookie时间;7天
export function setCookie(name: string, value: string, domain: string, path: string) {
    document.cookie = `${name}=${value};expires=${timeA};path=${path};domain=${domain};`;
}