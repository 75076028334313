interface localItem {
    [key: string]: string | number;
}

interface localList {
    [key: string]: localItem[];
}

// 未添加的语言属性，默认使用 en 的
export const localsList: localList = {
    asiaPacific: [
        {
            local: 'au',
            alia: 'en',
            region: 'Australia',
            regionText: 'Australia',
            language: 'English',
            languageText: 'English', // chooseSite 页面展示使用
            appId: 3,
            acceptLanguage: 'en-AU,en;q=0.9',
        },
        {
            local: 'cn',
            alia: 'zh',
            region: 'China',
            regionText: '中国（大陆）',
            language: 'Chinese',
            languageText: '简体中文',
            appId: 10,
            acceptLanguage: 'zh-CN,zh;q=0.9', //暂定
        },
        {
            local: 'tw',
            alia: 'zh',
            region: 'China',
            regionText: '台灣地區',
            language: 'Chinese',
            languageText: '繁体中文',
            appId: 19,
            acceptLanguage: 'zh_TW,zh;q=0.9', //暂定
        },
        {
            local: 'jp',
            alia: 'ja',
            region: 'Japan',
            regionText: '日本',
            language: 'Japanese',
            languageText: '日本語',
            appId: 5,
            acceptLanguage: 'ja-JP,ja;q=0.9',
        },
        {
            local: 'kr',
            alia: 'kr',
            region: 'Korea',
            regionForWarranty: 'South Korea',
            regionText: '한국',
            language: 'Korean',
            languageText: '한국어',
            appId: 8,
            acceptLanguage: 'ko-KR,ko;q=0.9',
        },
        {
            local: 'si',
            alia: 'si',
            region: 'Singapore',
            regionText: 'Singapore',
            language: 'English',
            languageText: 'English',
        },
        {
            local: 'in',
            alia: 'in',
            region: 'India',
            regionText: 'India',
            language: 'English',
            languageText: 'English',
        },
        // {
        //     local: 'ka',
        //     alia: 'ka',
        //     region: 'Kazakhstan',
        //     regionText: 'Kazakhstan',
        //     language: 'English',
        //     languageText: 'English',
        // },
        {
            local: 'ne',
            alia: 'ne',
            region: 'Nepal',
            regionText: 'Nepal',
            language: 'English',
            languageText: 'English',
        },
    ],
    northAmerica: [
        {
            local: 'ca',
            alia: 'en',
            region: 'Canada',
            regionText: 'Canada',
            language: 'English',
            languageText: 'English',
            appId: 4,
            acceptLanguage: 'en-CA,en;q=0.9',
        },
        {
            local: 'us',
            alia: 'en',
            region: 'United States',
            regionText: 'United States',
            language: 'English',
            languageText: 'English',
            appId: 1,
            acceptLanguage: 'en-US,en;q=0.9',
        },
        {
            local: 'me',
            alia: 'me',
            region: 'Mexico',
            regionText: 'México',
            language: 'Mexican',
            languageText: 'Español',
        },
    ],
    europe: [
        {
            local: 'uk',
            alia: 'en',
            region: 'United Kingdom',
            regionText: 'United Kingdom',
            language: 'English',
            languageText: 'English',
            appId: 2,
            acceptLanguage: 'en-UK,en;q=0.9',
        },
        {
            local: 'fr',
            alia: 'fr',
            region: 'France',
            regionText: 'France',
            language: 'French',
            languageText: 'français',
            appId: 6,
            acceptLanguage: 'fr-FR,fr;q=0.9',
        },
        {
            local: 'de',
            alia: 'de',
            region: 'Germany',
            regionText: 'Deutschland',
            language: 'German',
            languageText: 'Deutsch',
            appId: 7,
            acceptLanguage: 'de-DE,de;q=0.9',
        },
        {
            local: 'eu',
            alia: 'eu',
            region: 'Europe',
            regionText: 'Europe',
            language: 'Europe',
            languageText: 'Europe',
            appId: 9,
            acceptLanguage: 'en-EU,en;q=0.9',
        },
        {
            local: 'cis',
            alia: 'cis',
            region: 'Russia',
            regionText: 'CIS',
            language: 'Russian',
            languageText: 'Русский',
            appId: 12,
            acceptLanguage: 'ru-RU,ru;q=0.9',
        },
        {
            local: 'es',
            alia: 'es',
            region: 'Spain',
            regionText: 'España',
            language: 'Spain',
            languageText: 'Español',
            appId: 11,
            acceptLanguage: 'es-ES,es;q=0.9',
        },
        {
            local: 'it',
            alia: 'it',
            region: 'Italy',
            regionText: 'Italia',
            language: 'Italian',
            languageText: 'Italian',
            appId: 13,
            acceptLanguage: 'it-IT,it;q=0.9',
        },
    ],
    europe_ad: [
        {
            local: 'pl',
            alia: 'pl',
            region: 'Polska',
            regionText: 'Polska',
            language: 'Polski',
            languageText: 'Polski',
            appId: 21,
            acceptLanguage: 'pl-PL,pl;q=0.9',
        },
        {
            local: 'se',
            alia: 'se',
            region: 'Sverige',
            regionText: 'Sverige',
            language: 'svenska',
            languageText: 'svenska',
            appId: 22,
            acceptLanguage: 'sv-SE,sv;q=0.9',
        },
        {
            local: 'tr',
            alia: 'tr',
            region: 'Türkiye',
            regionText: 'Türkiye',
            language: 'Turkish',
            languageText: 'Turkish',
        },
        {
            local: 'ch',
            alia: 'ch',
            region: 'Schweiz',
            regionText: 'Schweiz',
            language: 'Deutsch',
            languageText: 'Deutsch',
            appId: 23,
            acceptLanguage: 'de-CH,de;q=0.9',
        },
        {
            // 代理站点
            local: 'nl',
            alia: 'nl',
            region: 'Nederland',
            regionText: 'Nederland',
            language: 'Nederlands',
            languageText: 'Nederlands',
        },
        {
            local: 'nz',
            alia: 'nz',
            region: 'New Zealand',
            regionText: 'New Zealand',
            language: 'English',
            languageText: 'English',
        },
        {
            // 格鲁吉亚
            local: 'ge',
            alia: 'ge',
            region: 'Georgia',
            regionText: 'საქართველო',
            language: 'Georgian',
            languageText: 'ქართული',
            appId: 24,
            acceptLanguage: 'ka-GE,ka;q=0.9',
            allSite: ['cis'],
        },
    ],
    africa: [
        {
            local: 'za',
            alia: 'za',
            region: 'South Africa',
            regionText: 'South Africa',
            language: 'English',
            languageText: 'English',
            appId: 15,
            acceptLanguage: 'en-ZA,en;q=0.9',
        },
        {
            local: 'uae',
            alia: 'uae',
            region: 'UAE',
            regionText: 'UAE',
            language: 'English',
            languageText: 'English',
        },
        {
            local: 'an',
            alia: 'an',
            region: 'Angola',
            regionText: 'Angola',
            language: 'Angolan',
            languageText: 'Português',
        },
        {
            local: 'ta',
            alia: 'en',
            region: 'Tanzania',
            regionText: 'Tanzania',
            language: 'English',
            languageText: 'English',
        },
        {
            local: 'mo',
            alia: 'mo',
            region: 'Morocco',
            regionText: 'المغرب',
            language: 'Moroccan',
            languageText: 'français',
        },
        {
            local: 'sa-ar',
            alia: 'sa-ar',
            region: ' المملكة العربية السعودية',
            regionText: ' المملكة العربية السعودية',
            language: 'العربية',
            languageText: 'العربية',
            // appId: 16,
            // acceptLanguage: 'sa_AR,sa;q=0.9',
            // acceptLanguage: 'sa-AR,sa;q=0.9',
        },
        {
            local: 'sa',
            alia: 'sa',
            region: 'الشرق الأوسط',
            regionText: 'الشرق الأوسط',
            language: 'الشرق الأوسط',
            languageText: 'العربية',
            appId: 16,
            // acceptLanguage: 'sa_AR,sa;q=0.9',
            acceptLanguage: 'sa-AR,sa;q=0.9',
        },
    ],
    latin: [
        {
            local: 'br',
            alia: 'br',
            region: 'Brazil',
            regionText: 'brasil',
            language: 'Portuguese',
            languageText: 'Português',
            appId: 18,
            acceptLanguage: 'pt-BR,pt;q=0.9',
        },
    ],
    other: [
        {
            local: 'other',
            alia: 'en',
            region: 'Other Regions',
            regionText: 'Other Regions',
            language: 'English',
            languageText: 'English',
        },
    ],
};
