import { getCountryRegion } from './getCountryRegion';

const API_HOST1 = process.env.NEXT_PUBLIC_API_HOST;
const LOGIN_HOST1 = process.env.NEXT_PUBLIC_LOGIN_HOST;
const API_HOST2 = process.env.NEXT_PUBLIC_API_HOST_CN;
const LOGIN_HOST2 = process.env.NEXT_PUBLIC_LOGIN_HOST_CN;
const API_HOST3 = process.env.NEXT_PUBLIC_API_HOST_EU;
const LOGIN_HOST3 = process.env.NEXT_PUBLIC_LOGIN_HOST_EU;

export const getHostApi = (locale: string) => {
    const obj = {
        NEXT_PUBLIC_API_HOST: API_HOST1,
        NEXT_PUBLIC_LOGIN_HOST: LOGIN_HOST1,
    };

    if (getCountryRegion(locale) == 'cn') {
        obj.NEXT_PUBLIC_API_HOST = API_HOST2;
        obj.NEXT_PUBLIC_LOGIN_HOST = LOGIN_HOST2;
    }

    if (getCountryRegion(locale) == 'eu') {
        obj.NEXT_PUBLIC_API_HOST = API_HOST3;
        obj.NEXT_PUBLIC_LOGIN_HOST = LOGIN_HOST3;
    }

    return obj;
};
