'use client';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLink from 'lowcode/components/EditorLink';

export default function ClientComp({ data = {} }: any) {
    const { link, floatPc, floatH5 } = data;

    return (
        <>
            <EditorLink href={link} className="glo-float-link">
                <EditorImg pc={floatPc} h5={floatH5} />
            </EditorLink>
        </>
    );
}
