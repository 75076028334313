'use client';
export const getBrowserIdFromCanvas = async () => {
    if (typeof window === 'undefined') {
        return '';
    }
    // 创建 Canvas 元素
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

    if (!ctx) {
        throw new Error('无法获取 Canvas 上下文');
    }

    // 在 Canvas 上绘制文字和形状
    ctx.textBaseline = 'top';
    ctx.font = "16px 'Arial'";
    ctx.textBaseline = 'alphabetic';
    ctx.fillStyle = '#f60';
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = '#069';
    const DividendsObj = {
        fr: 'Dividendes élevés',
        de: 'Dividenden',
        kr: '높은 배당금',
        cis: 'Высокие дивиденды',
        arab: 'أرباح عالية', //阿拉伯
        persia: 'تقسیم', //波斯
        belarus: 'Дывідэнты',
        Sanskrit: 'विभागाः', //梵语
        Thai: 'เงินปันผลสูง', //泰语
        baluchi: 'غیر فعال کتن ذخیره په ـدیسک', //俾路支语
    };
    const DividendsText = Object.values(DividendsObj);

    ctx.fillText(`https://www.ecoflow.com 正浩创新 ${DividendsText.join('-')}`, 2, 15);

    // 获取 Canvas 数据
    const canvasData: string = canvas.toDataURL();

    // navigator 特性
    const navigatorArray = [];
    for (const key in navigator) {
        const value = (navigator as any)[key];
        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
            navigatorArray.push(value);
        }
    }

    const userAgent: string = navigator.userAgent; // 用户代理
    const language: string = navigator.language; // 语言设置
    const whA = `${screen.width}x${screen.height}`; // 屏幕分辨率
    const whB = `${window.innerWidth}x${window.innerHeight}`; // 浏览器宽高

    // 网络
    const connectionA = (navigator as any).connection || {};
    const effectiveType = `${connectionA.effectiveType}`;
    // screen 特性
    const screenArray = [];
    for (const key in screen) {
        const value = (screen as any)[key];
        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
            screenArray.push(value);
        }
    }
    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone || 'unknown'; // 时区名称
    const timezoneOffset: number = new Date().getTimezoneOffset(); // 时区偏移量（分钟）
    // 整合所有特性数据
    const data: string = [
        userAgent,
        language,
        navigatorArray.join('-'),
        screenArray.join('-'),
        whA,
        whB,
        timezone,
        timezoneOffset,
        canvasData,
        effectiveType,
    ].join('###');

    // 使用 SHA-256 生成哈希
    return await hashSHA256(data);
};

/**
 * 使用 Web Crypto API 计算 SHA-256 哈希值
 * @param data - 输入字符串
 * @returns 哈希值的十六进制字符串
 */
async function hashSHA256(data: string): Promise<string> {
    const encoder = new TextEncoder();
    const buffer = encoder.encode(data);
    const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
}

// 使用示例
// getBrowserIdFromCanvas().then(fingerprint => {
//     console.log("浏览器指纹 ID:", fingerprint);
// });
