// 待补充
export enum REGION_ID_ENUM {
    us = '3',
    eu = '5',
    ca = '4',
    au = '11',
    uk = '6',
    kr = '10',
    de = '7',
    fr = '8',
    jp = '9',
    cn = '12',
    tw = '19',
    br = '18',
}

export const i18nMap: any = {
    us: 'en-us',
    ca: 'en-ca',
    uk: 'en-uk',
    eu: 'en-eu',
    za: 'en-za',
    au: 'en-au',
    de: 'de-de',
    fr: 'fr-fr',
    es: 'es-es',
    it: 'it-it',
    jp: 'ja-jp',
    kr: 'ko-kr',
    cn: 'zh-cn',
    ru: 'ru-ru',
    cis: 'ru-ru',
    nl: 'nl-nl',
    tw: 'zh-tw',
    sa: 'sa',
    br: 'pt-br',
};

// 各国货币符号 参考shopify 不用担心背锅
export const i18nCCYMap: any = {
    us: '$',
    ca: '$',
    uk: '£',
    eu: '€',
    za: 'R', // 有空格
    au: '$',
    de: '€',
    fr: '€', // 在后面
    es: '€',
    it: '€',
    jp: '¥',
    kr: '₩',
    cn: '¥',
    ru: '₽',
    cis: '₽',
    nl: 'ƒ',
    tw: 'NT$',
    br: 'R$',
};

// 各国货币简称 参考文档提供的payment currency, 不用担心背锅+1
export const i18nCCYCodeMap: any = {
    us: 'USD',
    ca: 'CAD',
    uk: 'GBP',
    eu: 'EUR',
    za: 'ZAR',
    au: 'AUD',
    de: 'EUR',
    fr: 'EUR',
    es: 'EUR',
    it: 'EUR',
    jp: 'JPY',
    kr: 'KRW',
    cn: 'CNY',
    ru: 'RUB',
    cis: 'RUB',
    nl: 'NZD',
    tw: 'TWD',
    br: 'BRL',
};

// regionHeader
export const regionPopupI18n: Record<string, any> = {
    us: {
        title: 'Region selection',
        text: 'Choose another country or region to get content and online shopping options that are appropriate for your location.',
        choose: 'Choose your country or region',
        confirm: 'Continue',
    },
    jp: {
        title: '国または地域を選択',
        text: 'お住まいの国や地域に適したコンテンツやオンラインショッピングのオプションを表示します。',
        choose: '国または地域を選択',
        confirm: '続ける',
    },
    cn: {
        title: '国家或者地区选项',
        text: '选择另一个国家或地区以获得适合您所在位置的内容和在线购物选项。',
        choose: '选择您的国家或者地区',
        confirm: '继续',
    },
    de: {
        title: 'Regionsauswahl',
        text: 'Wählen Sie ein anderes Land oder eine andere Region aus, um Inhalte und Online-Einkaufsoptionen zu erhalten, die für Ihren Standort geeignet sind.',
        choose: 'Land oder Region auswählen',
        confirm: 'Weitermachen',
    },
    fr: {
        title: 'Sélection de la région',
        text: `Choisissez un autre pays ou une autre région pour obtenir du contenu et des options d'achat en ligne adaptés à votre emplacement.`,
        choose: 'Choisissez votre pays ou région',
        confirm: 'Continuer',
    },
    it: {
        title: 'Selezione della regione',
        text: 'Scegli un altro paese o regione per ottenere contenuti e opzioni di acquisto online appropriate per la tua posizione.',
        choose: 'Scegli il tuo paese o regione',
        confirm: 'Continua',
    },
    es: {
        title: 'Selección de región',
        text: 'Elija otro país o región para obtener contenido y opciones de compras en línea que sean apropiadas para su ubicación.',
        choose: 'Elija su país o región',
        confirm: 'Continuar',
    },
    ru: {
        title: 'Выбор региона',
        text: 'Выберите другую страну или регион, чтобы получить контент и варианты покупок в Интернете, подходящие для вашего местоположения.',
        choose: 'Выберите страну или регион',
        confirm: 'Продолжать',
    },
    cis: {
        title: 'Выбор региона',
        text: 'Выберите другую страну или регион, чтобы получить контент и варианты покупок в Интернете, подходящие для вашего местоположения.',
        choose: 'Выберите страну или регион',
        confirm: 'Продолжать',
    },
    kr: {
        title: '지역 선택',
        text: '귀하의 위치에 적합한 콘텐츠 및 온라인 쇼핑 옵션을 얻으려면 다른 국가 또는 지역을 선택하십시오.',
        choose: '국가 또는 지역을 선택하십시오',
        confirm: '계속하다',
    },
    sa: {
        title: 'اختيار المنطقة',
        text: 'اختر بلدًا أو منطقة أخرى للحصول على المحتوى وخيارات التسوق عبر الإنترنت المناسبة لموقعك.',
        choose: 'اختر بلدك أو منطقتك',
        confirm: 'يكمل',
    },
    tw: {
        title: '國家或者地區選項',
        text: '選擇另一個國家或地區以獲得適合您所在位置的內容和在線購物選項。',
        choose: '選擇您的國家或者地區',
        confirm: '繼續',
    },
    br: {
        title: 'seleção de região',
        text: 'Escolha outro país ou região para obter conteúdo e opções de compras on-line apropriados para sua localização.',
        choose: 'Escolha seu país ou região',
        confirm: 'Continuar',
    },
};
