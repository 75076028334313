'use client';
import EditorImg from 'lowcode/components/EditorImg';
import EditorMessage from 'lowcode/components/EditorMessage';
import { useState } from 'react';
import { fetchGet, fetchPost } from 'lowcode/utils/service/FetchClient';
import { baseUrl, loginUrl } from 'lowcode/utils/service/baseUrl';
import IconClose from '../IconClose';
import ClientStyle from '../ClientStyle';
import IconSelect from './IconSelect';
import styles from './index.module.scss';
import textLang from './textLang';
import getLocal from '@/app/utils/getLocal';
import gtm from '@/utils/gtm';

// 将'1,2,3'转成[1,2,3]
const getMultipleValues = (value: string | number) => {
    const stringVal = `${value}`;
    const arr = stringVal.split(',');
    const ret = [];
    for (let i = 0; i < arr.length; i++) {
        ret.push(parseFloat(arr[i]));
    }

    return ret;
};
// 获取字段列表
const getWinFields = (values: any) => {
    const ret: any = [];
    const keys = ['Single1', 'Single2', 'Single3', 'Text1', 'Text2', 'Text3', 'Multiple1', 'Multiple2', 'Multiple3'];
    for (let i = 0; i < keys.length; i++) {
        const keyItem = keys[i];
        const typeVal = keyItem.slice(0, -1).toLocaleLowerCase();
        // 不能在这边组合,要不然填过一次的表单无法初始化
        const value1 = values[`${keyItem}-ID`];
        let value2: any = values[`${keyItem}-Value`] || '';
        const value3 = values[`${keyItem}-Overwrite`] || false;
        if (typeVal === 'multiple') {
            value2 = getMultipleValues(value2 || ['']);
        } else if (typeVal === 'text') {
            value2 = values[`${keyItem}-Value`] || '';
        } else {
            value2 = parseFloat(values[`${keyItem}-Value`] || '') || '';
        }
        if (value1 && value2) {
            ret.push({
                type: typeVal,
                id: value1,
                value: value2,
                overwrite: value3,
            });
        }
    }

    return ret;
};

const emailReg = /^[A-Za-z\d]+([.%~`$^*&(){}<>?"+=!_\\-]*[A-Za-z\d-])*@[a-zA-Z\d-]{1,30}\.[a-zA-Z\d.%#~`$^*&(){}<>?"+=!_\\-]{1,30}$/;
export default function Content({ id, data = {}, callback, isEditStatus, initShow }: any) {
    const {
        title,
        titleColor,
        subTitle,
        subTitleColor,
        desc,
        descColor,
        winBtnText,
        winBtnTextColor,
        winBtnTextBgColor,
        winPc,
        winH5,
        protocolCheckout,
        protocolUseDefault,
        emarsysFields,
        protocolLinkType,
        protocolCustomText,
        winSuccessType,
        winSuccessTipsText,
        winSuccessPc,
        winSuccessH5,
        winSuccessTipsTitle,
        winSuccessTipsTitleColor,
        winSuccessTipsDesc,
        winSuccessTipsDescColor,
    } = data;
    const { locale } = getLocal();
    const textA = textLang[locale] || textLang['us'];
    const [tipsType, setTipsType] = useState('');
    const [emailValue, setemailValue] = useState('');
    const [isCheck, setIsCheck] = useState(false);
    const [isRegister, setIsRegister] = useState(false); //是否注册
    const [successType, setSuccessType] = useState(''); //是否订阅成功

    // 切换隐私协议
    const toggleProtocolHandle = () => {
        const isCheck2 = !isCheck;
        setIsCheck(isCheck2);
    };

    // 邮箱输入
    const emailChangeHandle = (e: any) => {
        const value = e.target.value;
        setemailValue(value);
        setTipsType('');
    };

    // 发起请求-是否已注册
    const sendSetIsRegister = async () => {
        const val = emailValue.trim();
        const res = await fetchGet(`${loginUrl(locale)}/usercenter/inuc/checkEmail?email=${val}`, locale);
        if (res.code === '0') {
            setIsRegister(true);
        } else {
            setIsRegister(false);
        }
    };

    // 发起请求-提交订阅
    const sendSubscribe = async (emarsysFieldsA: any, filedsObj: any) => {
        const res = await fetchPost(`${baseUrl(locale)}/subscribe/shopify`, locale, {
            email: emailValue.trim(),
            contactListId: emarsysFieldsA.contactListId,
            fields: filedsObj,
        });
        if (res?.code === '0') {
            gtm.push({
                event: 'uaEvent',
                event_name: 'activity_submit',
                button_name: textA.btn_text,
                status: 'success',
                tag: 'Agree',
            });
            setTipsType('success');
            if (winSuccessType === 'tipsText') {
                setSuccessType('success-text');
            } else if (winSuccessType === 'tipsWin') {
                setSuccessType('success'); //成功订阅
            }

            const elemA = document.querySelector(`.glo-editor-modal-${id}`);
            if (elemA) {
                elemA.classList.remove('glo-editor-modal-show');
            }
        } else {
            gtm.push({
                event: 'uaEvent',
                event_name: 'activity_submit',
                button_name: textA.btn_text,
                status: 'fail',
                tag: 'Agree',
            });
        }
    };

    // 提交
    const submitHandle = async () => {
        const emarsysFieldsA = emarsysFields || {};
        const filedsObj = getWinFields(emarsysFieldsA);

        // 需要点击同意且没有同意
        if (protocolCheckout && !isCheck) {
            return setTipsType('fail-no_check');
        }
        if (!emailValue) {
            return setTipsType('fail-empty');
        }
        if (!emailReg.test(emailValue)) {
            return setTipsType('fail-reg');
        }

        try {
            await sendSubscribe(emarsysFieldsA, filedsObj);
            //await sendSetIsRegister();
        } catch (e) {
            gtm.push({
                event: 'uaEvent',
                event_name: 'activity_submit',
                button_name: textA.btn_text,
                status: 'fail',
                tag: 'Agree',
            });
        }
        //callback && callback();
    };

    // 关闭成功弹窗
    const closeSuccessHandle = () => {
        setSuccessType('');
    };
    // 成功回调
    const messageCallback = () => {
        setSuccessType('');
    };

    return (
        <>
            <ClientStyle id={id} data={data} />
            <div className={`glo-editor-modal${isEditStatus || initShow ? ' glo-editor-modal-show' : ''} ${styles.content} glo-editor-modal-${id}`}>
                <div className="glo-editor-modal-wrap">
                    <div className="glo-editor-modal-content">
                        <div className="sales-bg">
                            <EditorImg pc={winPc} h5={winH5} />
                        </div>
                        <IconClose id={id} callback={callback} />
                        <div className="glo-editor-modal-body sales-messages">
                            {subTitle && (
                                <div
                                    className="sales-subTitle"
                                    style={{
                                        color: subTitleColor,
                                    }}
                                >
                                    {subTitle}
                                </div>
                            )}
                            {title && (
                                <div
                                    className="sales-title"
                                    style={{
                                        color: titleColor,
                                    }}
                                >
                                    {title}
                                </div>
                            )}
                            {desc && (
                                <div
                                    className="sales-desc"
                                    style={{
                                        color: descColor,
                                    }}
                                >
                                    {desc}
                                </div>
                            )}
                            <div className="emarsys-input-mod">
                                <div className="emarsys-input-mod-box">
                                    <div className="emarsys-input-mod-l">
                                        <input
                                            className="emarsys-input-mod-input"
                                            placeholder={textA.site_bottom_subscription_email}
                                            onChange={emailChangeHandle}
                                        />
                                    </div>
                                    <div
                                        className="emarsys-input-mod-r"
                                        onClick={submitHandle}
                                        style={{
                                            color: winBtnTextColor,
                                            backgroundColor: winBtnTextBgColor,
                                        }}
                                    >
                                        {winBtnText || textA.btn_text}
                                    </div>
                                </div>
                                <div className={`emarsys-input-mod-tips emarsys-input-mod-tips-${tipsType}`}>
                                    <div className="emarsys-input-mod-tips-fail emarsys-input-mod-tips-no_check">{textA.site_bottom_subscription_tip_five}</div>
                                    <div className="emarsys-input-mod-tips-fail emarsys-input-mod-tips-tips_error_1">
                                        {textA.site_bottom_subscription_tip_two}
                                    </div>
                                    <div className="emarsys-input-mod-tips-success">{textA.site_bottom_subscription_tip_three}</div>
                                </div>
                                <div className={`emarsys-input-mod-protocol${isCheck ? ' emarsys-input-mod-check' : ''}`} onClick={toggleProtocolHandle}>
                                    {protocolCheckout && (
                                        <div className="emarsys-input-mod-protocol-l">
                                            <IconSelect />
                                        </div>
                                    )}
                                    <div className="emarsys-input-mod-protocol-r">
                                        {protocolUseDefault && (
                                            <>
                                                <div
                                                    className="emarsys-input-mod-protocol-a1"
                                                    dangerouslySetInnerHTML={{
                                                        __html: textA.protocol,
                                                    }}
                                                ></div>
                                            </>
                                        )}
                                        {!protocolUseDefault && (
                                            <>
                                                <div
                                                    className="emarsys-input-mod-protocol-a2"
                                                    dangerouslySetInnerHTML={{
                                                        __html: protocolCustomText,
                                                    }}
                                                ></div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isRegister && (
                <div className={`glo-editor-modal glo-editor-modal-show ${styles.resisterWin}`}>
                    <div className="glo-editor-modal-wrap">
                        <div className="glo-editor-modal-content">
                            <div className="sales-bg">
                                <EditorImg pc="https://websiteoss.ecoflow.com/_next/static/media/2_2.1419863d.png" />
                            </div>
                            <IconClose callback={closeSuccessHandle} />
                            <div className="glo-editor-modal-body sales-messages">
                                <div className="sales-title">{textA.register_win_title}</div>
                                <div
                                    className="sales-desc"
                                    dangerouslySetInnerHTML={{
                                        __html: textA.register_win_desc1,
                                    }}
                                ></div>
                                <div className="reg-win-desc2">{textA.register_win_desc2}</div>
                                <a href={`/${locale}/register`} className="reg-win-btn">
                                    {textA.register_win_btn}
                                </a>
                                <div className="reg-win-tips">{textA.register_win_tips}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {successType === 'success' && !isRegister && (
                <div className={`glo-editor-modal glo-editor-modal-show ${styles.content}`}>
                    <div className="glo-editor-modal-wrap">
                        <div className="glo-editor-modal-content">
                            <div className="sales-bg">
                                <EditorImg pc={winSuccessPc} h5={winSuccessH5} />
                            </div>
                            <IconClose callback={closeSuccessHandle} />
                            <div className="glo-editor-modal-body sales-messages">
                                <div
                                    className="sales-title"
                                    style={{
                                        color: winSuccessTipsTitleColor,
                                    }}
                                >
                                    {winSuccessTipsTitle}
                                </div>
                                <div
                                    className="sales-desc"
                                    style={{
                                        color: winSuccessTipsDescColor,
                                    }}
                                >
                                    {winSuccessTipsDesc}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <EditorMessage title={winSuccessTipsText} isShow={successType === 'success-text' && !isRegister} callback={messageCallback} />
        </>
    );
}
