'use client';

export function getCookie(cname: string) {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        const c = ca[i].trim();
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return '';
}

const timeA = 7 * 24 * 60 * 60 * 1000; //cookie时间;7天
export function setCookie(name: string, value: string, domain: string, path: string) {
    document.cookie = `${name}=${value};expires=${timeA};path=${path};domain=${domain};`;
}

export const getToken = () => {
    try {
        const userInfo = decodeURIComponent(getCookie('userInfo'));
        return JSON.parse(userInfo).token;
    } catch (err) {
        console.log('getToken error', err);
        return '';
    }
};

// admin 端的 token
export const getTokenAdmin = () => {
    try {
        return getCookie('token');
    } catch (err) {
        console.log('getToken error', err);
        return '';
    }
};

export const removeCookie = (name: string, path = '/', domain = '') => {
    let cookieString = `${name}=; Path=${path}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    if (domain) {
        cookieString += `Domain=${domain};`;
    }
    document.cookie = cookieString;
};
