'use client';
import gtm from '@/utils/gtm';

import BhStat from 'lowcode/BhStat';
import { getPageSource } from 'lowcode/BhStat/getPageSource';
import { getRealPathname } from 'lowcode/BhStat/getRealPathname';
import { useRouter } from 'next/router';
import styles from './index.module.scss';
import { saveCookieForShopify } from '@elem/ui-common/utils/CookieForShopify';

const uaEventName = 'uaEvent';
export default function Link({ href, className, stopPropagation = false, dataLayer = null, onClick, children, ...rest }: any) {
    const isNoneHttp = (href || '').indexOf('http') === -1;
    const isIncudesWebsite = (href || '').indexOf('www.ecoflow.com') > -1;
    const isWebsite = isNoneHttp || isIncudesWebsite;
    const { locale = 'us' } = useRouter();
    // 触发埋点
    const handleGTM = (params: any) => {
        if (Array.isArray(params)) {
            params.forEach((v) => gtm.push(v));
        } else if (params) {
            gtm.push({
                event: uaEventName,
                ...params,
            });
            try {
                const eventObj = {
                    ...params,
                };
                const pagePath = getRealPathname(location.pathname);
                eventObj.pagePath = pagePath;
                eventObj.pageSource = getPageSource();
                BhStat.push(eventObj);
            } catch (error: any) {}
        }
    };
    // 点击事件
    const clickHandle = (e: any) => {
        if (stopPropagation) {
            e.stopPropagation();
        }
        saveCookieForShopify(href, locale);
        dataLayer && handleGTM(dataLayer);
        onClick && onClick(); //触发回调
    };
    return (
        <a
            className={`${styles.lowcodelink} editor-link ${className ? ` ${className}` : ''}`}
            href={href}
            target={isWebsite ? '_self' : '_blank'}
            rel="noreferrer"
            onClick={clickHandle}
            {...rest}
        >
            {children}
        </a>
    );
}
