import { localValueMap } from '@/utils/locals';

const appById = {} as any;
const englishLang = [] as any[];
Object.values(localValueMap)?.map((item: any) => {
    appById[item.appId] = item;
    if (item.language === 'English') {
        englishLang.push(item.local);
    }
});

// APP by lang as key
export const APP_BY_LANG = localValueMap;

// APP by app id as key
export const APP_BY_ID = appById;

// locales of English languages
export const ENGLISH_LANG = englishLang;

// 正式上线的国家/语言（界面可切换，对应 x-appid 的 API 可访问）
export const READY_LANG = Object.keys(JSON.parse(process.env.NEXT_PUBLIC_APP_ID || '{}'));

// ReCAPTCHA语言表
// refrence: https://developers.google.com/recaptcha/docs/language?hl=zh-cn
export const rpcMap: any = {
    en: 'en',
    cn: 'zh_CN',
    it: 'it',
    es: 'es',
    de: 'de',
    fr: 'fr',
    kr: 'ko',
    jp: 'ja',
    sa: 'ar',
    tw: 'zh_TW',
    hk: 'zh_HK',
    cis: 'ru',
    br: 'pt_BR',
};

export const getRpcHL = (locale: string) => rpcMap[ENGLISH_LANG.includes(locale) ? 'en' : locale] || rpcMap.en;
