import cookie from 'cookie';

export const getABTestPageVersion = (locale: string, pathname: string) => {
    const ret = {
        isABTest: false,
        page_version: '',
        page_version_index: '', //首页专用
    };
    if (typeof window === 'undefined') {
        return ret;
    }

    //const pathname = location.pathname;
    const cookieObj: any = cookie.parse(document.cookie) || {};
    // 是否是编辑页面-首页
    if (pathname === '/' || pathname === '/index') {
        const page_version_index = cookieObj['page_version_index'] || ''; //从当前的url的cookie下拿到 page_version_index
        if (page_version_index) {
            ret.isABTest = true;
            ret.page_version_index = page_version_index;
        }
    }
    // 是否是编辑页面-非首页
    if (pathname.indexOf('/preview/') || pathname.indexOf('/pages/')) {
        const page_version = cookieObj['page_version'] || ''; //从当前的url的cookie下拿到 page_version
        if (page_version) {
            ret.isABTest = true;
            ret.page_version = page_version;
        }
    }

    return ret;
};
