'use strict';

var _TagManager = require("./tagmanager");
var _FBManager = require("./facebook");
var _SizmekManager = require("./sizmek");
var _BdManager = require("./baidu");
var _PtManager = require("./ptengine");
var _FloodlightManager = require("./floodlight");
var _EmarsysManager = require("./emarsys");
var _LineManager = require("./line");
var _interopRequireDefault = function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
};
var _TagManager2 = _interopRequireDefault(_TagManager);
var _FBManager2 = _interopRequireDefault(_FBManager);
var _SizmekManager2 = _interopRequireDefault(_SizmekManager);
var _BdManager2 = _interopRequireDefault(_BdManager);
var _PtManager2 = _interopRequireDefault(_PtManager);
var _FloodlightManager2 = _interopRequireDefault(_FloodlightManager);
var _EmarsysManager2 = _interopRequireDefault(_EmarsysManager);
var _LineManager2 = _interopRequireDefault(_LineManager);
module.exports = {
  TagManager: _TagManager2.default,
  FBManager: _FBManager2.default,
  SizmekManager: _SizmekManager2.default,
  BdManager: _BdManager2.default,
  PtManager: _PtManager2.default,
  FloodlightManager: _FloodlightManager2.default,
  EmarsysManager: _EmarsysManager2.default,
  LineManager: _LineManager2.default
};