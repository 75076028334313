'use client';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './index.module.scss';
export default function EditorMessage({ title, isShow, callback }: { title: string; isShow: boolean; callback: () => void }) {
    const [isClient, setIsClient] = useState(false);
    const [isEnterA, setIsEnterA] = useState('');

    useEffect(() => {
        setIsClient(true);
    }, []);
    useEffect(() => {
        if (isShow) {
            setIsEnterA('enter');
            setTimeout(() => {
                setIsEnterA('close');
                callback && callback();
            }, 2000);
        }
    }, [isShow]);
    if (!isClient) {
        return null;
    }
    return createPortal(
        <>
            <div className={`${styles.comp}${isEnterA === 'enter' ? ' message-enter' : ''}`}>
                <div className={`message-in`}>
                    <div className="message-title">{title}</div>
                </div>
            </div>
        </>,
        document.body,
    );
}
