'use client';

export function getCookie(cname: string) {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        const c = ca[i].trim();
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return '';
}

export function getCookieUser() {
    const val1 = getCookie('userInfo') || '';
    const val2 = decodeURIComponent(val1);
    const obj = JSON.parse(val2 || '{}');
    return obj;
}
