import { smartDevicePopupLink } from './url';

interface IExtraField {
    type: string;
    name: string;
    defaultValue?: string;
    data?: any[];
}
export interface IEmailMap {
    [key: string]:
        | {
              type: 'klaviyo';
              list_id: string;
              source: string;
              consent_form_id: string;
              popupBtnLink: string;
              SmartDevices?: string;
          }
        | {
              type: 'mailchimp';
              action: string;
              extraFields?: IExtraField[];
              popupBtnLink: string;
          };
}

interface TimeFormat {
    d: boolean;
    h: boolean;
    m?: boolean;
    s?: boolean;
}
interface YoutubeParams {
    img?: string;
    startTime?: string;
    liveTime?: string;
    format?: TimeFormat;
    liveContext?: string;
}

// 生产环境用
const BladeTimeLineProd: any = {
    us: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        crowdfunding: '2023/04/26 15:00:00 GMT+8',
        detail: '2023/05/15 15:00:00 GMT+8',
    },
    ca: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        crowdfunding: '2023/04/26 15:00:00 GMT+8',
        detail: '2023/05/15 15:00:00 GMT+8',
    },
    de: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/04/26 15:00:00 GMT+8',
    },
    fr: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/04/26 15:00:00 GMT+8',
    },
    it: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/04/26 15:00:00 GMT+8',
    },
    es: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/04/26 15:00:00 GMT+8',
    },
    eu: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/04/26 15:00:00 GMT+8',
    },
    uk: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/04/26 15:00:00 GMT+8',
    },
    au: {
        // teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/10/17 00:00:00 GMT+8',
    },
};

// 测试环境用
const BladeTimeLineTest: any = {
    us: {
        teaser: '2023/03/19 16:00:00 GMT+8',
        crowdfunding: '2023/04/21 17:50:00 GMT+8',
        detail: '2023/06/15 15:00:00 GMT+8',
    },
    ca: {
        teaser: '2023/03/19 16:00:00 GMT+8',
        crowdfunding: '2023/04/21 17:50:00 GMT+8',
        detail: '2023/05/15 15:00:00 GMT+8',
    },
    de: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/04/26 15:00:00 GMT+8',
    },
    fr: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/04/26 15:00:00 GMT+8',
    },
    it: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/04/26 15:00:00 GMT+8',
    },
    es: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/04/26 15:00:00 GMT+8',
    },
    eu: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/04/26 15:00:00 GMT+8',
    },
    uk: {
        teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/04/26 15:00:00 GMT+8',
    },
    au: {
        // teaser: '2023/04/19 16:00:00 GMT+8',
        detail: '2023/10/01 15:00:00 GMT+8',
    },
};

export const getBladeTimeline = (locale: string, flag?: boolean) => {
    return flag ? BladeTimeLineTest[locale] || BladeTimeLineTest.us : BladeTimeLineProd[locale] || BladeTimeLineProd.us;
};

export const enum STAGES {
    TEASER,
    CRODWFUNDING,
    DETAIL,
    UNKNOWN,
}

export const emailMap: IEmailMap = {
    us: {
        type: 'klaviyo',
        list_id: 'VuFVB6',
        source: 'EcoFlow Blade Teaser from',
        consent_form_id: 'SUzJPy',
        popupBtnLink: smartDevicePopupLink.blade,
    },
    ca: {
        type: 'klaviyo',
        list_id: 'WE82XV',
        source: 'EcoFlow Blade Teaser from',
        consent_form_id: 'Vvis8r',
        popupBtnLink: smartDevicePopupLink.blade,
    },
    eu: {
        type: 'klaviyo',
        list_id: 'RL2Kig',
        source: 'EcoFlow Blade Teaser from',
        consent_form_id: 'WmvAwF',
        popupBtnLink: smartDevicePopupLink.blade,
    },
    uk: {
        type: 'klaviyo',
        list_id: 'SxHiwN',
        source: 'EcoFlow Blade Teaser from',
        consent_form_id: 'TBu7em',
        popupBtnLink: smartDevicePopupLink.blade,
    },
    de: {
        type: 'klaviyo',
        list_id: 'TYJ8Ng',
        source: 'EcoFlow Blade Teaser from',
        consent_form_id: 'XZFrBt',
        popupBtnLink: smartDevicePopupLink.de,
    },
    fr: {
        type: 'klaviyo',
        list_id: 'WB6PHf',
        source: 'EcoFlow Blade Teaser from',
        consent_form_id: 'UY4cT2',
        popupBtnLink: smartDevicePopupLink.fr,
    },
    it: {
        type: 'klaviyo',
        list_id: 'V2rd4s',
        source: 'EcoFlow Blade Teaser from',
        consent_form_id: 'YuYHAq',
        popupBtnLink: smartDevicePopupLink.blade,
    },
    es: {
        type: 'klaviyo',
        list_id: 'XH5ahU',
        source: 'EcoFlow Blade Teaser from',
        consent_form_id: 'Untak3',
        popupBtnLink: smartDevicePopupLink.blade,
    },
    jp: {
        type: 'mailchimp',
        action: 'https://ecoflow.us8.list-manage.com/subscribe/post?u=d002bf04842356c4c1c5221cf&amp;id=440b6cbd62&amp;f_id=00b372e1f0',
        popupBtnLink: smartDevicePopupLink.jp,
    },
};

export interface BladeTreaserVideoItem {
    title: string;
    desc: string;
    sources: string[];
}

export const getTreaserVideoLists = (isMobile: boolean) => {
    const temp = isMobile ? 'h5' : 'pc';
    return [
        {
            title: 'teaser.sec3_list1_title',
            desc: 'teaser.sec3_list1_desc',
            sources: [`/video/blade/treaser/${temp}/3_1.webm`, `/video/blade/treaser/${temp}/3_1.mp4`],
        },
        {
            title: 'teaser.sec3_list2_title',
            desc: 'teaser.sec3_list2_desc',
            sources: [`/video/blade/treaser/${temp}/3_2.webm`, `/video/blade/treaser/${temp}/3_2.mp4`],
        },
        {
            title: 'teaser.sec3_list3_title',
            desc: 'teaser.sec3_list3_desc',
            sources: [`/video/blade/treaser/${temp}/3_3.webm`, `/video/blade/treaser/${temp}/3_3.mp4`],
        },
    ] as BladeTreaserVideoItem[];
};

export const YOUTUBE_PARAMS: YoutubeParams = {
    // img: '/media/delta/pc/751b40ecfe45b2cae84090458a0dd090.jpg',
    startTime: '2023/04/08 20:00:00 GMT+8',
    liveTime: 'teaser.sec6_youtube_start_time',
    format: { d: true, h: true } as TimeFormat,
    liveContext: 'teaser.sec6_live_context',
};

export enum BladeCal {
    Init,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Result,
}

export const detailYoutubeMap: any = {
    us: 'https://www.youtube.com/embed/vbIWNvkncE8',
    de: 'https://www.youtube.com/embed/4J9vTqyYRUE',
    fr: 'https://www.youtube.com/embed/xtE3XEPif6Q',
    it: 'https://www.youtube.com/embed/jtGOxYNH73s',
    es: 'https://www.youtube.com/embed/Pm78Cu_3-w0',
    cn: {
        SD: 'https://videos.ecoflow.com/414f233a732c497093a5ed9078c8eaad/8f00178764e54bc8b1913a8feff387d5-82da21de793746b6cea1259b31025f34-fd.mp4',
        HD: 'https://videos.ecoflow.com/414f233a732c497093a5ed9078c8eaad/8f00178764e54bc8b1913a8feff387d5-98ded41e8586415eb0a4f1e5ddd5561f-sd.mp4',
        '2K': 'https://videos.ecoflow.com/original/workflow/470d945a-1833b7e52ca-0004-9c91-e6d-40030.mp4',
    },
};

export const ACTIVITY_MAP: any = {
    us: 21,
    ca: 14,
};

export const AIMING_SITE_ARR = ['robots', 'bound', 'mowing', 'cut', 'character', 'services', 'other'];

export const getTCBlade = (locale: string) => {
    const map: any = {
        us: {
            title: 'crowdfunding.header.terms.title',
            desc: 'crowdfunding.header.terms.desc_blade',
            links: ['https://www.youtube.com/@EcoFlowTech', 'https://www.facebook.com/ecoflowtech', 'https://www.instagram.com/ecoflowtech/'],
        },
        ca: {
            title: 'crowdfunding.header.terms.title',
            desc: 'crowdfunding.header.terms.desc_blade',
            links: ['https://www.youtube.com/@EcoFlowTech', 'https://www.facebook.com/ecoflowtech', 'https://www.instagram.com/ecoflowtech/'],
        },
    };
    return map[locale] || map.us;
};

export const kolVideo = [
    'https://www.youtube.com/embed/CSWv2OT9rNg',
    'https://www.youtube.com/embed/tWVT6DlO3tw',
    'https://www.youtube.com/watch?v=wDhMqwljVj8',
];
