interface dataItem {
    key?: string;
    country: string;
    address: string;
    link?: string;
    hours?: string;
    tel?: string;
    hotline?: string;
    supportList?: supportItem[];
}

interface supportItemSub {
    value: string;
    link: string;
    notEmail?: boolean;
}

interface supportItem {
    title: string;
    value?: string;
    link?: string;
    notEmail?: boolean;
    subs?: supportItemSub[];
}

const CHINA_FIRST = ['cn'];
export const SHOW_COMMUNITY = ['us', 'ca', 'za', 'au', 'de', 'fr', 'br'];
const SHOW_AFFILIATE = ['us', 'ca', 'za', 'au', 'de', 'fr', 'eu', 'it', 'es', 'uk'];

const getNorthAmericanSupportLists = (locale: string) => {
    const research_team_obj = {
        title: 'site_research_team',
        value: 'GlobalUserResearch@ecoflow.com',
        link: '',
    };
    // us ca站
    const base1 = [
        {
            title: 'site_support',
            value: 'support@ecoflow.com',
            link: '',
        },
        {
            title: 'site_social_media',
            value: 'contentpartners@ecoflow.com',
            link: '',
        },
        {
            title: 'site_youTube_tiktok_influencers',
            value: 'Influencer@ecoflow.com',
            link: '',
        },
        {
            title: 'site_dealer',
            value: 'sales@ecoflow.com',
            link: '',
        },
        {
            title: 'site_northAmerican_community',
            value: 'Community@EcoFlowClub',
            link: 'http://bit.ly/3kqdSXK',
            notEmail: true,
        },
        {
            title: 'site_public_relations',
            value: 'media.na@ecoflow.com',
            link: '',
        },
        {
            title: 'site_affiliate',
            value: 'affiliate@ecoflow.com',
            link: '',
        },
        research_team_obj,
    ];
    // 其他站
    const base2 = [
        {
            title: 'site_support',
            value: 'support@ecoflow.com',
            link: '',
        },
        {
            title: 'site_influencer',
            value: 'Influencer@ecoflow.com',
            link: '',
        },
        {
            title: 'site_dealer',
            value: 'sales@ecoflow.com',
            link: '',
        },
        {
            title: 'site_public_relations',
            value: 'media.na@ecoflow.com',
            link: '',
        },
    ];
    if (['us', 'ca'].includes(locale)) {
        return base1;
    } else {
        if (SHOW_COMMUNITY.includes(locale)) {
            const tempArr = JSON.parse(JSON.stringify(base2));
            tempArr.splice(3, 0, {
                title: 'site_northAmerican_community',
                value: 'Community@EcoFlowClub',
                link: 'http://bit.ly/3kqdSXK',
                notEmail: true,
            });
            if (SHOW_AFFILIATE.includes(locale)) {
                tempArr.push({
                    title: 'site_affiliate',
                    value: 'affiliate@ecoflow.com',
                    link: '',
                });
            }
            tempArr.push(research_team_obj);
            return tempArr;
        } else {
            if (SHOW_AFFILIATE.includes(locale)) {
                base2.push({
                    title: 'site_affiliate',
                    value: 'affiliate@ecoflow.com',
                    link: '',
                });
            }
            base2.push(research_team_obj);
            return base2;
        }
    }
};

const getEuropeSuppportLists = (locale: string) => {
    // Community新增
    const community1: supportItem = {
        title: 'site_europe_community',
        subs: [
            {
                value: 'Communauté@EcoFlowFrancophone',
                link: 'https://bit.ly/3rBW8fa',
                notEmail: true,
            },
            {
                value: 'Community@EcoFlowDeutsch',
                link: 'https://bit.ly/3DUfX4j',
                notEmail: true,
            },
            {
                value: 'Community@EcoFlowUK',
                link: 'https://bit.ly/44OxFS8',
                notEmail: true,
            },
            {
                value: 'Community@EcoFlowItalia',
                link: 'https://bit.ly/44F40uN',
                notEmail: true,
            },
        ],
    };
    const community2: supportItem = {
        title: 'site_europe_community',
        subs: [
            {
                value: 'Community@EcoFlowDeutsch',
                link: 'https://bit.ly/3DUfX4j',
                notEmail: true,
            },
            {
                value: 'Community@EcoFlowUK',
                link: 'https://bit.ly/44OxFS8',
                notEmail: true,
            },
            {
                value: 'Community@EcoFlowItalia',
                link: 'https://bit.ly/44F40uN',
                notEmail: true,
            },
            {
                value: 'Communauté@EcoFlowFrancophone',
                link: 'https://bit.ly/3rBW8fa',
                notEmail: true,
            },
        ],
    };
    const publicRelationItem: supportItem = {
        title: 'site_public_relations',
        value: 'media.eu@ecoflow.com',
        link: '',
    };
    // de, fr, uk, eu 站
    const base1 = [
        {
            title: 'site_support',
            value: 'support.eu@ecoflow.com',
            link: '',
        },
        {
            title: 'site_dealer',
            value: 'sales.eu@ecoflow.com',
            link: '',
        },
    ];

    // 家储 contact-us   uk/de/eu/it
    const base1_home_battery = [
        {
            title: 'site_support',
            link: '',
            subs: [
                {
                    value: 'support.eu@ecoflow.com',
                    tip: 'site_support_email_tip_1',
                    link: '',
                },
                {
                    value: 'solutionservice.eu@ecoflow.com',
                    tip: 'site_support_email_tip_2',
                    link: '',
                },
            ],
        },
        {
            title: 'site_dealer',
            link: '',
            subs: [
                {
                    value: 'sales.eu@ecoflow.com',
                    tip: '',
                    link: '',
                },
                {
                    value: 'solutionsales.eu@ecoflow.com',
                    tip: 'site_support_email_tip_3',
                    link: '',
                },
            ],
        },
    ];

    // 其他站
    const base2 = [
        {
            title: 'site_support',
            value: 'support.eu@ecoflow.com',
            link: '',
        },
        {
            title: 'site_dealer',
            value: 'sales.eu@ecoflow.com',
            link: '',
        },
    ];
    const affiliate_list = [
        {
            title: 'site_affiliate',
            value: 'eu.affiliate@ecoflow.com',
            link: '',
        },
    ];
    const research_team_list = {
        title: 'site_research_team',
        value: 'GlobalUserResearch@ecoflow.com',
        link: '',
    };
    let list = [];
    if (['fr'].includes(locale)) {
        // ['us', 'ca', 'za', 'au', 'de', 'fr']
        list = SHOW_COMMUNITY.includes(locale) ? [...base1, community1, publicRelationItem] : [...base1, publicRelationItem];
    } else if (['uk', 'de', 'eu', 'it'].includes(locale)) {
        list = SHOW_COMMUNITY.includes(locale) ? [...base1_home_battery, community1, publicRelationItem] : [...base1_home_battery, publicRelationItem];
    } else {
        list = SHOW_COMMUNITY.includes(locale) ? [...base2, community1, publicRelationItem] : [...base2, publicRelationItem];
    }
    if (['eu', 'uk', 'it', 'es'].includes(locale)) {
        list.splice(3, 0, community2);
    }
    if (SHOW_AFFILIATE.includes(locale)) {
        list = list.concat(affiliate_list);
    }
    list.push(research_team_list);
    return list;
};

type MyObj = {
    [key: string]: any;
};

const reorderObject = (obj: MyObj, key: string) => {
    const { [key]: value, ...rest } = obj;
    const entries = Object.entries(rest);
    entries.unshift([key, value]);
    return Object.fromEntries(entries);
};

const getZaList = (locale: any) => {
    const data = [
        {
            title: 'site_support',
            value: 'support.za@ecoflow.com',
            link: '',
        },
        {
            title: 'site_hotline',
            value: '+27 80 088 0002',
            link: '',
        },
        {
            title: 'site_dealer',
            value: 'sales.rest@ecoflow.com',
            link: '',
        },
        {
            title: 'site_comunity',
            value: 'Facebook Group@South Africa',
            link: 'https://www.facebook.com/groups/efsouthafrica?utm_source=Web&utm_medium=Page&utm_campaign=contact+us',
            notEmail: true,
        },
        {
            title: 'site_public_relations',
            value: 'media.lamea@ecoflow.com  ',
            link: '',
        },
    ];

    if (SHOW_AFFILIATE.includes(locale)) {
        return data.concat([
            {
                title: 'site_affiliate',
                value: 'row.affiliate@ecoflow.com',
                link: '',
            },
            {
                title: 'site_research_team',
                value: 'GlobalUserResearch@ecoflow.com',
                link: '',
            },
        ]);
    }
    const research_team_obj = {
        title: 'site_research_team',
        value: 'GlobalUserResearch@ecoflow.com',
        link: '',
    };
    data.push(research_team_obj);
    return data;
};

const getAuList = (locale: string) => {
    const data = [
        {
            title: 'site_support',
            value: 'support.au@ecoflow.com',
            link: '',
        },
        {
            title: 'site_dealer',
            value: 'sales.rest@ecoflow.com',
            link: '',
        },
        {
            title: 'site_comunity',
            value: 'Facebook Group@EcoFlow Australia',
            link: 'https://www.facebook.com/groups/efaustralia',
            notEmail: true,
        },
        {
            title: 'site_public_relations',
            value: 'media.apac@ecoflow.com  ',
            link: '',
        },
    ];
    if (SHOW_AFFILIATE.includes(locale)) {
        return data.concat([
            {
                title: 'site_affiliate',
                value: 'row.affiliate@ecoflow.com',
                link: '',
            },
            {
                title: 'site_research_team',
                value: 'GlobalUserResearch@ecoflow.com',
                link: '',
            },
        ]);
    }
    const research_team_obj = {
        title: 'site_research_team',
        value: 'GlobalUserResearch@ecoflow.com',
        link: '',
    };
    data.push(research_team_obj);
    return data;
};

const getBrList = () => {
    const data = [
        {
            title: 'site_support',
            value: 'support.br@ecoflow.com',
            link: '',
        },
        {
            title: 'site_public_relations',
            value: 'media.apac@ecoflow.com',
            link: '',
        },
        {
            title: 'site_dealer',
            value: 'sales.rest@ecoflow.com',
            link: '',
        },
    ];
    return data;
};

export const getDataList = (locale: string) => {
    const cfgMap: any = {
        us: {
            country: 'site_about_contact_north_america',
            address: 'us_address',
            hours: 'us_hours',
            tel: 'us_tel',
            worktime: 'us_worktime',
            supportList: getNorthAmericanSupportLists(locale),
        },
        jp: {
            country: 'site_about_contact_japan',
            address: 'jp_address',
            hours: 'jp_hours',
            tel: 'jp_tel',
            supportList: [
                {
                    title: 'site_support',
                    value: 'support.jp@ecoflow.com',
                    link: '',
                },
                // {
                //     title: 'site_dealer',
                //     value: 'sales.jp@ecoflow.com',
                //     link: '',
                // },
                {
                    title: 'site_media',
                    value: 'media.jp@ecoflow.com',
                    link: '',
                },
                {
                    title: 'site_pre_order',
                    value: 'hello.jp@ecoflow.com',
                    link: '',
                },
                {
                    title: 'site_research_team',
                    value: 'GlobalUserResearch@ecoflow.com',
                    link: '',
                },
            ],
        },
        eu: {
            country: 'site_about_contact_europe',
            address: 'eu_address',
            hours: 'eu_hours',
            hotline: ['eu', 'uk', 'de'].includes(locale) ? 'eu_hotline' : '',
            supportList: getEuropeSuppportLists(locale),
        },
        za: {
            country: 'site_about_contact_south_africa',
            address: 'za_address',
            hours: 'za_hours',
            dealtime: 'za_dealtime',
            isShowRoom: true,
            supportList: getZaList(locale as any),
        },
        au: {
            country: 'site_about_contact_australia',
            address: '', // au_address
            hours: 'au_hours',
            tel: 'au_tel',
            supportList: getAuList(locale),
        },
        br: {
            country: 'site_about_contact_brazil',
            // address: 'br_address',
            hours: 'br_worktime',
            // tel: 'br_tel',
            // worktime: 'br_worktime',
            supportList: getBrList(),
        },
    };
    const other: any = {
        country: 'site_about_contact_others',
        address: '', // other_address
        hours: 'other_hours',
        supportList: [
            {
                title: 'site_support',
                value: 'support@ecoflow.com',
                link: '',
            },
            {
                title: 'site_dealer',
                value: 'sales.rest@ecoflow.com',
                link: '',
            },
            {
                title: 'site_research_team',
                value: 'GlobalUserResearch@ecoflow.com',
                link: '',
            },
        ],
    };
    const isEu = ['eu', 'uk', 'de', 'fr', 'it', 'es', 'cis'].includes(locale);
    const localeStr = isEu ? 'eu' : Boolean(cfgMap[locale]) ? locale : 'us';
    const result: dataItem[] = [...Object.values(reorderObject(cfgMap, localeStr)), other];
    if (NEED_COMMUNITY_SITES.includes(locale)) {
        result.push({
            key: 'community',
            country: 'site_about_contact_community',
            address: 'facebook',
            link: getFacebookLink(locale),
        });
    }
    if (CUSTOMER_SERVICE_HOTLINE.includes(locale)) {
        const index = result.findIndex((item: any) => item.country === 'site_about_contact_europe');
        if (index > -1) {
            result.unshift(result.splice(index, 1)[0]);
        }
    }
    // 中国站 中国联系地址放首位
    if (CHINA_FIRST.includes(locale)) {
        result.unshift({
            country: 'site_about_contact_china',
            address: 'cn_address',
            hours: 'cn_hours',
            supportList: [
                {
                    title: 'site_customer_support',
                    value: '400-600-5753',
                    link: '',
                },
                {
                    title: 'site_dealer',
                    value: '18923781545',
                    link: '',
                },
                {
                    title: 'site_cooperation',
                    value: 'marketing.cn@ecoflow.com',
                    link: '',
                },
                {
                    title: 'site_research_team',
                    value: 'GlobalUserResearch@ecoflow.com',
                    link: '',
                },
            ],
        });
    }
    // 日本站 隐藏 非日本的联系地址
    if (['jp'].includes(locale)) {
        for (const data of result) {
            if (data.address !== 'jp_address') {
                data.address = '';
            }
        }
    }
    return result;
};

export const FACEBOOK_LINKS: any = {
    uk: 'https://www.facebook.com/groups/ecoflowclub',
    us: 'http://bit.ly/3kqdSXK',
    ca: 'http://bit.ly/3kqdSXK',
    au: 'https://www.facebook.com/groups/efaustralia',
    za: 'http://bit.ly/3KO0KGA',
};
export const getFacebookLink = (locale: string) => FACEBOOK_LINKS[locale] || FACEBOOK_LINKS.us;
export const NEED_COMMUNITY_SITES: any = [];
export const CUSTOMER_SERVICE_HOTLINE = ['de'];
