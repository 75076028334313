'use strict';

var _Snippets = require("./snippets");
var _interopRequireDefault = function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
};
var _Snippets2 = _interopRequireDefault(_Snippets);
var FBManager = {
  fb: function fb(args) {
    var snippets = _Snippets2.default.fb.tags(args);
    var createNoScript = function createNoScript() {
      var noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };
    var createScript = function createScript() {
      var script = document.createElement('script');
      script.innerHTML = snippets.script;
      return script;
    };
    return {
      createNoScript: createNoScript,
      createScript: createScript
    };
  },
  initialize: function initialize(_ref) {
    var fbId = _ref.fbId;
    var fb = this.fb({
      id: fbId
    });
    document.head.insertBefore(fb.createScript(), document.head.childNodes[0]);
    document.body.insertBefore(fb.createNoScript(), document.body.childNodes[0]);
  }
};
module.exports = FBManager;