import { localsList } from '@/utils/locals';
import generateTraceId from '@/utils/singUtil/generateTraceId';

export default function getDefaultHeader(locale = 'us') {
    const locale2 = locale === 'en' ? 'us' : locale;
    let obj: any = {};
    for (const key in localsList) {
        const list = localsList[key] || [];
        for (let i = 0; i < list.length; i++) {
            const item = list[i] || {};
            if (item.local === locale2) {
                obj = item;
                return {
                    'Accept-Language': item?.acceptLanguage,
                    'x-appid': item?.appId,
                };
            }
        }
    }

    return {
        'Accept-Language': obj?.acceptLanguage,
        'x-appid': obj?.appId,
        traceId: generateTraceId(),
    };
}
