import GloLayout from 'lowcode/common/GloLayout';
import styles from './index.module.scss';
import IconClose from './comps/IconClose';
import TypeA from './comps/TypeA';
import TypeB from './comps/TypeB';

export default function Content({ id, data = {} }: any) {
    const { hasClose, cssType, barType } = data;

    return (
        <GloLayout className={`${styles.content} glo-float-left glo-float-type-${cssType}`} id={id}>
            {barType === 'ActivityWin' && <TypeA id={id} data={data} />}
            {barType === 'EmarsysWin' && <TypeB id={id} data={data} />}

            {hasClose && <IconClose id={id} />}
        </GloLayout>
    );
}
