export const incompleteLocalsList = [''];

export const readySites = ['us', 'ca', 'uk', 'au', 'za', 'sa', 'eu', 'kr'];

/**
 * 欧洲站点
 */
export const EU_SITE = ['eu', 'uk', 'de', 'fr', 'it', 'es'];

// 登录新版站点
export const newVersionRegion = (locale: string) => ['us', 'eu', 'uk', 'de', 'fr', 'it', 'es', 'jp', 'kr', 'au', 'za', 'ca'].includes(locale);

// 秋促站点
export const MEDIUM_SITES = ['eu', 'de', 'fr', 'it', 'es', 'uk'];

// Shopify 站点前缀
export const SHOPIFY_SITES: any = {
    us: 'https://us.ecoflow.com/',
    au: 'https://au.ecoflow.com/',
    ca: 'https://ca.ecoflow.com/',
    es: 'https://es.ecoflow.com/',
    eu: 'https://eu.ecoflow.com/',
    fr: 'https://fr.ecoflow.com/',
    de: 'https://de.ecoflow.com/',
    jp: 'https://jp.ecoflow.com/',
    kr: 'https://kr.ecoflow.com/',
    za: 'https://za.ecoflow.com/',
    uk: 'https://uk.ecoflow.com/',
    it: 'https://it.ecoflow.com/',
    br: 'https://br.ecoflow.com/',
    cis: '',
    sa: '',
    tw: '',
    cn: '',
};

// 展示 RIVER APP 卖点
export const SHOW_APP_POINT_SITES = ['us', 'au', 'za', 'uk', 'eu', 'ca'];

// RIVER 2 卖点优化展示
export const SHOW_RIVER2_OPTIMIZATION = ['us', 'ca', 'au', 'za'];
