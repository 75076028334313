import { loginUser } from '@/utils/cookie';

// 第三方翻译系统：文思海辉
const editModeWhiteList: Record<string, string | string[]> = {
    'baolin.xie@ecoflow.com': '',
    'riting.liu@ecoflow.com': '',
    'jocelyn.xu@ecoflow.com': '', // 运营产品经理：许洁媛
    'branko.su@ecoflow.com': ['cn', 'hk'], // 中国官网运营：苏光
    'changyi.wen@ecoflow.com': ['cn'],
    'amelie.zheng@ecoflow.com': ['us', 'ca'],
    'patrick.chong@ecoflow.com': ['uk', 'eu', 'de', 'fr', 'it', 'es'],
    'claudia.zhou@ecoflow.com': ['au', 'za'],
    'jiawei.wu@ecoflow.com': ['jp'],
    'jessica.huang@ecoflow.com': ['de'],
    'jeremie.savon@ecoflow.com': ['fr'],
    'krystal.zhuang@ecoflow.com': ['kr'],
    'yang.yu@ecoflow.com': '',
    'jiaming.wu@ecoflow.com': '',
    'helen.wu@ecoflow.com': '',
    'zhouming.wang@ecoflow.com': '',
    'yuzhong.dong@ecoflow.com': '',
    'kalkin.wong@ecoflow.com': '',
    'nicolas.qian@ecoflow.com': '',
    'nick.liao@ecoflow.com': '',
    'ziwen.liang@ecoflow.com': '',
    'jie.hu@ecoflow.com': '',
    'yang.xiao@ecoflow.com': '',
    'fund.gao@ecoflow.com': '',
    'timo.he@ecoflow.com': '',
    'alena.qiao@ecoflow.com': ['de', 'fr', 'it', 'es'],
    'monica.zhao@ecoflow.com': ['it'],
    'lionel.wu@ecoflow.com': ['es'],
    'yuki.zhang@ecoflow.com': ['jp'],
};

export const IS_EDIT_MODE = (lang: any, isMobile = false, isTest = false) => {
    // return true
    const userEmail = loginUser()?.user?.email;
    const auth = editModeWhiteList[userEmail];
    if (isMobile || !isTest) {
        return false;
    }

    return (typeof auth === 'object' && auth.includes(lang)) || (typeof auth === 'string' && auth === '');
};
