import { default as PastAPP } from '@/past_pages/_app';
import { getInitialProps as pastGetInitialProps } from '@/past_pages/_app';

import 'lowcode/common/css/global.scss';
import '@/styles/global.scss';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import 'swiper/scss/effect-fade';
import 'elem/styles/base.scss';

const APP = (props: any) => {
    return <PastAPP {...props} />;
};

APP.getInitialProps = async (ctx: any) => {
    const pastProps = await pastGetInitialProps(ctx);
    return pastProps;
};

export default APP;
