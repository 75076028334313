'use client';

export default function IconClose({ id }: any) {
    const hideHandle = () => {
        document.getElementById(id)?.classList.add('glo-lr-hide');
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14" fill="none" onClick={hideHandle} className="glo-float-close">
            <circle cx="7" cy="7" r="7" fill="#999999" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.23078 5.27123C9.43581 5.06621 9.43581 4.73379 9.23078 4.52877C9.02576 4.32374 8.69335 4.32374 8.48832 4.52877L6.87987 6.13723L5.27141 4.52877C5.06638 4.32374 4.73397 4.32374 4.52895 4.52877C4.32392 4.73379 4.32392 5.06621 4.52895 5.27123L6.1374 6.87969L4.52852 8.48857C4.3235 8.69359 4.3235 9.026 4.52852 9.23103C4.73355 9.43605 5.06596 9.43605 5.27099 9.23103L6.87987 7.62215L8.48875 9.23103C8.69377 9.43605 9.02618 9.43605 9.23121 9.23103C9.43623 9.026 9.43623 8.69359 9.23121 8.48857L7.62233 6.87969L9.23078 5.27123Z"
                fill="white"
            />
        </svg>
    );
}
