import { loginUrl } from './util';
import request from '@/utils/request';

/**
 * support/get-support 创建服务单
 * https://api-sit.ecoflow.com/doc.html#/%E5%AE%98%E7%BD%91%E6%9C%8D%E5%8A%A1/%E8%87%AA%E5%8A%A9%E6%8F%90%E5%8D%95%E4%B8%8E%E6%9F%A5%E8%AF%A2%20C%E7%AB%AF/submitUsingPOST
 */
export const createServiceCase = (data: object, locale: string): any =>
    request.post(`${loginUrl(locale)}/website/selfServiceCase/submit`, {
        data,
        needToken: true,
    });

/**
 * support/get-support 产品列表查询
 */
export const serviceProductTree = (locale: string): any =>
    request.get(`${loginUrl(locale)}/website/selfServiceCase/productTree`, {
        needToken: true,
    });

/**
 * support/service-status 用户邮箱列表查询
 */
export const getUserEmail = (locale: string): any =>
    request.get(`${loginUrl(locale)}/website/selfServiceCase/emails`, {
        needToken: true,
    });

/**
 * support/service-status 查询服务单
 */
export const getServiceCase = (params: object, locale: string): any =>
    request.get(`${loginUrl(locale)}/website/selfServiceCase/list`, {
        params,
        needToken: true,
    });

/**
 * support/service-status 查询服务单
 *
 * support
 * durationDay = 30
 *
 * my support
 * durationDay = 365
 */
export const getAllServiceCase = (params: object, locale: string): any =>
    request.get(`${loginUrl(locale)}/website/selfServiceCase/allList`, {
        params,
        needToken: true,
    });

/**
 * support/service-status 查询服务单
 * caseId
 * statusCode 5-已解决 6-取消
 */
export const changeCaseStatus = (data: object, locale: string): any =>
    request.put(`${loginUrl(locale)}/website/selfServiceCase/status`, {
        data,
        needToken: true,
    });
