'use client';
export const localeObjList = [
    {
        locale: 'us',
        conver: 'en-US',
        appId: 1,
        acceptLanguage: 'en-US,en;q=0.9',
    },
    {
        locale: 'uk',
        conver: 'en-US',
        appId: 2,
        acceptLanguage: 'en-UK,en;q=0.9',
    },
    {
        locale: 'au',
        conver: 'en-AU',
        appId: 3,
        acceptLanguage: 'en-AU,en;q=0.9',
    },
    {
        locale: 'ca',
        conver: 'en-CA',
        appId: 4,
        acceptLanguage: 'en-CA,en;q=0.9',
    },
    {
        locale: 'jp',
        conver: 'ja-JP',
        appId: 5,
        acceptLanguage: 'ja-JP,ja;q=0.9',
    },
    {
        locale: 'fr',
        conver: 'fr-FR',
        appId: 6,
        acceptLanguage: 'fr-FR,fr;q=0.9',
    },
    {
        locale: 'de',
        conver: 'de-DE',
        appId: 7,
        acceptLanguage: 'de-DE,de;q=0.9',
    },
    {
        locale: 'kr',
        conver: 'ko-KR',
        appId: 8,
        acceptLanguage: 'ko-KR,ko;q=0.9',
    },
    {
        locale: 'eu',
        conver: 'en-GB',
        appId: 9,
        acceptLanguage: 'en-EU,en;q=0.9',
    },
    {
        locale: 'cn',
        conver: 'zh-CN',
        appId: 10,
        acceptLanguage: 'zh-CN,zh;q=0.9',
    },
    {
        locale: 'es',
        conver: 'es-ES',
        appId: 11,
        acceptLanguage: 'es-ES,es;q=0.9',
    },
    {
        locale: 'cis',
        conver: 'ru-RU',
        appId: 12,
        acceptLanguage: 'ru-RU,ru;q=0.9',
    },
    {
        locale: 'it',
        conver: 'it-IT',
        appId: 13,
        acceptLanguage: 'it-IT,it;q=0.9',
    },
    {
        locale: 'za',
        conver: 'en-ZA',
        appId: 15,
        acceptLanguage: 'en-ZA,en;q=0.9',
    },
    {
        locale: 'sa',
        conver: 'ar',
        appId: 16,
        acceptLanguage: 'sa-AR,sa;q=0.9',
    },
    {
        locale: 'tw',
        conver: 'zh-Hant_TW',
        appId: 19,
        acceptLanguage: 'zh_TW,zh;q=0.9',
    },
    {
        locale: 'br',
        conver: 'pt-BR',
        appId: 18,
        acceptLanguage: 'pt-BR,pt;q=0.9',
    },
];

export const locales = localeObjList.map((item: any) => {
    return item.locale;
});

const getConverObj = (localeObjList: any) => {
    const obj: any = {};
    for (let i = 0; i < localeObjList.length; i++) {
        const item = localeObjList[i];
        obj[item.locale] = item.conver;
    }
    return obj;
};
export const converObj = getConverObj(localeObjList);

export const getLocalObj = (locale: string) => {
    const list = localeObjList.filter((item: any) => {
        return item.locale == locale;
    });
    return list[0] || localeObjList[0];
};
