'use client';
import GloBarLeft from './GloBarLeft';
import GloBarRight from './GloBarRight';
import GloBarWin from './GloBarWin';

export default function GloBar({ gloBarObject }: { gloBarObject: any }) {
    return (
        <>
            <GloBarLeft gloBarObject={gloBarObject.__globar_left} />
            <GloBarRight gloBarObject={gloBarObject.__globar_right} />
            <GloBarWin gloBarObject={gloBarObject.__globar_win} />
        </>
    );
}
