'use client';

export default function IconClose() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <rect x="1" y="0.5" width="19" height="19" rx="3.5" fill="#3F68E0" stroke="#3F68E0" />
            <path d="M15.5 6.84009L8.92004 13.42L5.5 10" stroke="white" strokeWidth="1.35" strokeLinecap="round" />
        </svg>
    );
}
