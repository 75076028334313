import { setLocalStorage, removeLocalStorage } from './localStorage';
import { APP_BY_LANG } from '@/constants';
import { checkSupportWebm } from '@/utils';

export const isCypressTestFn = () => {
    if (typeof window !== 'undefined') {
        const userAgent = window?.navigator?.userAgent || '';
        const devices = userAgent.toLowerCase() || '';
        if (devices.indexOf('iscypresstest') > 0) {
            return true;
        }
    }
    return false;
};

// 在浏览器端更新用户信息
export const updateLocalAppInfo = (locale: string) => {
    const appInfo = APP_BY_LANG[locale] ? APP_BY_LANG[locale] : APP_BY_LANG['us'];
    removeLocalStorage('appInfo');
    setLocalStorage('languageRegion', appInfo?.region);
    setLocalStorage('appInfo', {
        appId: appInfo?.appId,
        acceptLanguage: appInfo?.acceptLanguage,
        locale,
    });
};

// 获取异步资源
export const transformToBob = (url: string, webmUrl?: string): Promise<string> => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let finalyUrl = '';
    // safari 不支持 webm blob
    if (isSafari || !checkSupportWebm()) {
        finalyUrl = url;
    } else {
        finalyUrl = webmUrl || url;
    }

    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open('GET', finalyUrl, true);
        req.responseType = 'blob';
        req.onload = async function () {
            if (this.status === 200) {
                const videoBlob = this.response;
                const blobSrc = URL.createObjectURL(videoBlob);
                resolve(blobSrc);
            } else {
                reject();
            }
        };
        req.onerror = reject;
        req.send();
    });
};

export const roundFloat = (num: number, p: number) => {
    return parseFloat(num.toFixed(p));
};

export const getQueryAllStr = (str: string) => {
    const index = str.indexOf('?');
    return str.slice(index);
};
// 获取隐私政策链接
export const getPrivacyPolicy = (locale: string) => {
    const defaultUrl = `https://${locale}.ecoflow.com/policies/privacy-policy`;
    const linkMap: Record<string, string> = {
        jp: `https://account.ecoflow.com/agreement/ja-jp/PrivacyPolicy.html`,
        us: `https://account.ecoflow.com/agreement/en-us/PrivacyPolicy.html`,
        ca: `https://account.ecoflow.com/agreement/en-ca/PrivacyPolicy.html`,
    };
    const link = linkMap[locale] ? linkMap[locale] : defaultUrl;
    return link;
};
// 获取隐私政策链接2
export const getPagesPrivacyPolicy = (locale: string) => {
    const defaultUrl = `https://${locale}.ecoflow.com/pages/privacy-policy`;
    const linkMap: Record<string, string> = {
        us: `https://account.ecoflow.com/agreement/en-us/PrivacyPolicy.html`,
        ca: `https://account.ecoflow.com/agreement/en-ca/PrivacyPolicy.html`,
    };
    const link = linkMap[locale] ? linkMap[locale] : defaultUrl;
    return link;
};
