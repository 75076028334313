import { baseUrl } from './util';
import request from '@/utils/request';
import { setLocaleQuery, efFetch } from '@/utils';

/*
 * 官网 2.0 =========================================================================
 */

/**
 * 页面配置详情列表-前台
 */
// export const getPageList = (locale: "") => {
//   return request.get(baseUrl(locale) + `/page/list${setLocaleQuery(locale)}`)
// }
export const getPageList = (locale: '') => {
    return efFetch(`${baseUrl(locale)}/page/list${setLocaleQuery(locale)}`);
};

/**
 * 首页草稿预览-后台
 */
export const previewDraftHomePage = (locale: '', params: { pageId: any }, token: string | undefined, appId: any) => {
    return request.get(`${baseUrl(locale)}/admin/page/previewDraftHomePage${setLocaleQuery(locale)}`, {
        params: params,
        headers: {
            Authorization: `Bearer ${token}`,
            'x-appid': appId,
        },
    });
};

/**
 * 系列页草稿预览-后台/website/admin/page/previewDraftPage
 */
export const previewDraftPage = (locale: '', params: { pageId: any }, token: string | undefined, appId) => {
    return request.get(`${baseUrl(locale)}/admin/page/previewDraftPage${setLocaleQuery(locale)}`, {
        params: params,
        headers: {
            Authorization: `Bearer ${token}`,
            'x-appid': appId,
        },
    });
};

/**
 * 众筹页-基于pathname拉取后台数据
 */
export const getCrowdFundingData = (locale: '', params: object) => {
    return request.get(`${baseUrl(locale)}/crowdFundingBaseInfo/selectPublishedBySeoUrl${setLocaleQuery(locale)}`, {
        params,
    });
};

/**
 * 众筹页 - FAQ
 */
export const getCrowdFundingFaq = (locale: '', params: any) => {
    return request.get(`${baseUrl(locale)}/otherFaq/otherFaqInfo${setLocaleQuery(locale)}`, {
        params,
    });
};

/**
 * 众筹页 - Media
 */
export const getCrowdFundingMedia = (locale: '', params: any) => {
    return request.get(`${baseUrl(locale)}/media/details${setLocaleQuery(locale)}`, {
        params,
    });
};

/**
 * 众筹页 - /website/admin/crowdFundingBaseInfo/selectById
 */
export const getCrowdFundingById = (locale: '', params: any) => {
    // console.log('params: ',params)
    const paramsId = { id: params.id };
    const token = params.token;
    const appId = params.appId;
    return request.get(`${baseUrl(locale)}/admin/crowdFundingBaseInfo/selectById${setLocaleQuery(locale)}`, {
        params: paramsId,
        headers: {
            Authorization: `Bearer ${token}`,
            'x-appid': appId,
        },
    });
};

// =========门店地图 - start ===========
// 查询国家列表
export const getCountryList = (locale: string) => {
    return request.get(`${baseUrl(locale)}/storeAddress/countryList`, {
        needToken: true,
    });
};

// 查询省/州列表
export const getStateList = (params: any, locale: string) => {
    return request.get(`${baseUrl(locale)}/storeAddress/stateList`, {
        params,
        needToken: true,
    });
};

// 查询城市列表
export const getCityList = (params: any, locale: string) => {
    return request.get(`${baseUrl(locale)}/storeAddress/cityList`, {
        params,
        needToken: true,
    });
};

// 查询门店类型
export const getTypeList = (params: any, locale: string) => {
    return request.get(`${baseUrl(locale)}/storeAddress/typeList`, {
        params,
        needToken: true,
    });
};

// 查询门店
export const getStoreList = (params: any, locale: string) => {
    return request.get(`${baseUrl(locale)}/storeAddress/list`, {
        params: { ...params, addressEnable: true },
        needToken: true,
    });
};

// 导航栏预览 /website/admin/navigationBar/preview
export const getNavBarPreview = (locale: '', params: any, token: string | undefined) => {
    return request.get(`${baseUrl(locale)}/admin/navigationBar/preview`, {
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
