import { loginUser } from '@/utils/cookie';
import omit from 'lodash/omit';
import cacheData from 'memory-cache';
import { useRouter } from 'next/router';
import qsParse from 'qs/lib/parse';
import queryString from 'query-string';
import cookie from 'react-cookies';
//import filemap from '@/utils/filemap';
import { APP_BY_LANG, ENGLISH_LANG, i18nCCYMap, i18nMap, READY_LANG, readyStaticProductPage } from '@/constants';
import { getCountryRegion } from '@/utils/countryRegion';
import logger from '@/utils/logger';
import generateTraceId from '@/utils/singUtil/generateTraceId';

export const isDev = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production';
export const isTest = process.env.NEXT_PUBLIC_WEB_HOST == 'https://website-staging.ecoflow.com';
export const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL;

// 检测当前语言是否全面兼容前端界面和后端 API
export const isReadyLang = (locale: string) => {
    return READY_LANG?.includes(locale);
};

export const getHostApi = (locale: string) => {
    const API_HOST1 = process.env.NEXT_PUBLIC_API_HOST;
    const LOGIN_HOST1 = process.env.NEXT_PUBLIC_LOGIN_HOST;
    const API_HOST2 = process.env.NEXT_PUBLIC_API_HOST_CN;
    const LOGIN_HOST2 = process.env.NEXT_PUBLIC_LOGIN_HOST_CN;
    const API_HOST3 = process.env.NEXT_PUBLIC_API_HOST_EU;
    const LOGIN_HOST3 = process.env.NEXT_PUBLIC_LOGIN_HOST_EU;

    const obj = {
        NEXT_PUBLIC_API_HOST: API_HOST1,
        NEXT_PUBLIC_LOGIN_HOST: LOGIN_HOST1,
    };

    if (getCountryRegion(locale) == 'cn') {
        obj.NEXT_PUBLIC_API_HOST = API_HOST2;
        obj.NEXT_PUBLIC_LOGIN_HOST = LOGIN_HOST2;
    }

    if (getCountryRegion(locale) == 'eu') {
        obj.NEXT_PUBLIC_API_HOST = API_HOST3;
        obj.NEXT_PUBLIC_LOGIN_HOST = LOGIN_HOST3;
    }

    return obj;
};

export const requestHeader = (lang: string) => {
    let ret = {};

    // V1.2.0 只对四个英文版切换 appid（ENGLISH_LANG 包含了 us, uk, ca, au）
    if (typeof lang !== 'undefined' && typeof APP_BY_LANG[lang] !== 'undefined' && isReadyLang(lang)) {
        const appInfo: any = APP_BY_LANG[lang];
        ret = {
            'x-appid': appInfo.appId,
            'Accept-Language': appInfo.acceptLanguage,
        };
    }
    return ret;
};

// 只有 WordPress 接口用到，不需要区分多语言 Request Header
async function fetchWithCache(url: string, options: object) {
    const value = cacheData.get(url);
    if (value) {
        return value;
    } else {
        const hours = Number(process.env.NEXT_PUBLIC_CACHE_WORDPRESS) / (60 * 60);
        const res = await fetch(url, options);
        const data = await res.json();
        cacheData.put(url, data, hours * 1000 * 60 * 60);
        return data;
    }
}

// 只有 WordPress 接口用到，不需要区分多语言 Request Header
async function fetchWithCache2(url: string) {
    const res = await fetch(url);
    const data = await res.json();
    return data;
}

export const fetchWP = async (path: string, locale = 'us') => {
    // kr, za 没有 blog，转向 us
    // eu blog，转向 uk
    if (['kr', 'za'].includes(locale)) {
        locale = 'us';
    } else if (locale === 'eu') {
        locale = 'uk';
    }

    const dataRaw = await fetchWithCache(`${process.env.NEXT_PUBLIC_BLOG_HOST}/${locale}/?rest_route=/wp/v2/${path}`, {
        cache: 'force-cache',
    });
    return dataRaw;
};

export const fetchWP2 = async (path: string, locale = 'us') => {
    // kr, za 没有 blog，转向 us
    // eu blog，转向 uk
    if (['kr', 'za'].includes(locale)) {
        locale = 'us';
    } else if (locale === 'eu') {
        locale = 'uk';
    }

    const dataRaw = await fetchWithCache2(`${process.env.NEXT_PUBLIC_BLOG_HOST}/${locale}/?rest_route=/wp/v2/${path}`);
    return dataRaw;
};

export const capitalizeFirstChar = (str: string) => {
    return str ? str?.toLowerCase()?.replace(/( |^)[a-z]/g, (L) => L.toUpperCase()) : '';
};

export const Trim = (str: any, is_global: any) => {
    let result;
    result = str?.replace(/(^\s+)|(\s+$)/g, '');
    if (is_global?.toLowerCase() == 'g') {
        result = result?.replace(/\s/g, '_', '');
    }
    return result;
};

export const parseUrlQuery = (path: string) => {
    let urlQueryString = path;
    let ret = {} as object;
    if (/^http/i.test(path)) {
        const urlObj = new URL(path);
        urlQueryString = urlObj.search;
    }
    ret = qsParse(urlQueryString, { ignoreQueryPrefix: true });
    return ret;
};

export const fetchRaw = async (path: string, options: any = {}) => {
    const queryObj: any = parseUrlQuery(path);
    const lang = queryObj.locale || 'us';
    let fetchPath = path;
    let headerObj = {};

    if (typeof lang !== 'undefined' && typeof APP_BY_LANG[lang] !== 'undefined' && isReadyLang(lang)) {
        headerObj = requestHeader(lang);
        const langQueryReg = new RegExp(`(\\?|&)locale=${lang}$`);
        fetchPath = path.replace(langQueryReg, '');
    }

    const traceId = generateTraceId();

    const headers = {
        'x-appid': process.env.NEXT_PUBLIC_API_ID,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Vary: 'Origin,Access-Control-Request-Headers,Access-Control-Request-Method',
        ...(isDev ? { cache: 'no-cache' } : {}),
        ...(options?.headers ? options.headers : {}),
        // 多语言接口数据正常了，再 uncomment 下面一行
        ...headerObj,
        traceId,
    };

    let res, dataRaw;

    // TODO: Debug
    // logger.info(
    //     JSON.stringify({
    //         url: fetchPath,
    //         headers,
    //     }),
    // );

    try {
        dataRaw = await fetch(fetchPath, {
            headers,
            ...omit(options, ['header', 'cacheTime']),
        });
    } catch (err) {
        logger.error(
            JSON.stringify({
                headers,
                url: fetchPath,
                err,
            }),
        );
    }

    try {
        res = await dataRaw?.json();
    } catch (err) {
        res = {};
    }
    return res || {};
};

// 基于 fetchRaw 再封装
export const efFetch = async (path: string, options: { headers?: object; body?: any; cacheTime?: number } = {}) => {
    const fetchOptions = omit(options, ['cacheTime']);
    if (Number(options.cacheTime) > 0 && typeof window === 'undefined') {
        const cacheKey = path + JSON.stringify(fetchOptions);
        const value = cacheData.get(cacheKey);
        if (!value) {
            const fetchData = await fetchRaw(path, fetchOptions);
            cacheData.put(cacheKey, fetchData, options.cacheTime);
            return fetchData;
        }
        return value;
    } else {
        return fetchRaw(path, fetchOptions);
    }
};

// 用户相关接口与 x-appid 无关联
export const formOfFetch = async (path: string, bodyFormData: any, opts?: any) => {
    const queryObj: any = parseUrlQuery(path);
    const lang = queryObj.locale || 'us';
    const dataRaw: any = await fetch(path, {
        method: opts?.method || 'post',
        body: bodyFormData,
        headers: {
            Authorization: `Bearer ${loginUser()?.token || ''}`,
            ...(isDev ? { cache: 'no-cache' } : {}),
            ...(opts?.headers ? opts.headers : {}),

            // us, uk, ca, au 统一使用 us
            ...(ENGLISH_LANG.includes(lang) ? { 'Accept-Language': 'en-US,en;q=0.9' } : omit(requestHeader(lang), ['x-appid'])),

            traceId: generateTraceId(),
        },
    });

    // 如果后台抛回 401，直接跳转到登入页面
    const statusCode = await dataRaw.status;
    if (statusCode === 401) {
        cookie.remove('userInfo', { path: '/' });
        cookie.remove('us-token');
        if (typeof window !== 'undefined') {
            const locationHref = location.href;
            if (!locationHref.includes('login') || !locationHref.includes('tempedit')) {
                window.location.replace(`/login?redirect=${encodeURIComponent(locationHref)}`);
            }
        }
    }

    return await dataRaw.json();
};

export const handleSeo = (data: any, asPath: string, locale: string) => {
    if (!data) return;
    const seoProps: any = {};
    const url = data.url || '';
    let pathname = '/';
    if (typeof window !== 'undefined') {
        if (data?.type === 'PRODUCT') {
            pathname += url;
        }
        if (data?.type === 'PRODUCT_CATEGORY') {
            pathname += `${locale}/collections/${url}`;
        }
        window.history.replaceState({ asPath }, '', pathname);

        if (data?.title) {
            seoProps.title = data.title + (!/\| EcoFlow$/.test(window.document.title) ? ' | EcoFlow' : '');
        }

        if (data?.description) {
            seoProps.description = data.description;
        }
    }
    return seoProps;
};

export const htmlDecode = (text: string) => {
    let temp: any = document.createElement('div');
    temp.innerHTML = text || '';
    const output = temp.innerText || temp.textContent;
    temp = null;
    return output;
};

// 处理 /public 下的文件路径：在生产环境下，前缀加 CDN 地址
export const f = (src: string) => {
    const cdnSrc = /^http/i.test(src) ? src : (process.env.NEXT_PUBLIC_CDN_URL || '') + src;
    return isProd ? cdnSrc : src;
};

export const getFileInfo = (name = '') => {
    return {
        path: name.substring(0, name.lastIndexOf('/')),
        filename: name.substring(name.lastIndexOf('/') < 0 ? 0 : name.lastIndexOf('/') + 1, name.lastIndexOf('.')),
        ext: name.substring(name.lastIndexOf('.') + 1),
        excludeExt: name.substring(0, name.lastIndexOf('.')),
    };
};

// export const handleDynamicPath = (src: any, isDpr = false) => {
//     if (/^http/i.test(src)) return src;
//     const info: any = getFileInfo(src);
//     if (isDpr) {
//         const hash = info.filename.replace(/@[\d]+x/, '');
//         const dpr = info.filename.match(/@[\d]+x/)[0];
//         let originFilename;
//         const originFilenameList = Object.entries(filemap as any).filter((v) => v.includes(hash)) || [];
//         if (originFilenameList.length) {
//             originFilename = (originFilenameList.find((v) => getFileInfo(v[0]).path === info.path) || [])[0];
//         }
//         if (originFilename) src = `${info.path}/${getFileInfo(originFilename).filename}${dpr}.${info.ext}`;
//     }
//     return (filemap as any)[src] ? `${CDN_URL}${info.path}/${(filemap as any)[src]}.${info.ext}` : `${CDN_URL}${src}`;
// };

export const handleMultiProductImages = (img: any, type?: string) => {
    if (!img) {
        return '/img/search/2461bcec7e9c85a041ff2f95d5e476ea.png';
    }
    try {
        const imgObj = JSON.parse(img);
        if (typeof imgObj === 'object' && imgObj !== null) {
            return type === 'nav'
                ? imgObj.navigatorImages
                : type === 'specs'
                ? imgObj.spaceImages
                : type === 'trans'
                ? imgObj.transparentImages
                : imgObj?.images;
        }
    } catch (e) {
        return img;
    }
};

// 记录所有详情页
export const allDetailPages = [
    'product_ps_delta_delta_1300',
    'product_ps_delta_delta_max',
    'product_ps_delta_delta_pro',
    'product_ps_delta_delta_mini',
    'product_ps_river_river_pro_720',
    'product_ps_river_river_max',
    'product_ps_river_river',
    'product_ps_river_river_mini',
    'product_sp_400w_solar_panel',
    'product_sp_160w_solar_panel',
    'product_sp_110w_solar_panel',
    'product_sp_220w_bi_solar_panel',
    'product_sp_60w_solar_panel',
    'product_shp',
    'product_sg_dg100',
    'product_st_solar_tracker',
    'product_ps_delta_delta_2',
    'product_sg_dg200',
    'product_sp_100w_rigid_solar_panel',
    'product_sp_100w_solar_panel',
    'product_ps_river_river_256',
    'product_ps_river_river_768',
];

// 详情页：获取各详情页Other Product组件的数据
export const getDetaileMoreProducts = (gloproductlist: Array<object>, arr: Array<string>, title = '', more = '', moreUrl = '/collections/power') => {
    if (!Array.isArray(arr)) {
        return {};
    }

    const result = [] as Array<object>;
    arr?.forEach((item2: any) => {
        gloproductlist?.forEach((item: any) => {
            if (item2 === item.generalKey) {
                let accessoryFlag = item.accessoryFlag;
                if (item.url === 'smart-generator') {
                    accessoryFlag = false;
                }
                // console.log('item', item2, item);
                result.push({
                    titleText: item.name || '',
                    linkUrl: accessoryFlag ? item.buyLink : `/${item.url}`,
                    imgUrl: item?.transparentImages || '',
                    //navigatorImgUrl: item?.navigatorImages || '',
                });
            }
        });
    });

    return {
        title,
        more,
        moreUrl,
        items: result,
    };
};

// 详情页：获取各详情页Other Product组件的数据
export const getDetailePageOtherProducts = (
    seoMap: Array<object>,
    spuList: Array<object>,
    arr: Array<string>,
    title = '',
    more = '',
    moreUrl = '/collections/power',
) => {
    if (!Array.isArray(arr)) {
        return {};
    }
    const result = [] as Array<object>;
    arr?.forEach((item: any) => {
        const targetObj: any = spuList.filter((spu: any) => spu.generalKey === item)[0];
        if (targetObj?.spuId) {
            const seo: any = seoMap?.find((e: any) => e.type === 'PRODUCT' && e.sourceId === targetObj?.spuId);
            targetObj.url = seo?.url || '';
            targetObj.imageInfo = targetObj?.imageInfo || JSON.parse(targetObj?.images || '{}');
        }

        targetObj && result.push(targetObj);
    });
    if (!result?.length) {
        return {};
    }

    return {
        title,
        more,
        moreUrl,
        items: result
            ?.map((item: any) => ({
                titleText: item.name ?? '',
                linkUrl: allDetailPages.includes(item.generalKey) && item.spuId ? `/${item?.url || ''}` : item.buyLink || '/',
                imgUrl: item?.imageInfo?.transparentImages || item?.imageInfo?.images || 'error',
                navigatorImgUrl: item?.imageInfo?.navigatorImages || 'error',
            }))
            .slice(0, 3),
    };
};

export const browserIsMobile = (userAgent: string, navi: any) => {
    const ua = userAgent?.toLowerCase() || '';
    return /mobile|android|iphone|ipod|phone|midp|rv:1.2.3.4|ucweb|windows ce|windows mobile|ecoflowapp|ipad/i.test(ua);
};

export const browserIsInApp = (userAgent: string, navi: any) => {
    const ua = userAgent?.toLowerCase() || '';
    return /ecoflowapp/i.test(ua);
};

export const dashName = (str: string) => {
    let ret = '';
    if (String(str) === str) {
        ret = str?.replace(/[^a-zA-Z0-9]+/g, ' ');
        ret = ret?.trim()?.toLowerCase();
        ret = ret?.replace(/ /g, '-');
    }
    return ret;
};

export const navPicPreload = (spuList: any) => {
    if (!spuList || typeof window === 'undefined') return;
    const imgs = spuList?.map((item: any) => handleMultiProductImages(item?.images, 'nav'));
    const asyncImageLoader = (url: string) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = url;
            image.onload = () => resolve(image);
            image.onerror = () => reject(new Error('could not load image'));
        });
    };
    setTimeout(() => {
        const imgsPromise = Promise.all(imgs?.map((img: string) => asyncImageLoader(img)));
        imgsPromise
            .then((res) => {
                // console.log(res)
            })
            .catch((err) => {
                // handle error if you throw an Error instance
                // console.log('error:', err.message)
            });
    });
};

export const isJson = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const getWhitePageList = ({ type }: any, seoMap: any) => {
    const pageList: Array<string> = [];
    const pageType = type.toUpperCase();
    const filterMap = seoMap?.filter((item: any) => item.type === pageType);
    let temp = '';
    filterMap?.forEach((item: any) => {
        temp = item.url.split('/')?.pop();
        pageList.push(temp);
    });
    return pageList;
};

export const setLocaleQuery = (locale?: string) => {
    // V1.2.0 只对四个英文版切换 appid（ENGLISH_LANG 包含了 us, uk, ca, au）
    return typeof locale !== 'undefined' && isReadyLang(locale) ? `?locale=${locale}` : '';
};

// 随机生成唯一标识
export const getuuid = () => Number(Math.random().toString().substring(2, 7) + Date.now()).toString(36);

export const homeIntoDetail = (url: string, router: any) => {
    if (!url) {
        return;
    }
    if (typeof window !== 'undefined') {
        if (url.includes(location.origin)) {
            router.push(url);
        } else {
            window.open(url);
        }
    }
};

export const formatLink = (url: string) => {
    if (!url) {
        return {
            href: 'https://www.ecoflow.com',
        };
    }
    if (typeof window !== 'undefined') {
        if (url.includes(location.origin)) {
            return {
                href: url,
                selfWin: url.includes(location.origin),
            };
        }
    }
    return {
        href: url,
    };
};

// 当前link标签是否已物理化
export const isProductLinkInReadyStaticProductPage = (link: string) => {
    let flag = false;

    for (const key in readyStaticProductPage) {
        const val = readyStaticProductPage[key];
        if (link.indexOf(val) > -1) {
            flag = true;
            break;
        }
    }

    return flag;
};

export const homeGoDetail = ({ item, locale, router }: any) => {
    const addLocale = (el: string) => `/${locale}${el}`;
    if (typeof window !== 'undefined') {
        if (typeof item === 'object') {
            if (item.buyLink) {
                if (item.link && isProductLinkInReadyStaticProductPage(item.link)) {
                    router.push(item.link);
                } else {
                    router.push('/[...product]', item.link);
                }
            } else {
                router.push(item.link);
            }
        }
        if (typeof item === 'string') {
            if (item?.includes('/collections/')) {
                router.push('/collections/[series]', addLocale(item));
            } else {
                router.push(addLocale(item));
            }
        }
    }
};

export const homeLinkProps = (ele: any, isBuyLink = false) => {
    let ret = {};
    if (typeof ele === 'object') {
        if (ele.buyLink) {
            if (!isBuyLink) {
                ret = { href: ele.link };
            } else {
                ret = {
                    href: ele.buyLink,
                };
            }
        } else {
            ret = {
                href: ele.link,
            };
        }
    }
    if (typeof ele === 'string') {
        if (ele?.includes('/collections/') && ele?.indexOf('delta-2-portable-power-station') < 0) {
            ret = {
                href: '/collections/[series]',
                as: ele,
            };
        } else {
            ret = {
                href: ele,
            };
        }
    }
    return ret;
};

export const seriesLinkProps = (item: any) => {
    let ret = { href: item?.detailsLink } as object;

    // Product
    if (item?.type === 'product') {
        if (isProductLinkInReadyStaticProductPage(item.detailsLink.substr(1))) {
            ret = {
                href: item.detailsLink,
            };
        } else {
            ret = {
                href: '/[...product]',
                as: item.detailsLink,
            };
        }
    }

    // Collection
    if (item?.type === 'collection' && item.detailsLink !== '/collections/solar-generators') {
        ret = {
            href: item.detailsLink,
        };
    }

    // 应急使用，这种设计不合理，建议后续优化
    if (item.detailsLink === '/collections/solar-generators') {
        ret = {
            href: '/collections/solar-generators',
        };
    }

    // outer link => Blog
    if (item?.target === '_blank' && item?.detailsLink === 'https://blog.ecoflow.com') {
        const locale = useRouter()?.locale || 'us';
        ret = {
            href: `${item?.detailsLink}/${locale}`,
        };
    }
    return ret;
};

// 多语言 // => SITE-846 仅针对Terms of Use 和 Privacy Policy
export const langs: any = {
    // en: 'en',
    cn: 'zh-cn',
    ja: 'ja-jp',
    kr: 'ko-kr',
    es: 'es-es',
    ru: 'ru-ru',
    cis: 'ru-ru',
    de: 'de-de',
    it: 'it-it',
    nl: 'nl-nl',
    fr: 'fr-fr',
    eu: 'en-eu',
    uk: 'en-uk',
    us: 'en-us',
    tw: 'zh-TW',
};

// 根据浏览器语言转换
export const transferLang = () => {
    const browserLang = window?.navigator?.language ?? (window.navigator as any)?.browserLanguage;
    return langs[browserLang.substring(0, 2).toLowerCase()] || 'en';
};

// 根据浏览器语言转换--执行跳转--否则跳us
export const transferLangSkip = (locale: any, type: number) => {
    const langA = langs[locale] || 'en-us';
    if (typeof window !== 'undefined') {
        if (type === 1) {
            window.location.replace(`https://account.ecoflow.com/agreement/${langA}/TermsOfUse.html`);
        }
        if (type === 2) {
            window.location.replace(`https://account.ecoflow.com/agreement/${langA}/PrivacyPolicy.html`);
        }
    }
};

export const redirectTo404 = () => ({
    notFound: true,
});

export const GetUrlParams = (name: string) => {
    return queryString.parse(location.search)[name] || '';
};

export const FormatMoney = (key: number | string, splitStr = ',') => {
    let value;
    if (key || key === 0) {
        const tmpKey = key.toString();
        const reg = /(\d)(?=(\d{3})+$)/g;
        value = tmpKey.replace(reg, `$1${splitStr}`);
    }
    return value;
};

/**
 * 将spec参数组name，与产品的spec参数value，融合到一起
 * @param productCatagory 产品品类及spec name
 * @param productDetail spu spec value
 */
export const formatProductSpecData = (catagoryList: any[], skuList = []) => {
    const emptyValueTxt = '–';
    if (!skuList.length || !catagoryList.length) return [];
    /**
     * 1、将genericSpec， ownSpec字符串解析成对象（此时，对象中的值仍为json字符串）
     * 2、将genericSpec， ownSpec合并到skuSpec
     */
    const formatSkuList = skuList.map((sku: any) => {
        const { name, generalKey, productCatagoryId, ownSpec } = sku;
        const specGroupMap = JSON.parse(ownSpec || '{}');
        let specsValueMap = {};
        for (const key in specGroupMap) {
            if (typeof specGroupMap[key] === 'string') {
                const data = JSON.parse(specGroupMap[key]);
                specsValueMap = { ...specsValueMap, ...data };
            } else {
                specsValueMap = { ...specsValueMap, ...specGroupMap[key] };
            }
        }
        return { name, generalKey, productCatagoryId, specsValueMap, arrayList: [] };
    });

    /**
     * 1、遍历产品品类的specGroupList
     * 2、内层遍历spu经过合并的参数组，并且生成一个specList，合入每一个specGroup中
     */
    const catagoryMap: any = {};
    catagoryList.forEach((catagory) => {
        if ('specGroupList' in catagory) {
            const { productCatagoryId, specGroupList } = catagory;
            let group: any[] = [];
            specGroupList.forEach((specGroup: any) => (group = group.concat(specGroup.specParamList)));
            catagoryMap[productCatagoryId] = group;
        }
    });

    for (const i in formatSkuList) {
        const sku = formatSkuList[i];
        const specsNameList = catagoryMap[sku.productCatagoryId];
        const { specsValueMap } = sku as any;
        specsNameList.forEach((item: any) => {
            let value = specsValueMap[item.specParamId] || emptyValueTxt;
            if (item.fieldType === 'boolean' || typeof value === 'boolean') {
                value = value ? '✓' : emptyValueTxt;
            }
            (sku.arrayList as any).push({
                unit: '',
                ...item,
                key: item.name,
                value,
            });
        });
    }
    return formatSkuList;
};

export const checkSupportWebp = () => {
    // toDataURL方法判断会存在版本滞后
    try {
        return document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0;
    } catch (err) {
        return false;
    }
};

export const checkSupportWebm = () => {
    try {
        return document.createElement('video').canPlayType('video/webm');
    } catch (err) {
        return false;
    }
};

export const loadScript = (url: string) => {
    if (!url) return Promise.reject();
    return new Promise<void>((resolve, reject) => {
        const script = document.createElement('script') as any;
        const head = document.head || document.getElementsByTagName('head')[0];
        script.type = 'text/javascript';
        script.src = url;
        if (script.readyState) {
            script.onreadystatechange = () => {
                if (script.readyState === 'loaded' || script.readyState === 'complete') {
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else {
            script.onload = () => {
                resolve();
            };
        }

        script.onerror = () => {
            reject();
        };

        head.appendChild(script);
    });
};

export const intlNumber = (locale: string, num: number) => new Intl.NumberFormat(i18nMap[locale]).format(num);

export const intlPrice = (locale: string, num: number) => {
    let price: string;
    if (locale === 'za') {
        price = `${i18nCCYMap[locale]} ${new Intl.NumberFormat(i18nMap[locale]).format(num)}`;
    } else if (locale === 'fr') {
        price = new Intl.NumberFormat(i18nMap[locale]).format(num) + i18nCCYMap[locale];
    } else {
        price = i18nCCYMap[locale] + new Intl.NumberFormat(i18nMap[locale]).format(num);
    }
    return price;
};

export const intlDate = (locale: string, date: number | Date) => new Intl.DateTimeFormat(i18nMap[locale]).format(date);

export const copyText = (text?: string) => {
    if (!text) return;

    if (navigator?.clipboard && window?.isSecureContext) {
        window.navigator.clipboard.writeText(text);
    } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'absolute';
        textArea.style.opacity = '0';
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.select();
        new Promise(() => {
            const copyStatus = document.execCommand('copy');
            if (!copyStatus) {
                // message.error()
            }
            textArea.remove();
        });
    }
};

export const redirectPage = ({ navRes, generalKey, productKey, router }: { navRes: any[]; generalKey: string; productKey: string; router: any }) => {
    // 如果当前产品不存在，则404
    const currentGeneral = navRes.filter((item) => item.generalKey === generalKey);
    const { spuResponseList } = currentGeneral[0];
    const currentProduct = spuResponseList.filter((item: any) => item.generalKey === productKey);
    if (currentProduct.length === 0) {
        router.push(`${location.origin}/${router.locale}/404`);
    }
};

export const initH1Tage = (text = 'EcoFlow') => {
    let h1 = document.querySelector('h1.visuallyhidden') as HTMLHeadingElement;
    if (!h1) {
        h1 = document.createElement('h1');
    }
    h1.className = 'visuallyhidden';
    h1.innerText = text;
    const firstChild = document.body.firstChild;
    document.body.insertBefore(h1, firstChild);
};
