import filemap from '@/utils/filemap';

export const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL;

export const getFileInfo = (name = '') => {
    return {
        path: name.substring(0, name.lastIndexOf('/')),
        filename: name.substring(name.lastIndexOf('/') < 0 ? 0 : name.lastIndexOf('/') + 1, name.lastIndexOf('.')),
        ext: name.substring(name.lastIndexOf('.') + 1),
        excludeExt: name.substring(0, name.lastIndexOf('.')),
    };
};

export const handleDynamicPath = (src: any, isDpr = false) => {
    if (/^http/i.test(src)) return src;
    const info: any = getFileInfo(src);
    if (isDpr) {
        const hash = info.filename.replace(/@[\d]+x/, '');
        const dpr = info.filename.match(/@[\d]+x/)[0];
        let originFilename;
        const originFilenameList = Object.entries(filemap as any).filter((v) => v.includes(hash)) || [];
        if (originFilenameList.length) {
            originFilename = (originFilenameList.find((v) => getFileInfo(v[0]).path === info.path) || [])[0];
        }
        if (originFilename) src = `${info.path}/${getFileInfo(originFilename).filename}${dpr}.${info.ext}`;
    }
    return (filemap as any)[src] ? `${CDN_URL}${info.path}/${(filemap as any)[src]}.${info.ext}` : `${CDN_URL}${src}`;
};
