const getStringRandomA = () => {
    const numberA1 = Math.random();
    const numberA2 = `${numberA1}`.slice(2);
    const numberA3 = Number(numberA2);
    const numberA4 = numberA3.toString(36);
    
    return numberA4;
}
// 生成多少位数的随机字符串
export const getStringRandom = (total = 16) => {
    const val1 = getStringRandomA();
    const val2 = getStringRandomA();
    const val3 = getStringRandomA();
    const val4 = getStringRandomA();
    
    return `${val1}${val2}${val3}${val4}`.slice(0, total);
}