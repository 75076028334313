'use strict';

var createScript = function createScript() {
  var script = document.createElement('script');
  script.innerHTML = "\n ;(function (e, s, t) { ;(t = t || {}), (t.id = e), (versaTagObj = { $: [], onready: function (e) { this.$.push(e) }, }); var n = s.getElementsByTagName('script')[0], r = s.createElement('script') ;(r.options = t), (r.async = !0), (r.src = 'https://secure-ds.serving-sys.com/SemiCachedScripts/ebOneTag.js?id=' + e), (r.options = t), n.parentNode.insertBefore(r, n) })('1073748260', document, { dataLayer: 'SizmekDataLayer' })";
  return script;
};
var SizmekManager = {
  initialize: function initialize(locale) {
    var lang = locale ? locale.toLowerCase() : '';
    if (lang === 'jp') {
      document.head.insertBefore(createScript(), document.head.childNodes[0]);
    }
  }
};
module.exports = SizmekManager;