// 底部不同语言地址不一样
export const sharelinkbylang = (locale: any) => {
    const links: any = {
        us: {
            FACEBOOK: 'https://www.facebook.com/ecoflowtech',
            TWITTER: 'https://twitter.com/EcoFlowTech',
            INSTAGRAM: 'https://www.instagram.com/ecoflowtech/',
            YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
            Tiktok: 'https://www.tiktok.com/@ecoflowtech',
        },
        au: {
            FACEBOOK: 'https://www.facebook.com/ecoflowaustralia',
            INSTAGRAM: 'https://www.instagram.com/ecoflow.australia',
            YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
        },
        uk: {
            FACEBOOK: 'https://www.facebook.com/EcoFlowUK/',
            TWITTER: 'https://twitter.com/EcoFlowTech',
            INSTAGRAM: 'https://www.instagram.com/ecoflow.uk/',
            YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
            Tiktok: 'https://www.tiktok.com/@ecoflow.uk?lang=en',
        },
        jp: {
            FACEBOOK: 'https://www.facebook.com/ecoflowjapan',
            TWITTER: 'https://twitter.com/EcoflowJapan',
            INSTAGRAM: 'https://www.instagram.com/ecoflowjapan/',
            YOUTUBE: 'https://www.youtube.com/channel/UCup-8KjYcdzZf6iP583Q5FQ',
            LINE: 'https://lin.ee/g7W0VbD',
        },
        kr: {
            FACEBOOK: 'https://www.facebook.com/EcoFlow-KR-100921972559202/',
            TWITTER: 'https://twitter.com/EcoFlowTech',
            INSTAGRAM: 'https://www.instagram.com/ecoflow_korea/',
            NAVER: 'https://cafe.naver.com/ecoflowkr',
        },
        fr: {
            FACEBOOK: 'https://www.facebook.com/EcoFlowFrance',
            TWITTER: 'https://twitter.com/EcoFlowTech',
            INSTAGRAM: 'https://www.instagram.com/ecoflow.france/',
            YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
        },
        de: {
            FACEBOOK: 'https://www.facebook.com/EcoFlowDeutschland/',
            TWITTER: 'https://twitter.com/EcoFlowTech',
            INSTAGRAM: 'https://www.instagram.com/ecoflow.deutschland/',
            YOUTUBE: 'https://www.youtube.com/@EcoFlowDeutschland',
        },
        es: {
            FACEBOOK: 'https://www.facebook.com/EcoFlowEspana',
            TWITTER: 'https://twitter.com/EcoFlowTech',
            INSTAGRAM: 'https://www.instagram.com/ecoflow.espana/',
            YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
        },
        it: {
            FACEBOOK: 'https://www.facebook.com/EcoFlowItalia/',
            TWITTER: 'https://twitter.com/EcoFlowTech',
            INSTAGRAM: 'https://www.instagram.com/ecoflow.italia/',
            YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
        },
        cn: {
            Wechat: 'https://mp.weixin.qq.com/s/Fh4IKacNCJyeilg9ZkwLBg',
            Weibo: 'https://weibo.com/u/7442486327',
            Tiktok: 'https://www.douyin.com/user/MS4wLjABAAAAyNcHZEfHx3i9VKdIuyylD_43HOqyD3DJBR8bUksY9yk',
            Bilibili: 'https://space.bilibili.com/592286456?spm_id_from=333.337.0.0',
        },
        ru: {
            YOUTUBE: 'https://www.youtube.com/@ecoflowrussia',
            TELEGRAM: 'https://t.me/EcoFlowRussia',
            VK: 'https://vk.com/ecoflow',
        },
        cis: {
            YOUTUBE: 'https://www.youtube.com/@ecoflowrussia',
            TELEGRAM: 'https://t.me/EcoFlowRussia',
            VK: 'https://vk.com/ecoflow',
        },
        za: {
            FACEBOOK: 'https://www.facebook.com/ecoflowsouthafrica/',
            INSTAGRAM: 'https://www.instagram.com/ecoflow.southafrica/',
            YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
            Tiktok: 'https://www.tiktok.com/@ecoflow.southafrica',
        },
        sa: {
            FACEBOOK: 'https://www.facebook.com/EcoFlowArabia',
            TWITTER: 'https://twitter.com/EcoFlowArabia',
            INSTAGRAM: 'https://www.instagram.com/ecoflow.arabs/',
            YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
            Tiktok: 'https://www.tiktok.com/@ecoflowtech',
        },
    };

    const target: any = links[locale] || links['us'];

    return {
        FACEBOOK: target['FACEBOOK'],
        TWITTER: target['TWITTER'],
        INSTAGRAM: target['INSTAGRAM'],
        YOUTUBE: target['YOUTUBE'],
        NAVER: target['NAVER'],
        LINE: target['LINE'],
        Wechat: target['Wechat'],
        Weibo: target['Weibo'],
        Tiktok: target['Tiktok'],
        Bilibili: target['Bilibili'],
        TELEGRAM: target['TELEGRAM'],
        VK: target['VK'],
    };
};

export const URL = {
    FACEBOOK: 'https://www.facebook.com/ecoflowtech',
    TWITTER: 'https://twitter.com/EcoFlowTech',
    INSTAGRAM: 'https://www.instagram.com/ecoflowtech/',
    YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
    APP_IOS: 'https://apps.apple.com/us/app/ecoflow-power-a-new-world/id1506693140#?platform=iphone',
    APP_IOS_RU: 'https://apps.apple.com/ru/app/ecoflow-power-a-new-world/id1506693140#?platform=iphone',
    APP_IOS_CN: 'https://apps.apple.com/cn/app/ecoflow%E4%B8%AD%E6%96%87%E7%89%88/id1531850847#?platform=iphone',
    APP_IOS_JP: 'https://apps.apple.com/jp/app/ecoflow-power-a-new-world/id1506693140#?platform=iphone',
    APP_ANDROID: 'https://play.google.com/store/apps/details?id=com.ecoflow',
    APK_OVERSEAS: 'https://ecoflow-service-us-cdn.ecoflow.com/apk/app-release.apk',
    APK_CN: 'https://ecoflow-service-cn-cdn.ecoflow.com/apk/app-release-zh.apk',
    TELEGRAM: 'https://t.me/EcoFlowRussia', // ru
    VK: 'https://vk.com/ecoflow', // ru
};

export const CROWDFUNDING_MEDIA: any = {
    us: {
        FACEBOOK: 'https://www.facebook.com/ecoflowtech',
        INSTAGRAM: 'https://www.instagram.com/ecoflowtech/',
        YOUTUBE: 'https://www.youtube.com/c/EcoFlowTech/featured',
        TWITTER: 'https://twitter.com/EcoFlowTech',
        REDDIT: 'http://bit.ly/3U9hP0j',
        TIKTOK: 'https://www.tiktok.com/@ecoflowtech',
    },
    ca: {
        FACEBOOK: 'https://www.facebook.com/ecoflowtech',
        INSTAGRAM: 'https://www.instagram.com/ecoflowtech/',
        YOUTUBE: 'https://www.youtube.com/c/EcoFlowTech/featured',
        TWITTER: 'https://twitter.com/EcoFlowTech',
        REDDIT: 'https://bit.ly/3ZyrjmQ',
        TIKTOK: 'https://www.tiktok.com/@ecoflowtech',
    },
    jp: {
        FACEBOOK: 'https://www.facebook.com/ecoflowjapan',
        INSTAGRAM: 'https://www.instagram.com/ecoflowjapan/',
        // YOUTUBE: 'https://www.youtube.com/@EcoFlowJapan/channels',
        YOUTUBE: 'https://www.youtube.com/@EcoFlowJapan/featured',
        TWITTER: 'https://twitter.com/EcoflowJapan',
        LINE: 'https://lin.ee/g7W0VbD',
    },
    // 新增欧洲早鸟wave2
    de: {
        FACEBOOK_COMMUNITY: 'https://www.facebook.com/groups/ecoflowclubdeutsch/',
        // FACEBOOK: 'https://www.facebook.com/EcoFlowDeutschland/',
        INSTAGRAM: 'https://www.instagram.com/ecoflow.deutschland/',
        YOUTUBE: 'https://www.youtube.com/@EcoFlowDeutschland',
    },
    fr: {
        FACEBOOK_COMMUNITY: 'https://www.facebook.com/groups/ecoflowfrancophone',
        // FACEBOOK: 'https://www.facebook.com/EcoFlowFrance',
        INSTAGRAM: 'https://www.instagram.com/ecoflow.france/',
        YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
    },
    it: {
        FACEBOOK_COMMUNITY: 'https://www.facebook.com/EcoFlowItalia/',
        // FACEBOOK: 'https://www.facebook.com/EcoFlowItalia/',
        INSTAGRAM: 'https://www.instagram.com/ecoflow.italia/',
        YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
    },
    es: {
        FACEBOOK_COMMUNITY: 'https://www.facebook.com/EcoFlowEspana',
        // FACEBOOK: 'https://www.facebook.com/EcoFlowEspana',
        INSTAGRAM: 'https://www.instagram.com/ecoflow.espana/',
        YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
    },
    uk: {
        FACEBOOK_COMMUNITY: 'https://www.facebook.com/groups/ecoflowuk',
        // FACEBOOK: 'https://www.facebook.com/EcoFlowUK/',
        INSTAGRAM: 'https://www.instagram.com/ecoflow.uk/',
        YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
        REDDIT: 'https://www.reddit.com/r/Ecoflow_community/',
        TIKTOK: 'https://www.tiktok.com/@ecoflow.uk?lang=en',
    },
    eu: {
        FACEBOOK_COMMUNITY: 'https://www.facebook.com/ecoflowtech',
        INSTAGRAM: 'https://www.instagram.com/ecoflowtech/',
        YOUTUBE: 'https://www.youtube.com/channel/UCk8Zk8tUAwBN_NKkORh4q2Q',
        REDDIT: 'https://www.reddit.com/r/Ecoflow_community/',
    },
};
