import { getCountryRegion } from '../countryRegion';

const API_HOST_US = process.env.NEXT_PUBLIC_API_HOST;
const LOGIN_HOST_US = process.env.NEXT_PUBLIC_LOGIN_HOST;
const API_HOST_CN = process.env.NEXT_PUBLIC_API_HOST_CN;
const LOGIN_HOST_CN = process.env.NEXT_PUBLIC_LOGIN_HOST_CN;
const API_HOST_EU = process.env.NEXT_PUBLIC_API_HOST_EU;
const LOGIN_HOST_EU = process.env.NEXT_PUBLIC_LOGIN_HOST_EU;

export default function getHostApi(locale = 'us') {
    const obj = {
        NEXT_PUBLIC_API_HOST: API_HOST_US,
        NEXT_PUBLIC_LOGIN_HOST: LOGIN_HOST_US,
    };

    if (getCountryRegion(locale) === 'cn') {
        obj.NEXT_PUBLIC_API_HOST = API_HOST_CN;
        obj.NEXT_PUBLIC_LOGIN_HOST = LOGIN_HOST_CN;
    }

    if (getCountryRegion(locale) === 'eu') {
        obj.NEXT_PUBLIC_API_HOST = API_HOST_EU;
        obj.NEXT_PUBLIC_LOGIN_HOST = LOGIN_HOST_EU;
    }

    return obj;
}
