'use client';
import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Win from './Win';

export default function Content({ id, data = {}, callback, isEditStatus = false, initShow = false }: any) {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);
    // 编辑状态
    if (isEditStatus) {
        return (
            <>
                <Win id={id} data={data} callback={callback} isEditStatus={isEditStatus} />
            </>
        );
    }
    if (!isClient) {
        return null;
    }
    return createPortal(
        <>
            <Win id={id} data={data} callback={callback} initShow={initShow} />
        </>,
        document.body,
    );
}
