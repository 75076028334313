'use strict';

var _Snippets = require("./snippets");
var _require = require("./index"),
  PtManager = _require.PtManager;
var _interopRequireDefault = function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
};
var _Snippets2 = _interopRequireDefault(_Snippets);
var ptManager = {
  ptengine: function ptengine(locale, pathname) {
    var snippets = _Snippets2.default.ptengine.tags(locale, pathname);
    var createScript = function createScript() {
      var script = document.createElement('script');
      script.innerHTML = snippets.script;
      script.type = 'text/plain';
      script.className = 'optanon-category-C0002';
      return script;
    };
    return {
      createScript: createScript
    };
  },
  initialize: function initialize(locale, pathname) {
    var ptengine = this.ptengine(locale, pathname);
    document.head.insertBefore(ptengine.createScript(), document.head.childNodes[0]);
  }
};
module.exports = ptManager;