import { NextSeo } from 'next-seo';
import { useGlobal } from '@/utils/useGlobal';

type SEO = {
    locale?: string;
    title?: string;
    keywords?: string;
    description?: string;
    titleTemplate?: string;
    additionalMetaTags?: Array<any>;
    hideTitleTemplate?: boolean;
};

const genKeywords = (words: string) => ({
    name: 'keywords',
    content: words,
});

export default function SEO({ title, keywords, description, titleTemplate = '', hideTitleTemplate = false }: SEO) {
    const { useRouter } = useGlobal();
    const { locale } = useRouter();
    const seo: SEO = {
        titleTemplate: titleTemplate ? `%s | ${titleTemplate}` : `%s | EcoFlow ${locale.toUpperCase()}`,
    };
    if (hideTitleTemplate) {
        seo.titleTemplate = '';
    }

    if (['sa'].includes(locale)) {
        seo.titleTemplate = '%s | ايكو فلو EcoFlow Arabia';
    }

    if (title) {
        seo.title = title;
    }

    if (keywords) {
        seo.additionalMetaTags = [genKeywords(keywords)];
    }

    if (description) {
        seo.description = description;
    }

    return <NextSeo {...seo} />;
}
