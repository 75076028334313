'use client';

import getLocal from '@/app/utils/getLocal';

// 始终不展示的页面
const noneShowPageGlo = ['/register', '/login', '/home-solutions', '/home-battery/PowerOcean', '/membership'];
function getNoneLocalePathname(pathname = '') {
    const pathArr1 = pathname.split('/');
    const pathArr2 = pathArr1.slice(2);
    const pathname2 = pathArr2.join('/');
    return `/${pathname2}`;
}
// 将 effectList 中的 https://www.ecoflow.com/us/support 转化成 /support
const getEffectListPathname = (effectList: any) => {
    const effectList2: any = [];
    effectList.forEach((item: any) => {
        try {
            const urlObj = new URL(item.link);
            effectList2.push(getNoneLocalePathname(urlObj.pathname));
        } catch (error) {}
    });
    return effectList2;
};
// 获取当前页面是否在作用范围
export default function getCurrentEffect(data: any) {
    const { effect, effectList = [] } = data;
    const { pathname = '' } = getLocal();
    const pathnameRel = pathname; //getNoneLocalePathname(pathname);
    const effectList2 = getEffectListPathname(effectList);

    // 预览页始终显示
    if (pathname.indexOf('/preview/') > -1) {
        return true;
    }
    // 默认不展示的页面
    if (noneShowPageGlo.includes(pathnameRel)) {
        return false;
    }
    // 全部
    if (effect === 'all') {
        return true;
    }

    // 仅这些页面生效
    if (effect === 'only' && effectList2.includes(pathnameRel)) {
        return true;
    }
    // 仅这些页面不生效
    if (effect === 'none' && !effectList2.includes(pathnameRel)) {
        return true;
    }
    return false;
}
