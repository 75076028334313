// 生成32位traceId
export default function generateTraceId() {
    const date = new Date();
    const year = `00${date?.getFullYear()}`.slice(-2);
    const month = `00${date.getMonth() + 1}`.slice(-2);
    const day = `00${date.getDay()}`.slice(-2);
    const hour = `00${date.getHours()}`.slice(-2);
    const minute = `00${date.getMinutes()}`.slice(-2);
    const second = `00${date.getSeconds()}`.slice(-2);
    const millSecond = `000${date.getMilliseconds()}`.slice(-3);
    const randomStr1 = `${Math.random()}`;
    const randomStr2 = `${Math.random()}`;

    return year + month + day + hour + minute + second + millSecond + randomStr1.slice(-8) + randomStr2.slice(-9);
};