import { loginUser } from '@/utils/cookie';

// 第三方翻译系统：文思海辉
const editModeWhiteList: Record<string, string | string[]> = {
    'baolin.xie@ecoflow.com': '',
    'riting.liu@ecoflow.com': '',
    'jocelyn.xu@ecoflow.com': '', // 运营产品经理：许洁媛
    'branko.su@ecoflow.com': ['cn', 'hk'], // 中国官网运营：苏光
    'changyi.wen@ecoflow.com': ['cn'],
    'amelie.zheng@ecoflow.com': ['us', 'ca'],
    'patrick.chong@ecoflow.com': ['uk', 'eu', 'de', 'fr', 'it', 'es'],
    'claudia.zhou@ecoflow.com': ['au', 'za'],
    'jiawei.wu@ecoflow.com': ['jp', 'kr'],
    'jessica.huang@ecoflow.com': ['eu', 'uk', 'de', 'fr', 'it', 'es'],
    'jeremie.savon@ecoflow.com': ['eu', 'uk', 'de', 'fr', 'it', 'es'],
    'krystal.zhuang@ecoflow.com': ['kr'],
    'yang.yu@ecoflow.com': '',
    'jiaming.wu@ecoflow.com': '',
    'helen.wu@ecoflow.com': '',
    'zhouming.wang@ecoflow.com': '',
    'yuzhong.dong@ecoflow.com': '',
    'kalkin.wong@ecoflow.com': '',
    'nicolas.qian@ecoflow.com': '',
    'nick.liao@ecoflow.com': '',
    'ziwen.liang@ecoflow.com': '',
    'jie.hu@ecoflow.com': '',
    'yang.xiao@ecoflow.com': '',
    'fund.gao@ecoflow.com': '',
    'timo.he@ecoflow.com': '',
    'alena.qiao@ecoflow.com': ['de', 'fr', 'it', 'es', 'cis'], // cis = ru
    'monica.zhao@ecoflow.com': ['eu', 'uk', 'de', 'fr', 'it', 'es'],
    'lionel.wu@ecoflow.com': ['es'],
    'yuki.zhang@ecoflow.com': ['jp'],
    'stella.jin@ecoflow.com': ['jp', 'kr'],
    'jennifer.zhou@ecoflow.com': ['jp', 'kr'],
    'ai@ecoflow.com': ['jp'],
    'miki.yataka@ecoflow.com': ['jp'],
    'tomoya.kawasaki@ecoflow.com': ['jp'],
} as any;

export const IS_EDIT_MODE = (lang: any, isMobile = false, isTest = false) => {
    // return true
    const userEmail = loginUser()?.user?.email;
    const auth = editModeWhiteList[userEmail];
    // if (isMobile || !isTest) {
    if (!isTest) {
        return false;
    }

    // return typeof window !== 'undefined'
    //     ? (/website-lang-edit\.ecoflow\.com$/.test(window.location.host) &&
    //           ((typeof auth === 'object' && auth.includes(lang)) || (typeof auth === 'string' && auth === ''))) ||
    //           ['3005', '3033', '3035'].includes(window.location.port)
    //     : false

    return (typeof auth === 'object' && auth.includes(lang)) || (typeof auth === 'string' && auth === '');
};

export const localeMapWensi = {
    en: 'en_001',
    us: 'en_US',
    uk: 'en_GB',
    ca: 'en_CA',
    au: 'en_AU',
    jp: 'ja_JP',
    de: 'de_DE',
    fr: 'fr_FR',
    eu: 'en_150',
    kr: 'ko_KR',
    cn: 'zh_CN',
    hk: 'zh_HK',
    it: 'it_IT',
    es: 'es_ES',
    za: 'en_ZA',
    ru: 'ru_RU',
    sa: 'ar_SA',
    cis: 'ru_RU',
};

// 专属重置密码使用 eu站后端使用en_EU, 后端黄龙不改，只能前端临时改用这个
export const localeMapWensiSpecial: any = {
    en: 'en_001',
    us: 'en_US',
    uk: 'en_UK',
    ca: 'en_CA',
    au: 'en_AU',
    jp: 'ja_JP',
    de: 'de_DE',
    fr: 'fr_FR',
    eu: 'en_EU',
    kr: 'ko_KR',
    cn: 'zh_CN',
    hk: 'zh_HK',
    it: 'it_IT',
    es: 'es_ES',
    za: 'en_ZA',
    ru: 'ru_RU',
    sa: 'ar_SA',
    cis: 'ru_RU',
};

export const iconWensi = [
    {
        insertContent: '&#x200B;',
        clickType: 'zwsp',
    },
    {
        insertContent: '&#x2060;',
        clickType: 'zwnbsp',
    },
    {
        insertContent: '&#x0020;',
        clickType: 'sp',
    },
    {
        insertContent: '&#x00A0;',
        clickType: 'nbsp',
    },
    {
        insertContent: '&#x0009;',
        clickType: 'tab',
    },
    {
        insertContent: '&#x000A;',
        clickType: 'lf',
    },
    // https://docs.microsoft.com/en-us/visualstudio/ide/encodings-and-line-breaks?view=vs-2022
    // 网页中只有 lf
    // {
    //     insertContent: '&#x000A;',
    //     clickType: 'cr',
    // },
    // {
    //     insertContent: '&#x000A;',
    //     clickType: 'crlf',
    // },
    {
        insertContent: '&#x00AD;',
        clickType: 'shy',
    },
    {
        insertContent: '&#x200E;',
        clickType: 'lrm',
    },
    {
        insertContent: '&#x200F;',
        clickType: 'rlm',
    },
    {
        insertContent: '©',
        clickType: 'copyright',
        text: 'Copyright',
    },
    {
        insertContent: '®',
        clickType: 'registration',
        text: 'Registration',
    },
    {
        insertContent: '™',
        clickType: 'trademark',
        text: 'Trademark',
    },
    {
        insertContent: '—',
        clickType: 'emDash',
        text: 'Em Dash',
    },
    {
        insertContent: '–',
        clickType: 'enDash',
        text: 'En Dash',
    },
    {
        insertContent: '€',
        clickType: 'euro',
        text: 'Euro',
    },
    {
        insertContent: '„“',
        clickType: 'germanQuotes',
        text: 'German Quotes',
    },
    {
        insertContent: '“”',
        clickType: 'englishStraightQuotes',
        text: 'English Straight Quotes',
    },
    {
        insertContent: '«  »',
        clickType: 'frenchQuotes',
        text: 'French Quotes',
    },
];
// END of 第三方翻译系统：文思海辉
