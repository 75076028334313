import { baseUrl } from './util';
import request from '@/utils/request';

// 国家区号
export const areaCountriesList = (data: any, locale: string) => {
    return request.get(`${baseUrl(locale)}/address/countriesList`, {
        params: data,
    });
};

// 查询订阅信息
export const searchSubInfo = (data: any, locale: string) => {
    return request.get(`${baseUrl(locale)}/subscribe/searchSubInfo`, {
        params: data,
    });
};

// 创建sms订阅
export const createSubscribeSms = (data: any, locale: string) => {
    return request.post(`${baseUrl(locale)}/subscribe/createSubscribeSms`, {
        data,
    });
};
