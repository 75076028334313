import { loginUrl } from './util';
import request from '@/utils/request';
import { setLocaleQuery } from '@/utils/setLocaleQuery';

/**
 * 登陆认证
 */
export const toLogin = (params: any, locale?: string) => {
    return request(`${loginUrl(locale)}/auth/login${setLocaleQuery(locale)}`, {
        method: 'post',
        data: params,
        needToken: false,
    });
};

/**
 * 账号注册
 */
export const toRegister = (params: any, terminalId: string, locale?: string) => {
    return request(`${loginUrl(locale)}/auth/register${setLocaleQuery(locale)}`, {
        method: 'post',
        data: params,
        // headers: {
        //     terminalId,
        // },
        needToken: true,
    });
};

/**
 * 获取用户信息
 */
export const getUserInfo = (locale?: string) => {
    return request.get(`${loginUrl(locale)}/usercenter/inuc/getUserInfo${setLocaleQuery(locale)}`, {
        needToken: true,
    });
};

/**
 * 设置用户信息
 */
export const updateUserInfo = (data: any, locale?: string) => {
    return request(`${loginUrl(locale)}/usercenter/inuc/setInfo${setLocaleQuery(locale)}`, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data,
        needToken: true,
    });
};

/**
 * 获取用户地址信息
 */
export const getUserAddrList = (locale?: string) => {
    return request.get(`${loginUrl(locale)}/usercenter/userAddress/queryUserAddress${setLocaleQuery(locale)}`, {
        needToken: true,
    });
};

/**
 * 获取用户地址信息 new
 */
export const getUserAddrListNew = (locale?: string) => {
    return request.get(`${loginUrl(locale)}/usercenter/userAddressNew/queryUserAddress${setLocaleQuery(locale)}`, {
        needToken: true,
    });
};

/**
 * 获取单个用户地址信息 new
 */
export const getUserAddrNew = (params: { ids: string }, locale?: string) => {
    return request.get(`${loginUrl(locale)}/usercenter/userAddressNew/getAddress${setLocaleQuery(locale)}`, {
        needToken: true,
        params,
    });
};
/**
 * 更新用户地址
 */
export const updateAddressInfo = (data: any, method: string, locale?: string) => {
    return request(`${loginUrl(locale)}/usercenter/userAddress${setLocaleQuery(locale)}`, {
        method,
        data,
        needToken: true,
    });
};

/**
 * 更新用户地址
 */
export const updateAddressInfoNew = (data: any, method: string, locale?: string) => {
    return request(`${loginUrl(locale)}/usercenter/userAddressNew${setLocaleQuery(locale)}`, {
        method,
        data,
        needToken: true,
    });
};

/**
 *  删除用户地址
 */
export const deleteAddr = (params: any, locale?: string) => {
    return request(`${loginUrl(locale)}/usercenter/userAddress${setLocaleQuery(locale)}`, {
        method: 'delete',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        params,
        needToken: true,
    });
};

/**
 *  删除用户地址 New
 */
export const deleteAddrNew = (params: any, locale?: string) => {
    return request(`${loginUrl(locale)}/usercenter/userAddressNew${setLocaleQuery(locale)}/${params.id}`, {
        method: 'delete',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        needToken: true,
    });
};

/**
 * 获取验证码（发邮件验证）
 */
export const getVerifyCode = (data: any, locale?: string) => {
    return request(`${loginUrl(locale)}/auth/verificationCode/send${setLocaleQuery(locale)}`, {
        method: 'post',
        data,
        needToken: true,
    });
};

/**
 * 验证账号
 */
export const validateAccount = (data: any, locale?: string) => {
    return request.post(`${loginUrl(locale)}/auth/verificationCode/verify${setLocaleQuery(locale)}`, {
        data,
    });
};

/**
 * 重置密码
 */
export const resetPassword = (data: any, locale?: string) => {
    return request.post(`${loginUrl(locale)}/auth/resetPassword${setLocaleQuery(locale)}`, {
        data,
    });
};

/**
 * 检测邮箱是否存在
 */
export const checkAccountIsExist = (params: any, locale?: string) => {
    return request(`${loginUrl(locale)}/usercenter/inuc/checkEmail${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
        needToken: true,
    });
};

/**
 * 邮件发送账号激活认证消息
 */
export const sendActivationEmail = (data: any, locale?: string) => {
    return request.post(`${loginUrl(locale)}/usercenter/inuc/account/activation/email?email=${data.email}&scene=${data.scene}&locale=${locale}`, {
        needToken: true,
        headers: {
            lang: data.lang || '',
        },
    });
};

/**
 * 验证码校验(不会设置失效)
 */
export const verificationCode = (data: any, locale?: string) => {
    return request.post(`${loginUrl(locale)}/auth/verificationCode/verify${setLocaleQuery(locale)}`, {
        data,
        needToken: true,
    });
};

/**
 * 检测邮箱是否存在
 */
export const deleteAccountComfirmEmail = (params: any, locale?: string) => {
    return request(`${loginUrl(locale)}/usercenter/inuc/deleteAccountComfirmEmail${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
        needToken: true,
    });
};

/**
 * 注销账号
 */
export const deleteAccount = (data?: any, locale?: string) => {
    return request.post(`${loginUrl(locale)}/usercenter/inuc/deleteAccount${setLocaleQuery(locale)}`, {
        data,
        needToken: true,
    });
};

/**
 * 注销账号(新版)
 */
export const deleteNewAccount = (data?: any, locale?: string) => {
    return request.post(`${loginUrl(locale)}/auth/accountCancellationForWeb${setLocaleQuery(locale)}`, {
        data,
        needToken: true,
    });
};

/**
 * 个人积分跳转ecoflow.com站点登录
 */
export const authSignIn = (data: any, locale?: string) => {
    return request.post(`${loginUrl(locale)}/auth/signIn${setLocaleQuery(locale)}`, {
        data,
        needToken: true,
    });
};

/**
 * 是否需要触发人机校验
 */
export const checkHumanMachine = (params: any, locale?: string) => {
    return request(`${loginUrl(locale)}/auth/humanMachineCheck/switch${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
    });
};

/**
 * 获取用户付款记录
 */
export const getPaymentList = (locale?: string) => {
    return request.get(`${loginUrl(locale)}/order/acceptPayment/queryList`, {
        needToken: true,
    });
};

/**
 * 判断用户是否是家储用户
 */
export const getHomeBatteryInfo = (locale?: string) => {
    return request.post(`${loginUrl(locale)}/enterprise/usercenter/checkEnterprise`, {
        needToken: true,
    });
};

/**
 * 账号注册(New)
 */
export const toRegisterNew = (params: any, terminalId: string, locale?: string) => {
    return request(`${loginUrl(locale)}/auth/registerLogin${setLocaleQuery(locale)}`, {
        method: 'post',
        data: params,
        // headers: {
        //     terminalId,
        // },
        needToken: true,
    });
};

/**
 * 取消注销账号
 */
export const cancelDeleteAccount = (locale?: string) => {
    return request.post(`${loginUrl(locale)}/auth/resumeAccount`, {
        needToken: true,
    });
};
