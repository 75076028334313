'use strict';

var _warn = require("./utils/warn");
var _interopRequireDefault = function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
};
var _warn2 = _interopRequireDefault(_warn);
var Snippets = {
  gtm: {
    tags: function tags(_ref) {
      var id = _ref.id,
        events = _ref.events,
        dataLayer = _ref.dataLayer,
        dataLayerName = _ref.dataLayerName,
        preview = _ref.preview,
        auth = _ref.auth;
      if (!id) (0, _warn2.default)('GTM ID is required');
      var iframe = "\n    <iframe src=\"https://www.googletagmanager.com/ns.html?id=".concat(id, "\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" id=\"tag-manager\"></iframe>");
      var script = "\n    \n            (function(w,d,s,l,i){w[l]=w[l]||[];\n    \n                w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', });\n    \n                var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';\n    \n                j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;\n    \n                f.parentNode.insertBefore(j,f);\n    \n            })(window,document,'script','".concat(dataLayerName, "','").concat(id, "');");
      var dataLayerVar = this.dataLayer(dataLayer, dataLayerName);
      return {
        iframe: iframe,
        script: script,
        dataLayerVar: dataLayerVar
      };
    },
    dataLayer: function dataLayer(_dataLayer, dataLayerName) {
      return "\n    window.".concat(dataLayerName, "=window.").concat(dataLayerName, " || [];\n    window.").concat(dataLayerName, ".push(").concat(JSON.stringify(_dataLayer), ")");
    }
  },
  fb: {
    tags: function tags(_ref) {
      var id = _ref.id;
      if (!id) (0, _warn2.default)('Facebook ID is required');
      var iframe = "<img height=\"1\" width=\"1\" style=\"display:none\" src=\"https://www.facebook.com/tr?id=".concat(id, "&ev=PageView&noscript=1\" />");
      var script = "\n    !function(f,b,e,v,n,t,s)\n    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n    n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n    n.queue=[];t=b.createElement(e);t.async=!0;\n    t.src=v;s=b.getElementsByTagName(e)[0];\n    s.parentNode.insertBefore(t,s)}(window, document,'script',\n    'https://connect.facebook.net/en_US/fbevents.js');\n    fbq('init', '".concat(id, "');\n    fbq('track', 'PageView');");
      return {
        iframe: iframe,
        script: script
      };
    }
  },
  baidu: {
    tags: function tags(_ref) {
      var id = _ref.id;
      if (!id) (0, _warn2.default)('Baidu ID is required');
      var script = "\n    var _hmt = _hmt || []; (function() { var hm = document.createElement(\"script\"); hm.src = \"https://hm.baidu.com/hm.js?".concat(id, "\"; var s = document.getElementsByTagName(\"script\")[0]; s.parentNode.insertBefore(hm, s); })();");
      return {
        script: script
      };
    }
  },
  ptengine: {
    tags: function tags(locale, pathname) {
      // ptengine那边测试人员说这个虽然在header植入了ptengine，但是并没有执行
      // https://project.feishu.cn/mz820/story/detail/19921190
      var SPECIAL_PATHS = ['/us/ecocredits-monthly-madness', '/us', '/us/recreational-activities'];
      var src = SPECIAL_PATHS.includes(pathname) ? 'https://js.ptengine.jp/1fotbhvl.js' : 'https://js.ptengine.com/3dme2grz.js';
      var script = "\n    var _hmt = _hmt || []; (function() { var hm = document.createElement(\"script\"); hm.src = \"".concat(src, "\"; hm.type = \"text/plain\"; hm.class=\"optanon-category-C0002\"; var s = document.getElementsByTagName(\"script\")[0]; s.parentNode.insertBefore(hm, s); })();");
      return {
        script: script
      };
    }
  },
  floodlight: {
    tags: function tags(_ref) {
      var id = _ref.id;
      if (!id) (0, _warn2.default)('floodlight ID is required');
      var script = "\n    var _hmt = _hmt || []; (function() { var hm = document.createElement(\"script\"); hm.src = \"https://www.googletagmanager.com/gtag/js?id=".concat(id, "\"; var s = document.getElementsByTagName(\"script\")[0]; s.parentNode.insertBefore(hm, s); })();");
      return {
        script: script
      };
    }
  },
  emarsys: {
    tags: function tags(_ref) {
      var merchantId = _ref.merchantId;
      if (!merchantId) (0, _warn2.default)('Emarsys ID is required');
      var script = "\n            var ScarabQueue = ScarabQueue || [];\n            (function (id) {\n                if (document.getElementById(id)) return;\n                var js = document.createElement('script');\n                js.id = id;\n                js.src = '//cdn.scarabresearch.com/js/".concat(merchantId, "/scarab-v2.js';\n                var fs = document.getElementsByTagName('script')[0];\n                fs.parentNode.insertBefore(js, fs);\n            })('scarab-js-api');\n            ");
      return {
        script: script
      };
    }
  },
  line: {
    tags: function tags() {
      var script = "(function(g,d,o){\n                g._ltq=g._ltq||[];g._lt=g._lt||function(){g._ltq.push(arguments)};\n                var h=location.protocol==='https:'?'https://d.line-scdn.net':'http://d.line-cdn.net';\n                var s=d.createElement('script');s.async=1;\n                s.src=o||h+'/n/line_tag/public/release/v1/lt.js';\n                var t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);\n                  })(window, document)";
      return {
        script: script
      };
    }
  }
};
module.exports = Snippets;