import { cacheOrCatch } from './cacheOrCatch';
import { baseUrl, loginUrl } from './util';
import request, { createToken } from '@/utils/request';
import { setLocaleQuery } from '@/utils/setLocaleQuery';
import { getCountryRegion } from '@/utils/countryRegion';

const ENV = process.env.NEXT_PUBLIC_MODY;

/**
 * 获取搜索结果
 * @param keyword
 */
export const getSearchRes = (params: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/homeSearch${setLocaleQuery(locale)}`, {
        params,
    });
};

/**
 * 获取首页布局
 */
export const getHomePageLayout = (locale?: string) => {
    return request.get(`${baseUrl(locale)}/pageLayout/index${setLocaleQuery(locale)}`);
};

export const subscribeFn = (email: string, source: string, locale?: string) => {
    return request(`${baseUrl(locale)}/subscribe${setLocaleQuery(locale)}`, {
        method: 'post',
        data: {
            email,
            source,
        },
    });
};

/**
 * 下载管理--获取下载目录树 => 2022-4-24 切换cms
 */
export const getDownloadCatagoryTree = (locale: string, type?: string) => {
    if (type) {
        return request.get(`${baseUrl(locale)}/cms/downloadInfoForWeb/binds/downloadCatagoryTree/v2${setLocaleQuery(locale)}&classify=${type}`);
    }
    return request.get(`${baseUrl(locale)}/cms/downloadInfoForWeb/binds/downloadCatagoryTree/v2${setLocaleQuery(locale)}`);
};

/**
 * 下载管理--下载信息分页查询 => 2022-4-24 切换cms
 */
export const getDownloadInfo = (params: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/cms/downloadInfoForWeb/downloadInfo/v2${setLocaleQuery(locale)}`, {
        params: { page: 1, ...params },
    });
};

/**
 * 获取iso App版本号
 */
export const getIosAppVersion = (locale?: string) => {
    return request.get('https://itunes.apple.com/us/lookup?bundleId=com.ef.EcoFlow');
};

/**
 * 获取存在绑定的FAQ目录树 => 2022-4-24 切换cms
 */
export const getFaqCatagoryTree = (locale?: string) => {
    const requestQuery = request.get(`${baseUrl(locale)}/faqCatagoryTree/web/bind/v2/faqCatagoryTree${setLocaleQuery(locale)}`);
    return cacheOrCatch({
        requestQuery,
        cachePath: `faq/catagoryTree/${locale}.json`,
    });
};

/**
 * FAQ信息分页查询 => 2022-4-24 切换cms
 */
export const getFaqs = (params: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/faqInfoForWeb/v2/faqInfo${setLocaleQuery(locale)}`, {
        params,
        headers: {
            Authorization: '',
        },
    });
};

/**
 * FAQ信息分页查询（生产环境）
 */
export const getFaqsInProd = (params: any, locale?: string) => {
    return request.get(`${loginUrl(locale)}/cms/crowdFunding/faqInfo${setLocaleQuery(locale)}`, {
        params,
        withoutToken: true,
    });
};

/**
 * Specs信息查询
 */
export const queryIntegrateSku = (params: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/spu/cms/queryIntegrateSku${setLocaleQuery(locale)}`, {
        params,
        withoutToken: true,
    });
};

/**
 * 规格参数分页
 */
export const getQuery = (locale?: string) => {
    return request.get(`${baseUrl(locale)}/specParam/query${setLocaleQuery(locale)}`, {});
};
/**
 * 获取获取国家列表
 */
export const getCountriesList = async (params: any, locale?: string) => {
    let url = `${baseUrl(locale)}/address/countriesList${setLocaleQuery(locale)}`;
    if (params) {
        const paramsArray = [] as any[];
        Object.keys(params).forEach((key) => paramsArray.push(`${key}=${params[key]}`));
        if (url.search(/\?/) === -1) {
            url += `?${paramsArray.join('&')}`;
        } else {
            url += `&${paramsArray.join('&')}`;
        }
    }
    const result = await fetch(url);
    const json = await result.json();
    return json;
};

/**
 * 获取获取国家列表2
 */
export const getNewCountriesList = async (params: any, locale?: string) => {
    let url = `${baseUrl(locale)}/address/page${setLocaleQuery(locale)}`;
    if (params) {
        const paramsArray = [] as any[];
        Object.keys(params).forEach((key) => paramsArray.push(`${key}=${params[key]}`));
        if (url.search(/\?/) === -1) {
            url += `?${paramsArray.join('&')}`;
        } else {
            url += `&${paramsArray.join('&')}`;
        }
    }
    const result = await fetch(url);
    const json = await result.json();
    return json;
};

/**
 * 获取获取国家列表3
 */
export const getInterCountriesList = async (parentValue: string, locale?: string) => {
    return request.get(`${loginUrl(locale)}/shopify/web/shopifyAddressCountries/list/${parentValue}${setLocaleQuery(locale)}`, {
        needToken: true,
    });
};

/**
 * 获取州（省）列表
 */
export const getStatesList = (params: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/address/statesList${setLocaleQuery(locale)}`, {
        params,
        needToken: true,
    });
};

/**
 * 获取城市列表
 */
export const getCitiesList = (params: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/address/citiesList${setLocaleQuery(locale)}`, {
        params,
        needToken: true,
    });
};

/**
 * 获取区号列表
 */
export const getCodeList = (params: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/code${setLocaleQuery(locale)}`, {
        params,
        needToken: true,
    });
};

/**
 *  通过非配件品类ID查询配件Spu列表
 */
export const byNotAccessoryCatagoryId = (params: any, locale?: string) => {
    return request(`${baseUrl(locale)}/spu/cms/queryAccessories/byNotAccessoryCatagoryId${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
    });
};

/**
 * 获取sku列表
 * @param params
 */
export const getSkuList = (locale?: string) => {
    return request(`${baseUrl(locale)}/productRegistration/queryProductNameList${setLocaleQuery(locale)}`, {
        method: 'get',
    });
};

/**
 * 创建用户订阅记录
 */
export const createSubscribe = (data: any, locale?: string) => {
    return request.post(`${baseUrl(locale)}/subscribe${setLocaleQuery(locale)}`, {
        data,
        needToken: true,
    });
};

/**
 * 创建供应商注册
 */
export const createDealer = (data: any, locale?: string) => {
    return request.post(`${baseUrl(locale)}/dealerRegistration${setLocaleQuery(locale)}`, {
        data,
    });
};

export const updateDealer = (data: any, locale?: string) => {
    return request.put(`${baseUrl(locale)}/dealerRegistration${setLocaleQuery(locale)}`, {
        data,
    });
};

/**
 * 根据邮箱查询供应商注册信息
 */
export const getDealer = (params: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/dealerRegistration${setLocaleQuery(locale)}`, {
        method: 'get',
        params,
        needToken: true,
    });
};

/**
 *
 * 收集个人需求信息
 */
export const collectInfoSubmit = (data: any, locale?: string) => {
    return request.post(`${baseUrl(locale)}/crowdFundingUserInfo${setLocaleQuery(locale)}`, {
        data,
    });
};

/**
 * 对阿里云文件进行缓存预热
 */
export const refreshDcdn = (data: any, locale?: string) => {
    return request.post(`${baseUrl(locale)}/file/refreshDcdn${setLocaleQuery(locale)}`, {
        data,
    });
};

/**
 *  创建点击事件记录
 */
export const pageClick = (data: any, locale?: string) => {
    return request.post(`${loginUrl(locale)}/general/web/page/click${setLocaleQuery(locale)}`, {
        data,
    });
};

/**
 * 创建浏览事件记录
 */
export const pageView = (data: any, locale?: string) => {
    return request.post(`${loginUrl(locale)}/general/web/page/view${setLocaleQuery(locale)}`, {
        data,
    });
};

/**
 * 查询当前阶段信息
 */
export const getCurrStage = (data: any, locale?: string) => {
    return request.get(`${baseUrl(locale)}/crowdFunding/sku/stage${setLocaleQuery(locale)}`, {});
};

/*
 * 上传文件（Warranty页 Proof)
 */
export const uploadFile = (data: any, locale: '') => {
    return request.post(`${baseUrl(locale)}/file/upload`, {
        data,
        headers: {
            Authorization: `Bearer ${createToken()}`,
        },
    });
};

// Next 13 获取所有品类
export const getCategoryList = (locale: any) => {
    return request.get(`${baseUrl(locale)}/catagoryConformity${setLocaleQuery(locale)}`, {
        needToken: true,
    });
};

/**
 * 创建Gitex 2023
 */
export const createGitex2023 = (data: any, locale?: string) => {
    return request.post(`${baseUrl(locale)}/gitexRegistration/save${setLocaleQuery(locale)}`, {
        data,
    });
};

/**
 * 创建Gitex 2023
 * For B端 企业官网 => 数据进入企业官网后台(需用到企业官网的x-appid)
 */
export const createGitex2023ToB = (data: any, locale: string) => {
    const isEU = getCountryRegion(locale || 'eu') === 'eu';
    const HOST_STS = isEU ? process.env.NEXT_PUBLIC_STS_HOST_EU : process.env.NEXT_PUBLIC_STS_HOST;
    const appidMap: any = {
        us: 101,
        de: 102,
        eu: 103,
        sa: 104,
        uk: 105,
        fr: 106,
        es: 107,
        it: 108,
    };
    return request(`${HOST_STS}/enterprise/exhibitionWithEvent/eventRegister${setLocaleQuery(locale)}`, {
        method: 'post',
        data,
        // needToken: true,
        appId: appidMap[locale] || appidMap.us,
    });
};

/**
 * 绑定第三方账号
 */
export const bindOtherAccount = (data: any, locale?: string) => {
    return request.post(`${baseUrl(locale)}/auth/oauth/bind${setLocaleQuery(locale)}`, {
        data,
    });
};

/**
 * 查询第三方账号信息
 */
export const getOtherAccount = (params: any, locale?: string) => {
    return request.get(`${loginUrl(locale)}/usercenter/open/inuc/getOauthUserInfoByUserId`, {
        params,
        needToken: true,
    });
};

/**
 * 绑定第三方账号信息
 * @params userId、returnTo、shopifyDomain、returnToNew
 */
export const bindLine = (params: any, locale?: string) => {
    return request.get(`${loginUrl(locale)}/auth/oauth/bind/lineextend`, {
        params,
        needToken: true,
    });
};

/**
 * 解绑第三方账号信息
 */
export const unbindLine = (data: any, locale?: string) => {
    return request.post(`${loginUrl(locale)}/auth/oauth/unbind`, {
        data,
        needToken: true,
    });
};
// https://api-e.ecoflow.com/website/subscribe/shopify
export const subscribeShopify = (data: any, locale: string) => {
    return request(`${baseUrl(locale)}/subscribe/shopify${setLocaleQuery(locale)}`, {
        method: 'post',
        data,
    });
};

/**
 * 官网查询系统最新版本
 */
export const getUpsInfo = (locale?: string) => {
    let host = 'https://ecomatrix-cn.ecoflow.com';

    if (ENV === 'uat') {
        host = 'https://embed-api-beta.ecoflow.com';
        // host = 'http://61.141.202.83:30536';
    } else if (ENV === 'test') {
        host = 'https://api-dev.ecoflow.com';
    }
    return request.get(`${host}/powerManager/package/getOsVersion`, {});
};
