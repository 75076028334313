import { baseUrl } from './util';
import request from '@/utils/request';

export const submitDelta3Questionnaire = (data: any, locale: string) => {
    return request.post(`${baseUrl(locale)}/web/questionnaire/saveDelta3Questionnaire`, {
        data,
    });
};

export const getQuestionnaireByScene = (data: any, locale: string) => {
    return request.get(`${baseUrl(locale)}/web/questionnaire/listByScene`, {
        params: data,
    });
};
