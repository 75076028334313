'use client';

import { useState, useEffect } from 'react';

export default function DefaultStyle({ id, data = {} }: any) {
    const { closeColor, winBgColor, labelColor, subTitleColor, titleColor, descColor, specialTextColor, buyColor, buyBgColor, moreColor, moreBgColor } = data;
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);
    if (!isClient) {
        return null;
    }
    return (
        <>
            <style>
                {!!closeColor &&
                    `
                    .glo-editor-modal-${id} .glo-editor-modal-close {
                        color: ${closeColor};
                    }
                `}
                {!!winBgColor &&
                    `
                    .glo-editor-modal-${id} .glo-editor-modal-body {
                        background-color: ${winBgColor};
                    }
                `}
                {!!labelColor &&
                    `
                    .glo-editor-modal-${id} .sales-messages .sales-label {
                        color: ${labelColor};
                    }
                `}
                {!!subTitleColor &&
                    `
                    .glo-editor-modal-${id} .sales-messages .sales-subTitle {
                        color: ${subTitleColor};
                    }
                `}
                {!!titleColor &&
                    `
                    .glo-editor-modal-${id} .sales-messages .sales-title {
                        color: ${titleColor};
                    }
                `}
                {!!descColor &&
                    `
                    .glo-editor-modal-${id} .sales-messages .sales-desc {
                        color: ${descColor};
                    }
                `}
                {!!specialTextColor &&
                    `
                    .glo-editor-modal-${id} .sales-messages .sales-specialText {
                        color: ${specialTextColor};
                    }
                `}
                {!!buyColor &&
                    `
                    .glo-editor-modal-${id} .sales-messages .sales-btns .sales-btn-buy {
                        color: ${buyColor};
                    }
                `}
                {!!buyBgColor &&
                    `
                    .glo-editor-modal-${id} .sales-messages .sales-btns .sales-btn-buy {
                        background-color: ${buyBgColor};
                    }
                `}
                {!!moreColor &&
                    `
                    .glo-editor-modal-${id} .sales-messages .sales-btns .sales-btn-more {
                        color: ${moreColor};
                    }
                `}
                {!!moreBgColor &&
                    `
                    .glo-editor-modal-${id} .sales-messages .sales-btns .sales-btn-more {
                        border-color: ${moreBgColor};
                    }
                `}
            </style>
        </>
    );
}
