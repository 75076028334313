'use strict';

var _Snippets = require("./snippets");
var _interopRequireDefault = function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
};
var _Snippets2 = _interopRequireDefault(_Snippets);
var TagManager = {
  dataScript: function dataScript(dataLayer) {
    var script = document.createElement('script');
    script.innerHTML = dataLayer;
    return script;
  },
  gtm: function gtm(args) {
    var snippets = _Snippets2.default.gtm.tags(args);
    var createNoScript = function createNoScript() {
      var noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };
    var createScript = function createScript() {
      var script = document.createElement('script');
      script.innerHTML = snippets.script;
      return script;
    };
    var dataScript = this.dataScript(snippets.dataLayerVar);
    return {
      createNoScript: createNoScript,
      createScript: createScript,
      dataScript: dataScript
    };
  },
  initialize: function initialize(_ref) {
    var gtmId = _ref.gtmId,
      _ref$events = _ref.events,
      events = _ref$events === void 0 ? {} : _ref$events,
      _ref$dataLayer = _ref.dataLayer,
      dataLayer = _ref$dataLayer === void 0 ? undefined : _ref$dataLayer,
      _ref$dataLayerName = _ref.dataLayerName,
      dataLayerName = _ref$dataLayerName === void 0 ? 'dataLayer' : _ref$dataLayerName,
      _ref$auth = _ref.auth,
      auth = _ref$auth === void 0 ? '' : _ref$auth,
      _ref$preview = _ref.preview,
      preview = _ref$preview === void 0 ? '' : _ref$preview;
    var gtm = this.gtm({
      id: gtmId,
      events: events,
      dataLayer: dataLayer,
      dataLayerName: dataLayerName,
      auth: auth,
      preview: preview
    });
    if (dataLayer) document.head.appendChild(gtm.dataScript);
    document.head.insertBefore(gtm.createScript(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.createNoScript(), document.body.childNodes[0]);
  },
  dataLayer: function dataLayer(_ref) {
    var dataLayer = _ref.dataLayer,
      _ref$dataLayerName2 = _ref.dataLayerName,
      dataLayerName = _ref$dataLayerName2 === void 0 ? 'dataLayer' : _ref$dataLayerName2;
    if (window[dataLayerName]) return window[dataLayerName].push(dataLayer);
    var snippets = _Snippets2.default.gtm.dataLayer(dataLayer, dataLayerName);
    var dataScript = this.dataScript(snippets);
    document.head.insertBefore(dataScript, document.head.childNodes[0]);
  }
};
module.exports = TagManager;