// 静态路由的产品详情页
export const readyStaticProductPage: any = {
    product_ps_delta_delta_2: 'delta-2-portable-power-station',
    'delta-2-portable-power-station': true,
    product_ps_delta_delta_pro: 'delta-pro-portable-power-station',
    'delta-pro-portable-power-station': true,
    product_ps_delta_delta_max: 'delta-max-portable-power-station',
    'delta-max-portable-power-station': true,
    product_ps_delta_delta_1300: 'delta-portable-power-station',
    'delta-portable-power-station': true,
    product_ps_delta_delta_mini: 'delta-mini-portable-power-station',
    'delta-mini-portable-power-station': true,

    product_ps_river_river: 'river-portable-power-station',
    'river-portable-power-station': true,
    product_ps_river_river_mini: 'river-mini-portable-power-station',
    'river-mini-portable-power-station': true,
    product_ps_river_river_max: 'river-max-portable-power-station',
    'river-max-portable-power-station': true,
    product_ps_river_river_pro_720: 'river-pro-portable-power-station',
    'river-pro-portable-power-station': true,
    product_ps_river_river_256: 'river-2-portable-power-station',
    'river-2-portable-power-station': true,

    product_shp: 'smart-home-panel',
    'smart-home-panel': true,
    product_ac_wave: 'wave-portable-air-conditioner',
    'wave-portable-air-conditioner': true,
    product_st_solar_tracker: 'solar-tracker',
    'solar-tracker': true,
    product_sg_dg100: 'smart-generator',
    'smart-generator': true,
    product_sg_dg200: 'dual-fuel-smart-generator',
    'dual-fuel-smart-generator': true,

    product_sp_220w_bi_solar_panel: '220w-bifacial-solar-panel',
    '220w-bifacial-solar-panel': true,
    product_sp_100w_flexible_solar_panel: '100w-flexible-solar-panel',
    '100w-flexible-solar-panel': true,
    product_sp_100w_rigid_solar_panel: '100w-rigid-solar-panel',
    '100w-rigid-solar-panel': true,
    product_sp_110w_solar_panel: '110w-solar-panel',
    '110w-solar-panel': true,

    product_sp_400w_rigid_solar_panel: '400w-rigid-solar-panel',
    '400w-rigid-solar-panel': true,
    product_sp_400w_solar_panel: '400w-solar-panel',
    '400w-solar-panel': true,

    product_sp_60w_solar_panel: '60w-solar-panel',
    '60w-solar-panel': true,
    product_sp_160w_solar_panel: '160w-solar-panel',
    '160w-solar-panel': true,

    'dual-fuel-generator': true,
    '220w-solar-panel': true,
};
