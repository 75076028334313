'use client';

export const useStore = () => {
    const cacheKey = 'globalState';
    const getStore = () => {
        if (typeof window === 'undefined') return {};
        return JSON.parse(localStorage.getItem(cacheKey) || '{}');
    };
    const setStore = (udpates: any) => {
        const storeUpdated = { ...getStore(), ...udpates };
        updateCache(storeUpdated);
    };
    const updateCache = (newStore: any) => {
        if (!newStore || typeof window === 'undefined') return;
        return localStorage.setItem(cacheKey, JSON.stringify(newStore));
    };
    return {
        getStore,
        setStore,
    };
};