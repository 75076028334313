/* eslint-disable @typescript-eslint/no-var-requires */
import { ReactNode } from 'react';
import { NextIntlProvider, IntlErrorCode } from 'next-intl';
import { useRouter } from 'next/router';
import merge from 'lodash/merge';
//import { I18nEditProvider } from './I18nEditProvider';
import { mergeLocales } from '.';

const IS_BUILD = process.env.NEXT_MODE === 'build';
const IS_EDIT = !IS_BUILD && process.env.NEXT_PUBLIC_LANG_EDIT === 'on'; //是否开启文案编辑总开关-1,所有涉及的操作都要基于它去执行

//const EditBox = IS_EDIT ? I18nEditProvider : (props: any) => props.children({});
const EditBox = IS_EDIT ? null : (props: any) => props.children({});

export interface I18nProviderProps {
    globalLangAll: Record<string, unknown>;
    children: (res: { componentWords: Record<string, unknown> }) => ReactNode;
    isMobile: boolean;
    isLangEditTest: boolean;
    isEditPreview: boolean;
}

export function I18nProvider({ children, globalLangAll, isMobile, isLangEditTest, isEditPreview }: I18nProviderProps) {
    const router = useRouter();
    const { pathname } = router;

    // 获取语言路径,只用于componentLang(非NextIntl,即collections/smart等页面)
    const getLocalesMapKey = (pathname: string) => {
        // 多语言
        const pathToLocale = ['/', '/mobile'].includes(pathname) ? '/index' : pathname?.replace(/(\[|\]|\.\.\.)/g, '');
        const langKey = pathToLocale?.replace(/[\W_]+/g, '');
        return langKey;
    };

    function langOnError(error: any) {
        if (error.code === IntlErrorCode.MISSING_MESSAGE) {
            return '';
        } else {
            return '';
        }
    }

    const componentLangPathKey = getLocalesMapKey(pathname);
    // 合并后的词条
    const finallyWords = mergeLocales(globalLangAll);
    // 最终用于component的词条(即将废弃)
    const componentWords = finallyWords[componentLangPathKey];
    // 最终用于NextIntl的词条;
    const nextIntalOptionMessages = finallyWords;

    // NextIntl配置
    const nextIntalOption = {
        messages: nextIntalOptionMessages,
        onError: langOnError,
        getMessageFallback: () => {
            return '';
        },
        formats: {
            dateTime: {
                short: {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                },
            },
        },
    };

    // console.log('render NextIntlProvider');

    return (
        <EditBox
            isMobile={isMobile}
            isLangEditTest={isLangEditTest}
            isEditPreview={isEditPreview}
            globalLangAll={globalLangAll}
            componentLangPathKey={componentLangPathKey}
            isEdit={IS_EDIT}
        >
            {(options) => {
                const finalyOpt = { ...nextIntalOption, ...options.nextIntalOptionOverWrite };
                const finalyComponentWords = merge(componentWords, options.componentWords);
                // console.log('render NextIntlProvider2 ');
                return <NextIntlProvider {...(finalyOpt as any)}>{children({ componentWords: finalyComponentWords })}</NextIntlProvider>;
            }}
        </EditBox>
    );
}
