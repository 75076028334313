// https://github.com/garmeeh/next-seo
const additionalCommon = [
    {
        httpEquiv: 'x-ua-compatible',
        content: 'IE=edge; chrome=1',
    },
    {
        name: 'application-name',
        content: 'EcoFlow Website',
    },
    {
        name: 'viewport',
        content: 'width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0',
    },
];

const genKeywords = (words: string) => ({
    name: 'keywords',
    content: words,
});

const SeoConfig: any = {
    en: {
        title: 'EcoFlow',
        description:
            'Reliable power for emergency and adventures. EcoFlow aims to empower people and communities through clean, reliable power by dethroning the traditional gas generator with portable power station.',
        additionalMetaTags: [...additionalCommon, genKeywords('EcoFlow,Power')],
    },
    cn: {
        title: '正浩',
        description:
            '深圳市正浩创新科技股份有限公司(EcoFlow正浩)成立于2017年，总部位于深圳，在欧洲、日本、北美设有市场、销售及售后分部，是一家专注于储能产品的研发销售的国家高新企业。',
        additionalMetaTags: [...additionalCommon, genKeywords('户外电源,自驾游露营电源,应急备用电源,太阳能板,发电机')],
    },
};

export default SeoConfig;
