interface localItem {
    [key: string]: string | number;
}
interface localList {
    [key: string]: localItem[];
}

// 多语言--针对全局能用--后面2个必须大写
export const langeMap: any = {
    // en: 'en',
    zh: 'zh-CN',
    ja: 'ja-JP',
    kr: 'ko-KR',
    es: 'es-ES',
    de: 'de-DE',
    it: 'it-IT',
    nl: 'nl-NL',
    fr: 'fr-FR',
    eu: 'en-EU',
    uk: 'en-UK',
    us: 'en-US',
    ru: 'ru-RU',
    cis: 'ru-RU',
    tw: 'zh-TW',
    br: 'pt-BR',
};

// 多语言--针对全局能用--后面2个必须大写
export const appLocaleMap: any = {
    // en: 'en',
    cn: 'zh_CN',
    jp: 'ja_JP',
    de: 'de_DE',
    fr: 'fr_FR',
    eu: 'en_US',
    uk: 'en_US',
    us: 'en_US',
    ca: 'en_US',
    au: 'en_US',
    tw: 'zh_TW',
    br: 'pt_BR',
};

// 未添加的语言属性，默认使用 en 的
export const localsList: localList = {
    asiaPacific: [
        {
            local: 'au',
            alia: 'en',
            region: 'Australia',
            regionText: 'Australia',
            language: 'English',
            languageText: 'English', // chooseSite 页面展示使用
            appId: 3,
            acceptLanguage: 'en-AU,en;q=0.9',
        },
        {
            local: 'cn',
            alia: 'zh',
            region: 'China',
            regionText: '中国（大陆）',
            language: 'Chinese',
            languageText: '简体中文',
            appId: 10,
            acceptLanguage: 'zh-CN,zh;q=0.9', //暂定
        },
        {
            local: 'tw',
            alia: 'zh',
            region: 'China',
            regionText: '台灣地區',
            language: 'Chinese',
            languageText: '繁体中文',
            appId: 19,
            acceptLanguage: 'zh_TW,zh;q=0.9', //暂定
        },
        {
            local: 'jp',
            alia: 'ja',
            region: 'Japan',
            regionText: '日本',
            language: 'Japanese',
            languageText: '日本語',
            appId: 5,
            acceptLanguage: 'ja-JP,ja;q=0.9',
        },
        {
            local: 'kr',
            alia: 'kr',
            region: 'Korea',
            regionForWarranty: 'South Korea',
            regionText: '한국',
            language: 'Korean',
            languageText: '한국어',
            appId: 8,
            acceptLanguage: 'ko-KR,ko;q=0.9',
        },
        // {
        //     local: 'si',
        //     alia: 'si',
        //     region: 'Singapore',
        //     regionText: 'Singapore',
        //     language: 'English',
        //     languageText: 'English',
        // },
        {
            local: 'in',
            alia: 'in',
            region: 'India',
            regionText: 'India',
            language: 'English',
            languageText: 'English',
        },
        // {
        //     local: 'ka',
        //     alia: 'ka',
        //     region: 'Kazakhstan',
        //     regionText: 'Kazakhstan',
        //     language: 'English',
        //     languageText: 'English',
        // },
        {
            local: 'ne',
            alia: 'ne',
            region: 'Nepal',
            regionText: 'Nepal',
            language: 'English',
            languageText: 'English',
        },
    ],
    northAmerica: [
        {
            local: 'ca',
            alia: 'en',
            region: 'Canada',
            regionText: 'Canada',
            language: 'English',
            languageText: 'English',
            appId: 4,
            acceptLanguage: 'en-CA,en;q=0.9',
        },
        {
            local: 'us',
            alia: 'en',
            region: 'United States',
            regionText: 'United States',
            language: 'English',
            languageText: 'English',
            appId: 1,
            acceptLanguage: 'en-US,en;q=0.9',
        },
        {
            local: 'me',
            alia: 'me',
            region: 'Mexico',
            regionText: 'México',
            language: 'Mexican',
            languageText: 'Español',
        },
    ],
    europe: [
        {
            local: 'uk',
            alia: 'en',
            region: 'United Kingdom',
            regionText: 'United Kingdom',
            language: 'English',
            languageText: 'English',
            appId: 2,
            acceptLanguage: 'en-UK,en;q=0.9',
        },
        {
            local: 'fr',
            alia: 'fr',
            region: 'France',
            regionText: 'France',
            language: 'French',
            languageText: 'français',
            appId: 6,
            acceptLanguage: 'fr-FR,fr;q=0.9',
        },
        {
            local: 'de',
            alia: 'de',
            region: 'Germany',
            regionText: 'Deutschland',
            language: 'German',
            languageText: 'Deutsch',
            appId: 7,
            acceptLanguage: 'de-DE,de;q=0.9',
        },
        {
            local: 'eu',
            alia: 'eu',
            region: 'Europe',
            regionText: 'Europe',
            language: 'English',
            languageText: 'English',
            appId: 9,
            acceptLanguage: 'en-EU,en;q=0.9',
        },
        // {
        //     local: 'cis',
        //     alia: 'cis',
        //     region: 'Russia',
        //     regionText: 'CIS',
        //     language: 'Russian',
        //     languageText: 'Русский',
        //     appId: 12,
        //     acceptLanguage: 'ru-RU,ru;q=0.9',
        // },
        {
            local: 'es',
            alia: 'es',
            region: 'Spain',
            regionText: 'España',
            language: 'Spain',
            languageText: 'Español',
            appId: 11,
            acceptLanguage: 'es-ES,es;q=0.9',
        },
        {
            local: 'it',
            alia: 'it',
            region: 'Italy',
            regionText: 'Italia',
            language: 'Italian',
            languageText: 'Italian',
            appId: 13,
            acceptLanguage: 'it-IT,it;q=0.9',
        },
    ],
    europe_ad: [
        {
            local: 'pl',
            alia: 'pl',
            region: 'Polska',
            regionText: 'Polska',
            language: 'Polski',
            languageText: 'Polski',
            appId: 21,
            acceptLanguage: 'pl-PL,pl;q=0.9',
        },
        {
            local: 'se',
            alia: 'se',
            region: 'Sverige',
            regionText: 'Sverige',
            language: 'svenska',
            languageText: 'svenska',
            appId: 22,
            acceptLanguage: 'sv-SE,sv;q=0.9',
        },
        {
            local: 'tr',
            alia: 'tr',
            region: 'Türkiye',
            regionText: 'Türkiye',
            language: 'Turkish',
            languageText: 'Turkish',
        },
        {
            local: 'ch',
            alia: 'ch',
            region: 'Schweiz',
            regionText: 'Schweiz',
            language: 'Deutsch',
            languageText: 'Deutsch',
            appId: 23,
            acceptLanguage: 'de-CH,de;q=0.9',
        },
        {
            // 代理站点
            local: 'nl',
            alia: 'nl',
            region: 'Nederland',
            regionText: 'Nederland',
            language: 'Nederlands',
            languageText: 'Nederlands',
        },
        {
            local: 'nz',
            alia: 'nz',
            region: 'New Zealand',
            regionText: 'New Zealand',
            language: 'English',
            languageText: 'English',
        },
        {
            // 格鲁吉亚
            local: 'ge',
            alia: 'ge',
            region: 'Georgia',
            regionText: 'საქართველო',
            language: 'Georgian',
            languageText: 'ქართული',
            appId: 24,
            acceptLanguage: 'ka-GE,ka;q=0.9',
            allowSites: ['cis'],
        },
        {
            // 葡萄牙
            local: 'pt',
            alia: 'pt',
            region: 'Portugal',
            regionText: 'Portugal',
            language: 'Portuguese',
            languageText: 'Portuguese',
        },
        {
            // 爱尔兰
            local: 'ie',
            alia: 'ie',
            region: 'Ireland',
            regionText: 'Ireland',
            language: 'English',
            languageText: 'English',
        },
        {
            local: 'cz',
            alia: 'cz',
            region: 'Česká republika',
            regionText: 'Česká republika',
            language: 'český jazyk',
            languageText: 'český jazyk',
        },
        {
            // 爱尔兰
            local: 'hr',
            alia: 'hr',
            region: 'Republika Hrvatska',
            regionText: 'Republika Hrvatska',
            language: 'Hrvatski',
            languageText: 'Hrvatski',
        },
        {
            // 爱尔兰
            local: 'si',
            alia: 'si',
            region: 'Republika Slovenija',
            regionText: 'Republika Slovenija',
            language: 'Slovenščina',
            languageText: 'Slovenščina',
        },
    ],
    africa: [
        {
            local: 'za',
            alia: 'za',
            region: 'South Africa',
            regionText: 'South Africa',
            language: 'English',
            languageText: 'English',
            appId: 15,
            acceptLanguage: 'en-ZA,en;q=0.9',
        },
        {
            local: 'uae',
            alia: 'uae',
            region: 'UAE',
            regionText: 'UAE',
            language: 'English',
            languageText: 'English',
        },
        {
            local: 'an',
            alia: 'an',
            region: 'Angola',
            regionText: 'Angola',
            language: 'Angolan',
            languageText: 'Português',
        },
        {
            local: 'ta',
            alia: 'en',
            region: 'Tanzania',
            regionText: 'Tanzania',
            language: 'English',
            languageText: 'English',
        },
        {
            local: 'mo',
            alia: 'mo',
            region: 'Morocco',
            regionText: 'المغرب',
            language: 'Moroccan',
            languageText: 'français',
        },
        {
            local: 'sa-ar',
            alia: 'sa-ar',
            region: ' المملكة العربية السعودية',
            regionText: ' المملكة العربية السعودية',
            language: 'العربية',
            languageText: 'العربية',
            // appId: 16,
            // acceptLanguage: 'sa_AR,sa;q=0.9',
            // acceptLanguage: 'sa-AR,sa;q=0.9',
        },
        {
            local: 'sa',
            alia: 'sa',
            region: 'الشرق الأوسط',
            regionText: 'الشرق الأوسط',
            language: 'الشرق الأوسط',
            languageText: 'العربية',
            appId: 16,
            // acceptLanguage: 'sa_AR,sa;q=0.9',
            acceptLanguage: 'sa-AR,sa;q=0.9',
        },
    ],
    latin: [
        {
            local: 'br',
            alia: 'br',
            region: 'Brazil',
            regionText: 'Brasil',
            language: 'Portuguese',
            languageText: 'Português',
            appId: 18,
            acceptLanguage: 'pt-BR,br;q=0.9',
        },
    ],
    other: [
        {
            local: 'other',
            alia: 'en',
            region: 'Other Regions',
            regionText: 'Other Regions',
            language: 'English',
            languageText: 'English',
        },
    ],
};

function getLocalsMap() {
    const temp: {
        [key: string]: {};
    } = {
        en: {
            local: 'en',
            alia: 'en',
            region: 'United States',
            regionText: 'United States',
            language: 'English',
            languageText: 'English',
            appId: 1,
            acceptLanguage: 'en-US,en;q=0.9',
        },
    };
    for (const value of Object.values(localsList)) {
        if (value.length > 0) {
            value.forEach((item) => {
                temp[item.local] = item;
            });
        }
    }
    return temp;
}

export const localValueMap = getLocalsMap();

// 获取文字图片后缀
export const getImageLocal = function (locale: string, arr: string[]) {
    if (locale === 'sa') locale = 'uk'; // 阿拉伯采用uk规格图片
    if (locale === 'cis' && arr.includes(locale)) {
        return 'ru';
    }
    if (arr.indexOf(locale) !== -1) return locale;
    return 'en';
};

// 获取外观规格图片后缀
export const getImageSpec = function (locale: string, arr: string[]) {
    const localeMapping: Record<string, string> = {
        sa: 'uk',
        de: 'eu',
        fr: 'eu',
        it: 'eu',
        es: 'eu',
        cis: 'eu',
        kr: 'eu',
    };
    if (locale in localeMapping) {
        return localeMapping[locale];
    } else if (arr.includes(locale)) {
        return locale;
    }
    // 默认情况下返回 'en'
    return 'en';
};

// 只有该语言【有】
export const onlyIncludesLocal = function (locale: string, arr: string[]) {
    if (arr.indexOf(locale) !== -1) return true;
    return false;
};

// 只有该语言【没有】
export const noneIncludesLocal = function (locale: string, arr: string[]) {
    if (arr.indexOf(locale) !== -1) return false;
    return true;
};
