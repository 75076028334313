import { useEffect, useState } from 'react';
import CompA from './CompA';
const NEXT_PUBLIC_MODY = process.env.NEXT_PUBLIC_MODY;
export default function MicrosoftChat() {
    const [loadChat, setLoadChat] = useState(false);
    useEffect(() => {
        if (NEXT_PUBLIC_MODY === 'prd') {
            setTimeout(() => {
                setLoadChat(true);
            }, 20 * 1000);
        }
    }, []);
    return <>{loadChat && <CompA />}</>;
}
