'use strict';

var _Snippets = require("./snippets");
var _interopRequireDefault = function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
};
var _Snippets2 = _interopRequireDefault(_Snippets);
var BdManager = {
  baidu: function baidu(args) {
    var snippets = _Snippets2.default.baidu.tags(args);
    var createScript = function createScript() {
      var script = document.createElement('script');
      script.innerHTML = snippets.script;
      return script;
    };
    return {
      createScript: createScript
    };
  },
  initialize: function initialize(_ref) {
    var baiduId = _ref.baiduId;
    var baidu = this.baidu({
      id: baiduId
    });
    document.head.insertBefore(baidu.createScript(), document.head.childNodes[0]);
  }
};
module.exports = BdManager;