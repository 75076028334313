import getLocal from '@/app/utils/getLocal';
import { loginUser } from '@/utils/cookie';
import loginURL from '@/utils/loginURL';
import { getIsPadWidth } from '@elem/utils/devices';
import { css } from '@emotion/css';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const langAppId = {
    de: '0a1e5fb9-d869-4ef6-8aa0-983e24310671',
    en: '92b9f6ac-6623-44f2-994c-a8dd0a8c28f4',
    us: '44751321-678d-45e6-a575-3db6aedc9d77',
    au: 'a0758237-aa5d-4bd6-a9d7-6c334a9fbd06',
    za: 'a1c7111d-6cd7-475c-866f-dae46537d114',
};

const appIdMap = {
    de: langAppId.de,
    us: langAppId.us,
    ca: langAppId.en,
    au: langAppId.au,
    za: langAppId.za,
    uk: langAppId.en,
    eu: langAppId.en,
    // cn: 'ac4174bf-f6ef-4d35-93c2-6450c7956fae',
};

const regionCode = {
    us: 1,
    ca: 2,
    au: 3,
    za: 4,
    uk: 5,
    eu: 6,
    de: 7,
};

function MicrosoftChat() {
    const [isLoaded, setIsLoaded] = useState(false);
    const userInfo = loginUser();
    const { locale } = getLocal();
    const isMobile = getIsPadWidth(); //是否是移动端

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        function contextProvider() {
            const data = {
                SiteUrl: { value: window.location.origin + (loginURL() || ''), isDisplayable: true },
                SiteName: { value: (regionCode as any)[locale || ''] || '', isDisplayable: true },
                Origin: { value: 'Official website', isDisplayable: true },
            };
            //Here it is assumed that the corresponding work stream would have context variables with logical name of 'contextKey1', 'contextKey2', 'contextKey3'. If no context variable exists with a matching logical name, items are created assuming Type:string
            const res = Object.assign(
                data,
                userInfo && userInfo.user
                    ? {
                          Email: { value: userInfo.user.email, isDisplayable: true }, // boolean value
                      }
                    : {},
            );
            return res;
        }

        window.addEventListener('lcw:ready', function handleLivechatReadyEvent() {
            // Handle LiveChat Ready event
            // SDK methods are ready for use now
            // Setting custom context provider to be used with Chat for Dynamics 365
            // The custom context provided by custom context provider can be used for routing the chat to a particular queue
            (window as any).Microsoft.Omnichannel.LiveChatWidget.SDK.setContextProvider(contextProvider);
            // Starting a new chat
            //Microsoft.Omnichannel.LiveChatWidget.SDK.startChat();
            setIsLoaded(true);
        });
    }, []);

    const open = () => {
        (window as any).Microsoft.Omnichannel.LiveChatWidget.SDK.startChat();
    };

    const appId = (appIdMap as any)[locale || ''];

    return (
        <>
            <Helmet>
                {appId && (
                    <script
                        id="Microsoft_Omnichannel_LCWidget"
                        src="https://oc-cdn-public-eur.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js"
                        data-app-id={appId}
                        data-lcw-version="prod"
                        data-org-id="9ae05e11-c6d8-ed11-aed0-000d3a64d144"
                        data-org-url="https://unq9ae05e11c6d8ed11aed0000d3a64d-crm4.omnichannelengagementhub.com"
                        data-hide-chat-button="true"
                        defer
                    />
                )}
            </Helmet>
            {isLoaded && (
                <div
                    onClick={open}
                    className={
                        isMobile
                            ? css`
                                  color: #ffffff;
                                  position: fixed;
                                  border-width: 0px;
                                  z-index: 999;
                                  right: 20px;
                                  bottom: 78px;
                                  cursor: pointer;
                                  height: 40px;
                                  width: 40px;
                                  background: rgba(255, 255, 255, 1);
                                  box-shadow: 0px 4.4px 22px rgba(0, 0, 0, 0.08);
                                  border-radius: 50%;
                                  align-items: center;
                                  justify-content: center;
                                  display: flex;
                                  &:hover {
                                      background-color: #f0f0f0;
                                  }
                              `
                            : css`
                                  color: #ffffff;
                                  position: fixed;
                                  border-width: 0px;
                                  z-index: 999;
                                  right: 20px;
                                  bottom: 78px;
                                  cursor: pointer;
                                  height: 48px;
                                  width: 48px;
                                  background: rgba(255, 255, 255, 1);
                                  box-shadow: 0px 4.4px 22px rgba(0, 0, 0, 0.08);
                                  border-radius: 50%;
                                  align-items: center;
                                  justify-content: center;
                                  display: flex;
                                  &:hover {
                                      background-color: #f0f0f0;
                                  }
                              `
                    }
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3 5C3 4.44771 3.44772 4 4 4H16C16.5523 4 17 4.44772 17 5V13.5625C17 14.1148 16.5523 14.5625 16 14.5625H9.125L5.625 17V14.5625H4C3.44772 14.5625 3 14.1148 3 13.5625V5Z"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M16.625 11H20C20.5523 11 21 11.4477 21 12V17.3125C21 17.8648 20.5523 18.3125 20 18.3125H19.125V20L16.625 18.3125H12C11.4477 18.3125 11 17.8648 11 17.3125V15"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            )}
        </>
    );
}

export default dynamic(() => Promise.resolve(MicrosoftChat), {
    ssr: false,
});
