export const getLocalStorage = function (key: string) {
    if (typeof window !== 'undefined') {
        const value = localStorage.getItem(key);
        try {
            return value && !['undefined', 'null'].includes(value) ? JSON.parse(value) : null;
        } catch {
            return value;
        }
    }
    return null;
};

export const setLocalStorage = function (key: string, value: any) {
    if (typeof window !== 'undefined') {
        localStorage.setItem(key, JSON.stringify(value));
    }
};

export const removeLocalStorage = function (key: string) {
    if (typeof window !== 'undefined') {
        localStorage.removeItem(key);
    }
};
