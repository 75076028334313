'use strict';

var _Snippets = require("./snippets");
var _interopRequireDefault = function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
};
var _Snippets2 = _interopRequireDefault(_Snippets);
var FLManager = {
  floodlight: function floodlight(args) {
    var snippets = _Snippets2.default.baidu.tags(args);
    var createScript = function createScript() {
      var script = document.createElement('script');
      script.innerHTML = snippets.script;
      return script;
    };
    return {
      createScript: createScript
    };
  },
  initialize: function initialize(_ref) {
    var floodlightId = _ref.floodlightId;
    var floodlight = this.floodlight({
      id: floodlightId
    });
    document.head.insertBefore(floodlight.createScript(), document.head.childNodes[0]);
  }
};
module.exports = FLManager;