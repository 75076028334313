import { baseUrl } from './util';
import request from '@/utils/request';
import { setLocaleQuery } from '@/utils';

// 获取查询shopify产品数据接口
export const getShipifyProducts = (params: any, appId: number, locale: string) => {
    return request.get(`${baseUrl(locale)}/productQuote/productList${setLocaleQuery(locale)}`, {
        params: params,
        appId,
    });
};

// 将直传oss的pdf/img链接返回给后端（发邮件用）
export const postImgAndPDF = (data: any, locale: string) => {
    return request(`${baseUrl(locale)}/productQuote/update${setLocaleQuery(locale)}`, {
        method: 'post',
        data,
        // needToken: true,
    });
};

// 购物车提交表单
export const submitForm = (data: any, locale: string) => {
    return request(`${baseUrl(locale)}/productQuote/save${setLocaleQuery(locale)}`, {
        method: 'post',
        data,
        // needToken: true,
    });
};
