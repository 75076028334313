export const w_desktop_max = 1440;
export const w_desktop = 1024;
export const w_tablet = 768;
// 根据屏幕宽度获取设备类型
// 与css对应,不能用于写css;只能用于绑定事件或跳转
// $desktop-max-width: 1440px;
// $desktop-width: 1024px;
// $tablet-width: 768px;
// $mobile-width: 375px;
const getDevice = () => {
    let val = 'desktop';
    if (typeof document !== 'undefined') {
        const width = document.body.clientWidth;
        if (width > 1440) {
            val = 'desktop-max';
        } else if (width > 1024 && width <= 1440) {
            val = 'desktop';
        } else if (width >= 768 && width <= 1024) {
            val = 'pad';
        } else if (width < 768) {
            val = 'h5';
        }
    }

    return val;
};

// 根据对比的宽度,默认为1024,判断是否是手机端
export const getIsMobile = () => {
    if (typeof document !== 'undefined') {
        const width = document.body.clientWidth;

        if (width <= w_desktop) {
            return true;
        }
    }

    return false;
};

export default getDevice;
