'use client';
import { useParams, usePathname, useRouter as useR } from 'next/navigation';
import { useRouter } from 'next/router';

const getLocal = () => {
    let params: any = {};
    let pathname2: any = '';
    try {
        params = useParams() || {};
        pathname2 = usePathname();
    } catch (e: any) {}
    // console.log('params', params);
    // console.log('pathname2', pathname2);

    const locale: any = params.locale;
    const route: any = pathname2 || '';
    const pathname: any = pathname2 || '';
    const query: any = params.query || '';
    // 之前的组件没有useParams().locale;直接返回 router;
    if (!locale) {
        return useRouter();
    }

    return { ...useR(), locale, route, pathname, query };
};

export default getLocal;
