'use client';
import EditorImg from 'lowcode/components/EditorImg';
import EmarsysWin from 'lowcode/common/BarWin/EmarsysWin';
import { useState } from 'react';

export default function ClientComp({ id, data = {} }: any) {
    const { floatPc, floatH5 } = data;
    const [showWin, setShowWin] = useState(false);
    // 打开弹窗
    const openHandle = () => {
        setShowWin(true);
    };
    // 关闭弹窗
    const closeCallback = () => {
        setShowWin(false);
    };
    return (
        <>
            <div className="glo-float-link" onClick={openHandle}>
                <EditorImg pc={floatPc} h5={floatH5} />
            </div>
            {showWin && <EmarsysWin id={id} data={data} callback={closeCallback} initShow={true} />}
        </>
    );
}
