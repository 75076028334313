'use client';

export default function IconClose({ id, callback }: { id?: string; callback?: () => void }) {
    const closeHandle = () => {
        const elem = document.querySelector(`.glo-editor-modal-${id}`);
        if (elem) {
            elem.classList.remove('glo-editor-modal-show');
        }
        callback && callback();
    };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className="glo-editor-modal-close 111"
            onClick={closeHandle}
        >
            <path d="M6 5L19 18M19 5L6 18" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
