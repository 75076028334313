type LoadMethod = 'async' | 'defer' | undefined | null | '';
export const createScript = (src: string, loadMethod?: LoadMethod, type = 'text/javascript'): HTMLScriptElement => {
    const script = document.createElement('script');
    script.src = src;
    script.type = type;
    script.defer = true;
    if (loadMethod) {
        script[loadMethod] = true;
    }
    return script;
};

export const createTextScript = (code: any, type = 'text/javascript'): HTMLScriptElement => {
    const script = document.createElement('script');
    script.type = type;
    script.defer = true;
    script.appendChild(document.createTextNode(code));
    return script;
};

export const createBothScript = (src: string, code: string, loadMethod?: LoadMethod, type = 'text/javascript'): Record<string, HTMLScriptElement> => {
    const elScript = createScript(src, loadMethod, type);
    const textScript = createTextScript(code, type);
    return {
        elScript,
        textScript,
    };
};

// 日本站点专用统计
export const loadJPScript = (locale: string): void => {
    if (locale === 'jp') {
        const f: HTMLScriptElement = document.getElementsByTagName('script')[0];
        const src = 'https://dmp.im-apps.net/js/1011935/0001/itm.js';
        // const script: HTMLScriptElement = createScript(src, 'async');
        const script: HTMLScriptElement = createScript(src, 'defer');
        f.parentNode?.insertBefore(script, f);
    }
};

// 南非品牌官网TTD pixel安装
export const loadZAScript = (locale: string): void => {
    if (locale === 'za') {
        const f: HTMLScriptElement = document.getElementsByTagName('script')[0];
        const code = `
            window.onload = function() {
                ttd_dom_ready( function() {
                    if (typeof TTDUniversalPixelApi === 'function') {
                        var universalPixelApi = new TTDUniversalPixelApi();
                        universalPixelApi.init("p9kn8wu", ["22z3men"], "https://insight.adsrvr.org/track/up");
                    }
                });
            }
        `;
        const { elScript, textScript } = createBothScript('https://js.adsrvr.org/up_loader.1.1.0.js', code, 'defer');
        f.parentNode?.insertBefore(elScript, f);
        document.body.append(textScript);
    }
};

// US
export const loadUSScript = (locale: string): void => {
    if (locale === 'us' || locale === 'ca') {
        const f: HTMLScriptElement = document.getElementsByTagName('script')[0];

        const script = document.createElement('script');
        script.src = 'https://js.adsrvr.org/up_loader.1.1.0.js';
        script.defer = true;
        script.onload = function () {
            const ttddr = document.referrer;
            const ttdRKW = ['source=', 'fbclid=', 'msclkid=', 'utm_', 'gclid=', 'sscid='];
            const ttdRef = encodeURIComponent(ttddr);
            let ttdNobounce = '';
            if (ttddr) {
                if (ttddr.indexOf('ecoflow') !== -1) {
                    if (new RegExp(ttdRKW.join('|')).test(ttddr)) {
                        ttdNobounce = 'no_bounce';
                    }
                }
            }
            ttd_dom_ready(function () {
                if (typeof TTDUniversalPixelApi === 'function') {
                    const universalPixelApi = new TTDUniversalPixelApi();
                    universalPixelApi.init('p9kn8wu', ['22z3men'], 'https://insight.adsrvr.org/track/up', { td1: ttdRef, td2: ttdNobounce });
                }
            });
        };
        f.parentNode?.insertBefore(script, f);
    }
};

export const LoadTriplePixel = (locale: string) => {
    if (locale === 'ca') {
        (window.TriplePixelData = {
            TripleName: 'ecoflow-canada.myshopify.com',
            ver: '2.17',
            plat: 'SHOPIFY',
            isHeadless: true,
        }),
            (function (W, H, A, L, E, _, B, N) {
                function O(U, T, P, H, R) {
                    void 0 === R && (R = !1),
                        (H = new XMLHttpRequest()),
                        P ? (H.open('POST', U, !0), H.setRequestHeader('Content-Type', 'text/plain')) : H.open('GET', U, !0),
                        H.send(JSON.stringify(P || {})),
                        (H.onreadystatechange = function () {
                            4 === H.readyState && 200 === H.status
                                ? ((R = H.responseText), U.includes('/first') ? eval(R) : P || (N[B] = R))
                                : (299 < H.status || H.status < 200) && T && !R && ((R = !0), O(U, T - 1, P));
                        });
                }
                if (((N = window), !N[H + 'sn'])) {
                    (N[H + 'sn'] = 1),
                        (L = function () {
                            return Date.now().toString(36) + '_' + Math.random().toString(36);
                        });
                    try {
                        A.setItem(H, 1 + (0 | A.getItem(H) || 0)),
                            (E = JSON.parse(A.getItem(H + 'U') || '[]')).push({
                                u: location.href,
                                r: document.referrer,
                                t: Date.now(),
                                id: L(),
                            }),
                            A.setItem(H + 'U', JSON.stringify(E));
                    } catch (e) {}
                    var i, m, p;
                    A.getItem('"!nC`') ||
                        ((_ = A),
                        (A = N),
                        A[H] ||
                            ((E = A[H] =
                                function (t, e, a) {
                                    return void 0 === a && (a = []), 'State' == t ? E.s : ((W = L()), (E._q = E._q || []).push([W, t, e].concat(a)), W);
                                }),
                            (E.s = 'Installed'),
                            (E._q = []),
                            (E.ch = W),
                            (B = 'configSecurityConfModel'),
                            (N[B] = 1),
                            O('https://conf.config-security.com/model', 5),
                            (i = L()),
                            (m = A[atob('c2NyZWVu')]),
                            _.setItem('di_pmt_wt', i),
                            (p = {
                                id: i,
                                action: 'profile',
                                avatar: _.getItem('auth-security_rand_salt_'),
                                time: m[atob('d2lkdGg=')] + ':' + m[atob('aGVpZ2h0')],
                                host: A.TriplePixelData.TripleName,
                                plat: A.TriplePixelData.plat,
                                url: window.location.href.slice(0, 500),
                                ref: document.referrer,
                                ver: A.TriplePixelData.ver,
                            }),
                            O('https://api.config-security.com/event', 5, p),
                            O('https://api.config-security.com/first?host=ecoflow-canada.myshopify.com&plat=SHOPIFY', 5)));
                }
            })('', 'TriplePixel', localStorage);
    }

    if (locale === 'us') {
        (window.TriplePixelData = {
            TripleName: 'ecoflow-tech.myshopify.com',
            ver: '2.17',
            plat: 'SHOPIFY',
            isHeadless: true,
        }),
            (function (W, H, A, L, E, _, B, N) {
                function O(U, T, P, H, R) {
                    void 0 === R && (R = !1),
                        (H = new XMLHttpRequest()),
                        P ? (H.open('POST', U, !0), H.setRequestHeader('Content-Type', 'text/plain')) : H.open('GET', U, !0),
                        H.send(JSON.stringify(P || {})),
                        (H.onreadystatechange = function () {
                            4 === H.readyState && 200 === H.status
                                ? ((R = H.responseText), U.includes('/first') ? eval(R) : P || (N[B] = R))
                                : (299 < H.status || H.status < 200) && T && !R && ((R = !0), O(U, T - 1, P));
                        });
                }
                if (((N = window), !N[H + 'sn'])) {
                    (N[H + 'sn'] = 1),
                        (L = function () {
                            return Date.now().toString(36) + '_' + Math.random().toString(36);
                        });
                    try {
                        A.setItem(H, 1 + (0 | A.getItem(H) || 0)),
                            (E = JSON.parse(A.getItem(H + 'U') || '[]')).push({
                                u: location.href,
                                r: document.referrer,
                                t: Date.now(),
                                id: L(),
                            }),
                            A.setItem(H + 'U', JSON.stringify(E));
                    } catch (e) {}
                    var i, m, p;
                    A.getItem('"!nC`') ||
                        ((_ = A),
                        (A = N),
                        A[H] ||
                            ((E = A[H] =
                                function (t, e, a) {
                                    return void 0 === a && (a = []), 'State' == t ? E.s : ((W = L()), (E._q = E._q || []).push([W, t, e].concat(a)), W);
                                }),
                            (E.s = 'Installed'),
                            (E._q = []),
                            (E.ch = W),
                            (B = 'configSecurityConfModel'),
                            (N[B] = 1),
                            O('https://conf.config-security.com/model', 5),
                            (i = L()),
                            (m = A[atob('c2NyZWVu')]),
                            _.setItem('di_pmt_wt', i),
                            (p = {
                                id: i,
                                action: 'profile',
                                avatar: _.getItem('auth-security_rand_salt_'),
                                time: m[atob('d2lkdGg=')] + ':' + m[atob('aGVpZ2h0')],
                                host: A.TriplePixelData.TripleName,
                                plat: A.TriplePixelData.plat,
                                url: window.location.href.slice(0, 500),
                                ref: document.referrer,
                                ver: A.TriplePixelData.ver,
                            }),
                            O('https://api.config-security.com/event', 5, p),
                            O('https://api.config-security.com/first?host=ecoflow-tech.myshopify.com&plat=SHOPIFY', 5)));
                }
            })('', 'TriplePixel', localStorage);
    }
};

// 亚马逊DSP广告代码安装需求 https://ecoflow.feishu.cn/docx/SPh2dIM1SoGm36xgSlvctzhEnth
export const amazonDSPScript = (): void => {
    (function (e, s, t) {
        t = t || {};
        t.id = e;
        const versaTagObj = {
            $: [],
            onready: function (e: never) {
                this.$.push(e);
            },
        };
        const n: any = s.getElementsByTagName('script')[0];
        const r: any = s.createElement('script');
        r.options = t;
        r.async = !0;
        r.defer = true;
        r.src = `https://secure-ds.serving-sys.com/SemiCachedScripts/ebOneTag.js?id=${e}`;
        r.options = t;
        n.parentNode.insertBefore(r, n);
    })('1073749300', document, { dataLayer: 'SizmekDataLayer' });
};

// 使用LINE Tag追蹤 https://ecoflow.feishu.cn/docx/EuHRdbtMoo4RJ9xxn2fc4Sminje
// 需求 https://ecoflow.feishu.cn/docx/GBHadvfhbooGZaxN36ucTWiqn1f
export const lineTagScript = (): void => {
    (function (g, d, o) {
        g._ltq = g._ltq || [];
        g._lt =
            g._lt ||
            function () {
                g._ltq.push(arguments);
            };
        const s: any = d.createElement('script');
        s.async = 1;
        s.defer = true;
        s.src = o || `https://d.line-scdn.net/n/line_tag/public/release/v1/lt.js`;
        const t: any = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);
    })(window, document);
    _lt('init', {
        customerType: 'lap',
        tagId: '7a08d550-718c-4404-a781-8b48049e1384',
    });
    _lt('send', 'pv', ['7a08d550-718c-4404-a781-8b48049e1384']);
};

// yahoo广告代码
export const loadYahooScript = (): void => {
    const f: HTMLScriptElement = document.getElementsByTagName('script')[0];
    const src = 'https://s.yimg.jp/images/listing/tool/cv/ytag.js';
    // const script: HTMLScriptElement = createScript(src, 'async');
    const script: HTMLScriptElement = createScript(src, 'defer');
    f.parentNode?.insertBefore(script, f);
};

// load Schema
export const loadSchemaScript = (): void => {
    const f: HTMLScriptElement = document.getElementsByTagName('script')[0];
    const code = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        name: 'EcoFlow',
        url: 'https://www.ecoflow.com/us',
    };
    const script: HTMLScriptElement = createTextScript(JSON.stringify(code), 'application/ld+json');
    f.defer = true;
    f.parentNode?.insertBefore(script, f);
};

// B端销售销售RE-MKT广告代码设置需求
// https://ecoflow.feishu.cn/sheets/WTl6s5fqPhqiottL99WcNqBOnGe
export const linkedinScript = (): void => {
    (function (l) {
        if (!l) {
            window.lintrk = function (a, b) {
                window.lintrk.q.push([a, b]);
            };
            window.lintrk.q = [];
        }
        const s = document.getElementsByTagName('script')[0];
        const b = document.createElement('script');
        b.type = 'text/javascript';
        b.async = true;
        b.defer = true;
        b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
        s?.parentNode.insertBefore(b, s);
    })(window.lintrk);
};
