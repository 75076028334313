'use client';

import { getToken, removeCookie } from 'lowcode/common/ABTestClient/ABTestCookie';
import getDefaultHeader from './getDefaultHeader';

const handleFetchError = (res: Response) => {
    switch (res.status) {
        case 401:
            removeCookie('token');
            removeCookie('userInfo');
            if (typeof window !== 'undefined') {
                const locationHref = location.href;
                if (!locationHref.includes('login')) {
                    // 通过url获取当前locale
                    const url = window.location.pathname;
                    const firstPath = url.split('/')[1];
                    window.location.replace(`/${firstPath}/login?redirect=${encodeURIComponent(locationHref)}`);
                }
            }
            return;
        default:
            break;
    }
};

// 发起请求
export const fetchGet = async (url: string, locale: string, params?: any, options?: any) => {
    const headers: any = getDefaultHeader(locale);
    // 需要登录
    if (options && options.needToken) {
        const token = getToken();
        headers['Authorization'] = `Bearer ${token}`;
    }

    const res = await fetch(url, {
        method: 'GET',
        headers: headers,
    });
    const { status } = res;
    if (status !== 200) handleFetchError(res);
    return res.json();
};

// 发起请求
export const fetchPost = async (url: string, locale: string, params?: any, options?: any) => {
    const headers: any = getDefaultHeader(locale);
    const headers2 = options?.headers;
    // 需要登录
    if (options && options.needToken) {
        const token = getToken();
        headers['Authorization'] = `Bearer ${token}`;
    }
    headers['Accept'] = 'application/json';
    headers['Content-Type'] = 'application/json';

    const res = await fetch(url, {
        method: 'POST',
        headers: {
            ...headers,
            ...headers2,
        },
        body: JSON.stringify(params || {}),
        next: { revalidate: 0 },
    });
    const { status } = res;
    if (status !== 200) handleFetchError(res);
    return res.json();
};
