/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { APP_BY_LANG } from '@/constants';
import { isReadyLang, parseUrlQuery, requestHeader } from '@/utils';
import { loginUser } from '@/utils/cookie';
import { localsList } from '@/utils/locals';
import { getLocalStorage } from '@/utils/localStorage';
import generateTraceId from '@/utils/singUtil/generateTraceId';
import jwt_decode from 'jwt-decode';
import { getEfDeviceId } from 'lowcode/utils/getBrowserIdFromCanvas';
import cookie from 'react-cookies';
import { extend } from 'umi-request';

const codeMessage: any = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};

/**
 * 判断token是否过期，若未过期返回token，否则返回空串
 */
export const createToken = () => {
    try {
        const token = loginUser()?.token;
        const jwt = jwt_decode<any>(token);
        if (Date.now() > jwt.exp * 1000) {
            return '';
        }
        return token;
    } catch (e) {
        return '';
    }
};

/**
 * 异常处理程序
 */
const errorHandler = (error: { response: Response }): Response => {
    const { response } = error;
    if (response && response.status) {
        const errorText = codeMessage[response.status] || response.statusText;
        const { status, url } = response;
        console.error({
            message: `请求错误 ${status}: ${url}`,
            description: errorText + JSON.stringify(response, null, 2),
        });
    } else if (!response) {
        console.error({
            description: '您的网络发生异常，无法连接服务器',
            message: `网络异常${JSON.stringify(error, null, 2)}`,
        });
    }
    return response;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
    errorHandler, // 默认错误处理
    timeout: 30000, // CRM 接口链路太长
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        systemName: 'website_user',
    },
});

const getDefaultAppInfo = () => {
    const locale = location?.pathname?.split('/')?.[1] || 'us';
    const appInfo = Object.values(localsList)
        .flat()
        .find((item) => item.local === locale);
    return {
        appId: appInfo?.appId,
        acceptLanguage: appInfo?.acceptLanguage,
        locale: appInfo?.local,
    };
};

// 全局劫获
request.interceptors.request.use((_url, options: any) => {
    const queryObj: any = parseUrlQuery(options.url);
    const lang = queryObj.locale;
    const regURL = /^http/i;
    const regApi = /^\/api\//i;
    let appInfo = getLocalStorage('appInfo');
    let host = process.env.NEXT_PUBLIC_API_HOST;
    if (typeof window !== 'undefined') {
        const localeInUrl = (window.location.pathname.split('/')[1] || '').toLocaleLowerCase();
        const locale = typeof APP_BY_LANG[localeInUrl] !== 'undefined' && isReadyLang(localeInUrl) ? localeInUrl : 'us';
        appInfo = APP_BY_LANG[locale];
    }

    if (typeof window !== 'undefined' && (!appInfo || (appInfo && !appInfo.appId))) {
        appInfo = getDefaultAppInfo();
    }
    if (appInfo && appInfo.appId && appInfo.appId == '10' && process.env.NEXT_PUBLIC_MODY == 'prd') {
        host = process.env.NEXT_PUBLIC_API_HOST_CN;
    }
    if (options?.needToken) {
        options.headers['Authorization'] = `Bearer ${createToken()}`;
    }
    options.headers['traceId'] = generateTraceId();
    // const efDeviceId = getEfDeviceId();
    // if (efDeviceId) {
    //     options.headers['terminalId'] = efDeviceId;
    // }
    getEfDeviceId().then((val) => {
        if (val) {
            options.headers['terminalId'] = val;
        }
    });
    options.headers['x-appid'] = appInfo?.appId ? appInfo?.appId : options?.params?.appId || process.env.NEXT_PUBLIC_API_ID;
    options.headers['Accept-Language'] = appInfo?.acceptLanguage
        ? appInfo?.acceptLanguage === 'de-De,de;q=0.9'
            ? 'en-US,en;q=0.9'
            : appInfo?.acceptLanguage
        : 'en-US,en;q=0.9';
    if (typeof lang !== 'undefined' && typeof APP_BY_LANG[lang] !== 'undefined' && isReadyLang(lang)) {
        options.headers = {
            ...options.headers,
            // 多语言接口数据正常了，再 uncomment 下面一行
            ...requestHeader(lang),
        };
        const langQueryReg = new RegExp(`(\\?|&)locale=${lang}$`);
        options.url = options.url.replace(langQueryReg, '');
    }
    if (options.withoutToken) {
        options.headers['Authorization'] = '';
    }
    if (options?.appId) {
        options.headers['x-appid'] = options?.appId;
    }
    if (options?.apiKey) {
        options.headers['apiKey'] = options?.apiKey;
    }
    const oldUrl = options.url;
    let newUrl = '';
    if (regURL.test(oldUrl)) {
        newUrl = oldUrl;
    } else if (regApi.test(oldUrl)) {
        newUrl = process.env.NEXT_PUBLIC_WEB_HOST + oldUrl;
    } else {
        newUrl = host + oldUrl;
    }
    return {
        url: newUrl,
        options,
    };
});

request.interceptors.response.use(async (response) => {
    const res = await response.clone().json();
    const { code } = res;
    // 频繁点击
    if (code === 1020) {
        window.location.href = '/frequency';
    }
    return response;
});

request.interceptors.response.use(async (response, request) => {
    const { status } = response;
    if (status === 401) {
        cookie.remove('us-token');
        cookie.remove('userInfo', { path: '/' });
        cookie.remove('userInfo', { path: '/', domain: '.ecoflow.com' });
        cookie.remove('userInfo', { path: '/', domain: '.iot-ecoflow.com' });
        if (typeof window !== 'undefined') {
            const locationHref = location.pathname;
            const isNoLogin1 = locationHref.includes('login'); //登录页
            const isNoLogin2 = locationHref.includes('tempedit'); //编辑页
            if (isNoLogin1 || isNoLogin2) {
            } else {
                //if (!locationHref.includes('login') || !locationHref.includes('tempedit') || !locationHref.includes('preview')) {
                // 通过url获取当前locale
                const url = window.location.pathname;
                const firstPath = url.split('/')[1];
                window.location.replace(`/${firstPath}/login?redirect=${encodeURIComponent(locationHref)}`);
            }
        }
    }
    return response;
});

export default request;
