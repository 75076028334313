'use client';

const textLang: any = {
    us: {
        btn_text: 'Subscribe',
        protocol: `* I agree to EcoFlow's <a class="link" href="https://account.ecoflow.com/agreement/en-us/PrivacyPolicy.html" target="_blank" rel="noreferrer">Privacy Policy</a> and <a class="link" href="https://us.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>.`,
        site_bottom_subscription_tip_one: 'This email has been subscribed, Please use another email address',
        site_bottom_subscription_tip_three: 'Thanks for subscribing！',
        site_bottom_subscription_email: 'Email Address',
        site_bottom_subscription_tip_two: 'Please enter a valid email address',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'You agree to receive newsletters and marketing emails from EcoFlow.',
        site_bottom_subscription_email_protocol_hint: 'Please tick the checkbox to proceed.',
        register_win_title: 'Thank you for subscribing!',
        register_win_desc1: 'Here is your $79 Off code: <span class="win-color2">EFWELCOME</span>',
        register_win_desc2: 'oin us to receive an additional 500 EcoCredits for free',
        register_win_btn: 'Create an EcoFlow Account',
        register_win_tips: 'EcoCredits can be redeemed for discounts and gifts',
    },
    ca: {
        btn_text: 'Subscribe',
        protocol: `* I agree to EcoFlow's <a class="link" href="https://account.ecoflow.com/agreement/en-ca/PrivacyPolicy.html" target="_blank" rel="noreferrer">Privacy Policy</a> and <a class="link" href="https://ca.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>.`,
        site_bottom_subscription_tip_one: 'This email has been subscribed, Please use another email address',
        site_bottom_subscription_tip_three: 'Thanks for subscribing！',
        site_bottom_subscription_email: 'Email Address',
        site_bottom_subscription_tip_two: 'Please enter a valid email address',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'You agree to receive newsletters and marketing emails from EcoFlow.',
        site_bottom_subscription_email_protocol_hint: 'Please tick the checkbox to proceed.',
    },
    uk: {
        btn_text: 'Subscribe',
        protocol: `* I agree to EcoFlow's <a class="link" href="https://uk.ecoflow.com/policies/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a class="link" href="https://uk.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>.`,
        site_bottom_subscription_tip_one: 'This email has been subscribed, Please use another email address',
        site_bottom_subscription_tip_three: 'Thanks for subscribing！',
        site_bottom_subscription_email: 'Email Address',
        site_bottom_subscription_tip_two: 'Please enter a valid email address',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'You agree to receive newsletters and marketing emails from EcoFlow.',
        site_bottom_subscription_email_protocol_hint: 'Please tick the checkbox to proceed.',
        register_win_title: 'Thank you for subscribing!',
        register_win_desc1: 'Here is your $79 Off code: <span class="win-color2">EFWELCOME</span>',
        register_win_desc2: 'oin us to receive an additional 500 EcoCredits for free',
        register_win_btn: 'Create an EcoFlow Account',
        register_win_tips: 'EcoCredits can be redeemed for discounts and gifts',
    },
    au: {
        btn_text: 'Subscribe',
        protocol: `* I agree to EcoFlow's <a class="link" href="https://au.ecoflow.com/policies/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a class="link" href="https://au.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>.`,
        site_bottom_subscription_tip_one: 'This email has been subscribed, Please use another email address',
        site_bottom_subscription_tip_three: 'Thanks for subscribing！',
        site_bottom_subscription_email: 'Email Address',
        site_bottom_subscription_tip_two: 'Please enter a valid email address',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'You agree to receive newsletters and marketing emails from EcoFlow.',
        site_bottom_subscription_email_protocol_hint: 'Please tick the checkbox to proceed.',
    },
    eu: {
        btn_text: 'Subscribe',
        protocol: `* I agree to EcoFlow's <a class="link" href="https://eu.ecoflow.com/policies/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a class="link" href="https://eu.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>.`,
        site_bottom_subscription_tip_one: 'This email has been subscribed, Please use another email address',
        site_bottom_subscription_tip_three: 'Thanks for subscribing！',
        site_bottom_subscription_email: 'Email Address',
        site_bottom_subscription_tip_two: 'Please enter a valid email address',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'You agree to receive newsletters and marketing emails from EcoFlow.',
        site_bottom_subscription_email_protocol_hint: 'Please tick the checkbox to proceed.',
    },
    za: {
        btn_text: 'Subscribe',
        protocol: `* I agree to EcoFlow's <a class="link" href="https://za.ecoflow.com/policies/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a class="link" href="https://za.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>.`,
        site_bottom_subscription_tip_one: 'This email has been subscribed, Please use another email address',
        site_bottom_subscription_tip_three: 'Thanks for subscribing！',
        site_bottom_subscription_email: 'Email Address',
        site_bottom_subscription_tip_two: 'Please enter a valid email address',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'You agree to receive newsletters and marketing emails from EcoFlow.',
        site_bottom_subscription_email_protocol_hint: 'Please tick the checkbox to proceed.',
    },
    cis: {
        btn_text: 'Subscribe',
        protocol: `* I agree to EcoFlow's <a class="link" href="https://cis.ecoflow.com/policies/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a class="link" href="https://cis.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>.`,
        site_bottom_subscription_tip_one: 'Этот адрес уже используется, укажите другой адрес эл. почты',
        site_bottom_subscription_tip_three: 'Благодарим за подписку.',
        site_bottom_subscription_email: 'Адрес эл. почты',
        site_bottom_subscription_tip_two: 'Укажите действующий адрес эл. почты',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'You agree to receive newsletters and marketing emails from EcoFlow.',
        site_bottom_subscription_email_protocol_hint: 'Please tick the checkbox to proceed.',
    },
    cn: {
        btn_text: '订阅',
        protocol: `* I agree to EcoFlow's <a class="link" href="https://cn.ecoflow.com/policies/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a class="link" href="https://cn.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>.`,
        site_bottom_subscription_tip_one: '此电子邮件已被订阅，请使用其他电子邮件地址',
        site_bottom_subscription_tip_three: '感谢您的订阅！',
        site_bottom_subscription_email: '电子邮件地址',
        site_bottom_subscription_tip_two: '请输入有效的电子邮件地址',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'You agree to receive newsletters and marketing emails from EcoFlow.',
    },
    de: {
        btn_text: 'Anmelden',
        protocol: `* Ich erkläre mich mit <a class="link" href="https://de.ecoflow.com/policies/privacy-policy" target="_blank" rel="noreferrer">den Datenschutzrichtlinien </a> und <a class="link" href="https://de.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Nutzungsbedingungen von EcoFlow </a> einverstanden.`,
        site_bottom_subscription_tip_one: 'Diese E-Mail wurde bereits abonniert. Verwenden Sie eine andere E-Mail-Adresse',
        site_bottom_subscription_tip_three: 'Vielen Dank für Ihre Registrierung!',
        site_bottom_subscription_email: 'E-Mail-Adresse',
        site_bottom_subscription_tip_two: 'Geben Sie eine gültige E-Mail-Adresse ein',
        site_bottom_subscription_tip_five: 'Bitte kreuzen Sie mindestens ein Szenario an, um sich anzumelden.',
        site_bottom_subscription_email_protocol: 'Ich bin mit der Zusendung des EcoFlow Newsletters einverstanden.',
        site_bottom_subscription_email_protocol_hint: 'Bitte ankreuzen, um fortzufahren.',
    },
    es: {
        btn_text: 'Suscribirse',
        protocol: `* Acepto la <a class="link" href="https://es.ecoflow.com/policies/privacy-policy" target="_blank" rel="noreferrer">Política de privacidad</a> y los <a class="link" href="https://es.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Términos de servicio</a> de EcoFlow.`,
        site_bottom_subscription_tip_one: 'Este correo electrónico ya se ha suscrito. Utiliza otra dirección de correo electrónico',
        site_bottom_subscription_tip_three: '¡Gracias por suscribirte!',
        site_bottom_subscription_email: 'Dirección de correo electrónico',
        site_bottom_subscription_tip_two: 'Introduce una dirección de correo electrónico válida',
        site_bottom_subscription_tip_five: 'Seleccione al menos un escenario para inscribirse.',
        site_bottom_subscription_email_protocol: 'Aceptas recibir boletines y correos electrónicos de marketing de EcoFlow.',
        site_bottom_subscription_email_protocol_hint: 'Marca la casilla para continuar.',
    },
    fr: {
        btn_text: "S'abonner",
        protocol: `* J'accepte la <a class="link" href="https://fr.ecoflow.com/policies/privacy-policy" target="_blank" rel="noreferrer">Politique de confidentialité</a> et <a class="link" href="https://fr.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">les Conditions d'utilisation</a> d'EcoFlow.`,
        site_bottom_subscription_tip_one: 'Cette adresse e-mail a déjà été enregistrée. Veuillez en utiliser une autre.',
        site_bottom_subscription_tip_three: 'Merci de vous être abonné(e) !',
        site_bottom_subscription_email: 'Adresse e-mail',
        site_bottom_subscription_tip_two: 'Veuillez saisir une adresse e-mail valide.',
        site_bottom_subscription_tip_five: 'Veuillez cocher au moins un scénario pour vous abonner.',
        site_bottom_subscription_email_protocol: "Vous acceptez de recevoir la newsletter d'EcoFlow.",
        site_bottom_subscription_email_protocol_hint: 'Veuillez cocher la case pour continuer.',
    },
    it: {
        btn_text: 'Iscriviti',
        protocol: `* Accetto l'Informativa sulla <a class="link" href="https://it.ecoflow.com/policies/privacy-policy" target="_blank" rel="noreferrer">privacy</a> e i <a class="link" href="https://it.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Termini di servizio</a> di EcoFlow.`,
        site_bottom_subscription_tip_one: 'Questa e-mail è già stata registrata. Utilizza un altro indirizzo e-mail',
        site_bottom_subscription_tip_three: 'Grazie per esserti iscritto！',
        site_bottom_subscription_email: 'Indirizzo e-mail',
        site_bottom_subscription_tip_two: 'Inserisci un indirizzo e-mail valido',
        site_bottom_subscription_tip_five: 'Si prega di selezionare almeno uno scenario per iscriverti.',
        site_bottom_subscription_email_protocol: 'Accetti di ricevere newsletter ed e-mail di marketing da parte di EcoFlow.',
        site_bottom_subscription_email_protocol_hint: 'Si prega di spuntare la casella per procedere.',
    },
    jp: {
        btn_text: 'Subscribe',
        protocol: `* I consent to the <a class="link" href="https://jp.ecoflow.com/policies/privacy-policy-2023" target="_blank" rel="noreferrer">Privacy Policy</a> and <a class="link" href="https://jp.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>.`,
        site_bottom_subscription_tip_one: 'このメールアドレスは登録されています。別のメールアドレスを使用してください',
        site_bottom_subscription_tip_three: 'ご登録ありがとうございます。',
        site_bottom_subscription_email: 'メールアドレス',
        site_bottom_subscription_tip_two: '有効なメールアドレスを入力してください',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'EcoFlowからニュースレターやプロモーションメールを受け取ることに同意するものとします。',
        site_bottom_subscription_email_protocol_hint: '次に進むには同意するにチェックを入れてください。',
    },
    kr: {
        btn_text: '구독',
        protocol: `* EcoFlow의 <a class="link" href="https://kr.ecoflow.com/policies/privacy-policy" target="_blank" rel="noreferrer">개인정보 보호정책</a> 및 <a class="link" href="https://kr.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">이용약관</a>에 동의합니다.`,
        site_bottom_subscription_tip_one: '이 이메일은 이미 구독 중입니다. 다른 이메일 주소를 사용해 주십시오.',
        site_bottom_subscription_tip_three: '구독해주셔서 감사합니다!',
        site_bottom_subscription_email: '이메일 주소',
        site_bottom_subscription_tip_two: '유효한 이메일 주소를 입력해 주십시오.',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'EcoFlow의 뉴스레터 및 마케팅 이메일을 수신하는 데 동의합니다.',
        site_bottom_subscription_email_protocol_hint: '계속 진행하려면 동의 확인란을 선택하세요.',
    },
    sa: {
        btn_text: 'Subscribe',
        protocol: `* I agree to EcoFlow's <a class="link" href="https://sa.ecoflow.com/policies/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a class="link" href="https://sa.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>.`,
        site_bottom_subscription_tip_one: 'عنوان ​​البريد الإلكتروني هذا مشترك بالفعل. يُرجى استخدام عنوان بريد إلكتروني آخر',
        site_bottom_subscription_tip_three: 'شكرًا لك على الاشتراك!',
        site_bottom_subscription_email: 'عنوان البريد الإلكتروني',
        site_bottom_subscription_tip_two: 'يُرجى إدخال عنوان بريد إلكتروني صالح',
        site_bottom_subscription_tip_five: 'Please tick at least one scenario to subscribe.',
        site_bottom_subscription_email_protocol: 'You agree to receive newsletters and marketing emails from EcoFlow.',
    },
    tw: {
        btn_text: 'Subscribe',
        protocol: `* I agree to EcoFlow's <a class="link" href="https://tw.ecoflow.com/policies/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a class="link" href="https://tw.ecoflow.com/policies/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>.`,
        site_bottom_subscription_tip_one: '此電子郵件已訂閱過，請使用其他電子郵件地址',
        site_bottom_subscription_tip_three: '感謝您的訂閱！',
        site_bottom_subscription_email: '電子郵件地址',
        site_bottom_subscription_email_protocol: 'You agree to receive newsletters and marketing emails from EcoFlow.',
    },
};

export default textLang;
