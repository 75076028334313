const getTimeToSecondNum = (val: string | number) => {
    return `00${val}`.slice(-2);
};
export const getDateString = () => {
    const myDate = new Date();
    const year = myDate.getFullYear();
    const month = myDate.getMonth() + 1;
    const date = myDate.getDate();
    const hours = myDate.getHours();
    const minute = myDate.getMinutes();
    const second = myDate.getSeconds();
    const value = `${year}${getTimeToSecondNum(month)}${getTimeToSecondNum(
        date,
    )}${getTimeToSecondNum(hours)}${getTimeToSecondNum(
        minute,
    )}${getTimeToSecondNum(second)}`;

    return value;
};

export const getTraceId = (num = 32) => {
    const val1 = getDateString();
    const val2 = `${Math.random()}`.slice(2);
    const val3 = `${Math.random()}`.slice(2);
    const val4 = `${Math.random()}`.slice(2);
    const val5 = `${Math.random()}`.slice(2);
    return `${val1}${val2}${val3}${val4}${val5}`.slice(0, num);
}