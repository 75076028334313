'use client';
import { w_desktop } from 'lowcode/common/getDevice';
import styles from './index.module.scss';

export default function EditorImg({ className = '', alt = '', pc, pad, h5 }: any) {
    return (
        <picture className={`${styles.lowcodeimg} ${className} lowcode-img`}>
            {h5 && <source media={`(max-width: ${w_desktop}px)`} srcSet={h5}></source>}
            {pc && <img src={pc} alt={alt} aria-label={alt} />}
        </picture>
    );
}
