export const cacheOrCatch = ({ requestQuery, cachePath }: any) => {
    const ret = requestQuery || Promise.resolve(null);

    // // 打包构建时执行
    // if (process.env.NEXT_MODE === 'build' && cachePath) {
    //     let reqData = {} as any;
    //     try {
    //         // 如果有缓存的文件
    //         reqData = require(`/scripts/cache/${cachePath}`);

    //         // 如果缓存是有效数据
    //         if (typeof reqData?.code !== 'undefined' && reqData?.code === '0' && !isEmpty(reqData?.data)) {
    //             return Promise.resolve(reqData);
    //         } else {
    //             // 如果缓存是报错信息，变为实时请求
    //             return ret;
    //         }
    //     } catch (e) {
    //         // 如果没有缓存文件，变为实时请求
    //         return ret;
    //     }
    // } else {
    //     // 在 runtime 时，实时请求
    //     return ret;
    // }

    return ret;
};
