'use strict';

var _Snippets = require("./snippets");
var _interopRequireDefault = function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
};
var _Snippets2 = _interopRequireDefault(_Snippets);
var emManager = {
  emarsys: function emarsys(args) {
    var snippets = _Snippets2.default.emarsys.tags(args);
    var createScript = function createScript() {
      var script = document.createElement('script');
      script.innerHTML = snippets.script;
      return script;
    };
    return {
      createScript: createScript
    };
  },
  initialize: function initialize(_ref) {
    var merchantId = _ref.merchantId;
    var emarsys = this.emarsys({
      merchantId: merchantId
    });
    document.head.insertBefore(emarsys.createScript(), document.head.childNodes[0]);
  }
};
module.exports = emManager;