/**
 * 获取国家所属区域
 * @params locale 语言
 * @return cn 中国; eu 欧洲; us 美国;
 */
export const getCountryRegion = (locale: string) => {
    const EU_MAP = ['de', 'it', 'uk', 'fr', 'cis', 'es', 'eu', 'za', 'sa', 'jp', 'kr'];
    const CN_MAP = ['cn'];
    if (CN_MAP.includes(locale)) return 'cn';
    if (EU_MAP.includes(locale)) return 'eu';
    return 'us';
};
