import { baseUrl } from './util';
import request from '@/utils/request';
import { setLocaleQuery } from '@/utils';
import { getCountryRegion } from '@/utils/countryRegion';

/**
 * 提交Quote表单 // Power Ocean 详情页/APP页面
 * @param data 请求参数
 */
export type quoteParams = {
    firstName: string;
    lastName: string;
    country: string;
    zipCode: string;
    email: string;
    phone: string;
    message?: string;
    customerSituation: string;
    recaptchaResponse: string;
};
export const submitFreeQuote = (data: quoteParams, locale?: string) => {
    return request(`${baseUrl(locale)}/powerocean/quote${setLocaleQuery(locale)}`, {
        method: 'post',
        data,
        // needToken: true,
    });
};

// For B端 企业官网 => 数据进入企业官网后台(需用到企业官网的x-appid)
export const submitFreeQuoteToB = (data: quoteParams, locale: string) => {
    const isEU = getCountryRegion(locale || 'eu') === 'eu';
    const HOST_STS = isEU ? process.env.NEXT_PUBLIC_STS_HOST_EU : process.env.NEXT_PUBLIC_STS_HOST;
    const appidMap: any = {
        us: 101,
        de: 102,
        eu: 103,
        uk: 105,
        fr: 106,
        es: 107,
        it: 108,
    };
    return request(`${HOST_STS}/enterprise/exhibitionWithEvent/partnerRegister${setLocaleQuery(locale)}`, {
        method: 'post',
        data,
        // needToken: true,
        appId: appidMap[locale] || appidMap.us,
    });
};

// For B端 企业官网 => 数据进入企业官网后台(需用到企业官网的x-appid)
export const subscribeToB = (data: any, locale: string) => {
    const isEU = getCountryRegion(locale || 'eu') === 'eu';
    const HOST_STS = isEU ? process.env.NEXT_PUBLIC_STS_HOST_EU : process.env.NEXT_PUBLIC_STS_HOST;
    const appidMap: any = {
        us: 101,
        de: 102,
        eu: 103,
        uk: 105,
        fr: 106,
        es: 107,
        it: 108,
    };
    return request(`${HOST_STS}/enterprise/subscribe/${setLocaleQuery(locale)}`, {
        method: 'post',
        data,
        // needToken: true,
        appId: appidMap[locale] || appidMap.us,
    });
};
