import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import './index.scss';

const NEXT_PUBLIC_MODY = process.env.NEXT_PUBLIC_MODY;
const NEXT_IS_PRD = NEXT_PUBLIC_MODY === 'test';
function UiCheck() {
    const { query } = useRouter();
    const isUiCheck = query.ui;

    if (!NEXT_IS_PRD) {
        return null;
    }
    if (!isUiCheck) {
        return null;
    }
    const bg1920 = query['1920'];
    const bg1366 = query['1366'];
    const bg768 = query['768'];
    const bg375 = query['375'];
    const defaultTop = query['top'] || '0';
    const defaultLeft = query['left'] || '0';
    const styleA = {
        '--ui-check-1920': `url('${bg1920}')`,
        '--ui-check-1366': `url('${bg1366}')`,
        '--ui-check-768': `url('${bg768}')`,
        '--ui-check-375': `url('${bg375}')`,
        '--ui-check-top': `${defaultTop}px`,
        '--ui-check-left': `${defaultLeft}px`,
    } as any;
    const [styleB, setStyleB] = useState(styleA);
    const [inputValue, setInputValue] = useState(defaultTop);

    useEffect(() => {
        if (isUiCheck) {
            setTimeout(() => {
                document.body.classList.add('ui-check-test');
            }, 300);
            const defaultTop2 = localStorage.getItem('--ui-check-mod-top') || defaultTop;
            setInputValue(defaultTop2);
            setStyleB({
                ...styleA,
                '--ui-check-top': `${defaultTop2}px`,
            });
        }
    }, []);
    // top 变化
    const topHandle = (e: any) => {
        const val = e.target.value;
        const styleB2 = {
            ...styleA,
            '--ui-check-top': `${val}px`,
        } as any;
        setInputValue(val);
        setStyleB(styleB2);
        localStorage.setItem('--ui-check-mod-top', val);
    };

    // 显示/隐藏
    const [hideA, setHideA] = useState(false);
    const toggleHandle = () => {
        const hideA2 = !hideA;
        setHideA(hideA2);
    };

    return (
        <>
            <dialog className={`ui-check-mod ${hideA ? 'ui-check-mod-hide' : 'ui-check-mod-show'}`} style={styleB}></dialog>
            <div className="ui-check-mod-opera">
                <input type="number" className="ui-check-mod-move" onChange={topHandle} value={inputValue} />
                <div className={'ui-check-mod-toggle'} onClick={toggleHandle}>
                    显示/隐藏
                </div>
            </div>
        </>
    );
}

export default UiCheck;
