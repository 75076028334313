'use client';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const int16ToHex = (i: any) => {
    let result = i.toString(16);
    let j = 0;
    while (j + result.length < 4) {
        result = `0${result}`;
        j++;
    }
    return result;
};

const bin2hex = (str: string) => {
    let result = '';
    for (let i = 0; i < str.length; i++) {
        result += int16ToHex(str.charCodeAt(i));
    }
    return result;
};

// 生成浏览器指纹id
export const getBrowserIdFromCanvas = () => {
    const canvas = document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');
    const txt = 'https://www.ecoflow.com';
    ctx.textBaseline = 'top';
    ctx.font = "14px 'Arial'";
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#f60';
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = '#069';
    ctx.fillText(txt, 2, 15);
    ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
    ctx.fillText(txt, 4, 17);

    const b64 = canvas.toDataURL().replace('data:image/png;base64,', '');
    const bin = atob(b64);
    const crc = bin2hex(bin.slice(-16, -12));
    return crc;
};

// 生成浏览器指纹id 方法2
export const generateFingerprint = () => {
    const canvas = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

    if (ctx) {
        // 生成 canvas 数据
        ctx.textBaseline = 'top';
        ctx.font = '14px Arial';
        ctx.fillText('Hello, World!', 2, 2);
    }

    const canvasFingerprint = canvas.toDataURL();

    // 收集其他信息
    const data = [navigator.userAgent, screen.width + 'x' + screen.height + 'x' + screen.colorDepth, navigator.language, canvasFingerprint].join('|');

    // 使用简单哈希生成唯一ID
    return crypto.subtle.digest('SHA-256', new TextEncoder().encode(data)).then((buffer) =>
        Array.from(new Uint8Array(buffer))
            .map((b) => b.toString(16).padStart(2, '0'))
            .join(''),
    );
};

// 生成浏览器指纹id 方法3
export const createFingerprint = async () => {
    const fp = await FingerprintJS.load();
    const { visitorId } = await fp.get();
    return visitorId;
};

// 生成浏览器指纹id
export const getEfDeviceId = async () => {
    if (typeof window !== 'undefined') {
        try {
            const deviceId1 = localStorage.getItem('ef-device-newid');

            if (deviceId1) {
                return deviceId1;
            }
            const deviceId2 = await createFingerprint();
            localStorage.setItem('ef-device-newid', deviceId2);
            return deviceId2;
        } catch (error) {}
    }
    return null;
};
