'use client';
export const getPageSource = () => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const source = params.get('utm_campaign') || params.get('ref');
    if (source) {
        return source;
    }

    return '';
};
