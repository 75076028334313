import type { IAnswerLog, IAwardLog, IQuestion, IQuestionInfo, ProductModule } from '@/components/AppreciationDay/index.d';
import { LevelLogItem } from '@/components/EcoCredits/type';
import type { ProductModule as EcoProductModule } from '@/components/EcocreditsMonthlyMadness/type';
import request from '@/utils/request';
import { getCookie } from 'lowcode/common/ABTestClient/ABTestCookie';
import { fetchPost } from 'lowcode/utils/service/FetchClient';
import { loginUrl } from './util';

export type ResponseObj<T = unknown> = {
    code: string;
    data: T;
    message: string;
};

/**
 * 账户积分详情
 */
export const getRewardUserInfo = (locale: string) =>
    request.get(`${loginUrl(locale)}/integral/web/rewardUserInfo/selectByUserId`, {
        needToken: true,
    });

/**
 * 积分兑换规则
 */
export const getRewardRedeemRule = (locale: string, pathUrl?: string) => {
    // console.log('getRewardRedeemRule', pathUrl);
    if (pathUrl) {
        return request.post(`${loginUrl(locale)}/integral/web/creditsDay/listCouponRule`, {
            data: {
                pathUrl,
            },
            needToken: true,
        });
    }
    return request.post(`${loginUrl(locale)}/integral/web/rewardRedeemRule/list`, {
        needToken: true,
    });
};

/**
 * 积分赚取规则
 */
export const getRewardEarnRule = (locale: string) =>
    request.post(`${loginUrl(locale)}/integral/web/rewardEarnRule/list`, {
        needToken: true,
    });

/**
 * 用户优惠券兑换记录
 */
export const getRewardRedeemCoupon = (locale: string) =>
    request.get(`${loginUrl(locale)}/integral/web/rewardRedeemCoupon/list`, {
        needToken: true,
    });

/**
 * 用户积分历史记录 sortType 1 按照失效时间正序 2 按照失效时间倒序
 */
export const getRewardHistory = (locale: string, sortType?: number) =>
    request.post(`${loginUrl(locale)}/integral/web/rewardHistory/page`, {
        data: { size: -1, sortType },
        needToken: true,
    });

/**
 * 转盘活动抽奖记录
 */
export const getLuckyHistory = (locale: string, drawId: string) =>
    request.post(`${loginUrl(locale)}/integral/web/rewardDraw/pageRewards`, {
        data: { size: -1, drawId },
        needToken: true,
    });

/**
 * 兑换积分为优惠券等
 */
export const rewardRedeemCoupon = (params: any, locale: string) =>
    request.post(`${loginUrl(locale)}/integral/web/rewardRedeemCoupon/redeem`, {
        data: params,
        needToken: true,
        timeout: 1000 * 20,
    });

/**
 * 查询issued和优惠券列表
 */
export const getIssuedCoupons = (locale: string) =>
    request.get(`${loginUrl(locale)}/integral/web/rewardRedeemCoupon/autoCouponListWithGiftCard`, {
        needToken: true,
        timeout: 1000 * 20,
    });

/**
 * 查询Rewards Activity 优惠券使用流水
 */
export const getRewardsActivity = (locale: string) =>
    request.get(`${loginUrl(locale)}/integral/web/rewardRedeemCouponLog/page`, {
        params: { size: -1 },
        needToken: true,
        timeout: 1000 * 20,
    });

/**
 * 查询福利中心列表接口
 */
export const getDiscountCouponList = (locale: string) =>
    request.get(`${loginUrl(locale)}/integral/web/rewardRedeemCoupon/discountCouponList`, {
        needToken: true,
    });

/**
 * 福利中心兑换接口
 */
export const rewardRedeemDiscounCoupon = (params: any, locale: string) =>
    request.get(`${loginUrl(locale)}/integral/web/rewardRedeemCoupon/discount/receive`, {
        params,
        needToken: true,
    });

/**
 * 查询实体产品规则列表
 */
export const queryFreeProductRuleList = (locale: string, pathUrl?: string) => {
    if (pathUrl) {
        return request.post(`${loginUrl(locale)}/integral/web/creditsDay/listFreeProductRule`, {
            data: {
                pathUrl,
            },
            needToken: true,
        });
    }
    return request.get(`${loginUrl(locale)}/integral/web/rewardRedeemRule/freeProductRuleList`, {
        needToken: true,
    });
};

/**
 * 查询第三方优惠券规则列表
 */
export const queryThirdCouponRuleList = (locale: string, pathUrl?: string) => {
    if (pathUrl) {
        return request.post(`${loginUrl(locale)}/integral/web/creditsDay/listThirdCouponRule`, {
            data: {
                pathUrl,
            },
            needToken: true,
        });
    }
    return request.get(`${loginUrl(locale)}/integral/web/rewardRedeemRule/thirdCouponRuleList`, {
        needToken: true,
    });
};

/**
 * 查询已兑换实体产品列表
 */
export const queryFreeProductList = (locale: string) =>
    request.get(`${loginUrl(locale)}/integral/web/rewardRedeemCoupon/freeProductList`, {
        needToken: true,
    });

/**
 * 查询已兑换第三方优惠券列表
 */
export const queryThirdCouponList = (locale: string) =>
    request.get(`${loginUrl(locale)}/integral/web/rewardRedeemCoupon/thirdCouponList`, {
        needToken: true,
    });

/**
 * 查询自动获取第三方优惠券列表
 */
export const queryIssuedThirdCouponList = (locale: string) =>
    request.get(`${loginUrl(locale)}/integral/web/rewardRedeemCoupon/thirdCouponListForIssued`, {
        needToken: true,
    });

/**
 * shopify-国家地区列表查询
 */
export const queryShopifyCountry = (locale: string) =>
    request.get(`${loginUrl(locale)}/shopify/web/shopifyCountry/selectWithProvince`, {
        needToken: true,
    });

/**
 * 获取shopify已保存的地址
 */
export const queryShopifySavedAddress = (locale: string) =>
    request.get(`${loginUrl(locale)}/shopify/web/customer/selectAddress`, {
        needToken: true,
    });

/**
 * 获取活动列表
 */
export const queryActivetyList = (locale: string, pathUrl?: string, id?: string) => {
    // if (pathUrl) {
    //     return request.post(`${loginUrl(locale)}/integral/web/creditsDay/listDraw`, {
    //         data: {
    //             // playWays: 1,
    //             pathUrl,
    //         },
    //         needToken: true,
    //     });
    // }
    return request.post(`${loginUrl(locale)}/integral/web/rewardDraw/list`, {
        data: {
            id,
            pathUrl,
        },
        needToken: true,
    });
};

/**
 * 根据id获取活动列表 isEditStatus区分是否在B端发起
 */
export const queryActivetyListById = (isEditStatus: boolean, locale: string, id: string) =>
    isEditStatus
        ? fetchPost(
              `${loginUrl(locale)}/integral/admin/rewardDraw/getRewardDrawById`,
              locale,
              { id },
              {
                  needToken: true,
                  headers: {
                      Authorization: `Bearer ${getCookie('token')}`,
                  },
              },
          )
        : request.post(`${loginUrl(locale)}/integral/web/rewardDraw/getRewardDrawById`, {
              data: { id },
              needToken: true,
          });

/**
 * 抽奖前先Check
 */
export const checkRewardDraw = (params: any, locale: string, pathUrl = '') => {
    if (pathUrl) {
        return request.post(`${loginUrl(locale)}/integral/web/creditsDay/checkDraw`, { data: params, needToken: true });
    }
    return request.post(`${loginUrl(locale)}/integral/web/rewardDraw/check`, { data: params, needToken: true });
};

/**
 * 抽奖
 */
export const rewardDraw = (params: any, locale: string, pathUrl = '') => {
    if (pathUrl) {
        return request.post(`${loginUrl(locale)}/integral/web/creditsDay/draw`, { data: params, needToken: true });
    }
    return request.post(`${loginUrl(locale)}/integral/web/rewardDraw/draw`, { data: params, needToken: true });
};

/**
 * 查询秒杀产品列表
 */
export const getListSecKillProducts = (locale: string) =>
    request.post(`${loginUrl(locale)}/shopify/web/creditsDay/listSecKillProducts`, { data: {}, needToken: true });

/**
 * 查询普通产品列表
 * (HOT-热门模块;NORMAL-常规模块;ECOLOGY-生态产品)
 */
export const getListProducts = (params: { module: ProductModule | EcoProductModule }, locale: string) =>
    request.post(`${loginUrl(locale)}/shopify/web/creditsDay/listProducts`, { data: params, needToken: true });

/**
 * 获取锁详情
 */
export const getLockInfo = (locale: string) => request.post(`${loginUrl(locale)}/integral/web/creditsDay/getLockInfo`, { data: {}, needToken: true });

/**
 * 获取锁详情
 */
export const unlock = (locale: string) => request.post(`${loginUrl(locale)}/integral/web/creditsDay/unLock`, { data: {}, needToken: true });

/**
 * 获取产品购买url
 */
export const getProductUrl = (id: string, locale: string) =>
    request.post(`${loginUrl(locale)}/shopify/web/creditsDay/getSecKillProductUrl`, { data: { id }, needToken: true });

/**
 * 查询答题活动
 */
export const getQuestionCampaign = (data: { pathUrl: string }, locale: string): Promise<ResponseObj<IQuestionInfo>> =>
    request.post(`${loginUrl(locale)}/integral/web/creditsDay/getQuestionCampaign`, { data, needToken: true });

/**
 * 根据活动id查询答题活动 isEditStatus 判断是否在编辑页面发起请求 调用管理后台接口
 */
export const getQuestionCampaignById = (isEditStatus: boolean, data: { campaignId: string }, locale: string): Promise<ResponseObj<IQuestionInfo>> =>
    isEditStatus
        ? fetchPost(`${loginUrl(locale)}/integral/admin/questionCampaign/getQuestionCampaignById`, locale, data, {
              needToken: true,
              headers: {
                  Authorization: `Bearer ${getCookie('token')}`,
              },
          })
        : request.post(`${loginUrl(locale)}/integral/web/creditsDay/getQuestionCampaignById`, { data, needToken: true });

/**
 * 查询活动题目
 */
export const getQuestionList = (
    data: {
        campaignId: string;
        questionGroupId: string;
    },
    locale: string,
): Promise<
    ResponseObj<{
        questionList: IQuestion[];
        rewardCouponCount: number;
        rewardTotalPoints: number;
    }>
> => request.post(`${loginUrl(locale)}/integral/web/creditsDay/listQuestion`, { data, needToken: true });

/**
 * 提交答案
 */
export const saveAnswer = (
    data: {
        answerFinish: boolean;
        campaignId: string;
        questionGroupId: string;
        questionId: string;
        optionList: {
            optionId: string;
            optionSelect: string;
        }[];
    },
    locale: string,
): Promise<ResponseObj<IQuestion>> => request.post(`${loginUrl(locale)}/integral/web/creditsDay/submitQuestion`, { data, needToken: true });

/**
 * 查询本题组累计获得积分
 */
export const getRewardTotalPoints = (
    data: {
        campaignId: string;
        questionGroupId: string;
    },
    locale: string,
): Promise<
    ResponseObj<{
        rewardCouponCount: number;
        rewardTotalPoints: number;
    }>
> => request.post(`${loginUrl(locale)}/integral/web/creditsDay/getRewardTotalPoints`, { data, needToken: true });

/**
 * 查询答题记录
 */
export const getAnswerLog = (
    data: {
        page?: number;
        size: number;
        campaignId: string;
    },
    locale: string,
): Promise<ResponseObj<IAnswerLog>> => request.post(`${loginUrl(locale)}/integral/web/creditsDay/pageAnswerLog`, { data, needToken: true });

/**
 * 查询奖励日志
 */
export const getAwardLog = (
    data: {
        page?: number;
        size: number;
        campaignId: string;
    },
    locale: string,
): Promise<ResponseObj<IAwardLog>> => request.post(`${loginUrl(locale)}/integral/web/creditsDay/pageAwardLog`, { data, needToken: true });

/**
 * 邮箱订阅
 */
export const subscribe = (email: string, locale: string, source: string): Promise<ResponseObj<any>> =>
    request.post(`${loginUrl(locale)}/website/subscribe`, {
        data: {
            email,
            source,
        },
        needToken: true,
    });

/**
 * 邮箱订阅-发起一个请求
 */
export const sendSubscribeGet = (email: string) => {
    const email2 = `${email}`;
    const url = `https://media.ecoflow.com/u/register.php?CID=1049983726&f=1237&p=2&a=r&SID=&el=&llid=&counted=&c=&optin=y&inp_3=${email2.trim()}&inp_4423=1&inp_7715=19&inp_8520=3`;
    if (typeof window !== 'undefined') {
        const elem = document.createElement('img');
        elem.classList.add('temp-hide');
        elem.src = url;
        document.body.appendChild(elem);
    }
};
/**
 * 查询是否在黑名单
 */
export const checkBlacklist = (
    locale: string,
): Promise<
    ResponseObj<{
        inBlackList: boolean;
    }>
> =>
    request.get(`${loginUrl(locale)}/integral/web/riskBlacklistInfo/getBlacklist`, {
        needToken: true,
    });

/**
 * 查询普通产品列表
 * (HOT-热门模块;NORMAL-常规模块;ECOLOGY-生态产品)
 */
export const getListUsualProducts = (params: { module: ProductModule | EcoProductModule }, locale: string) =>
    request.post(`${loginUrl(locale)}/shopify/web/creditsDay/listProducts`, { data: params, needToken: true });

/**
 * 会员等级规则查询
 */

export const getRewardRuleList = (
    locale: string,
): Promise<
    ResponseObj<
        {
            level: number;
            name: string;
            minPoints: string;
        }[]
    >
> =>
    request.get(`${loginUrl(locale)}/integral/web/rewardUserLevel/ruleList`, {
        needToken: true,
    });

/**
 * 会员等级查询
 */

export const getRewardUserLevel = (
    locale: string,
): Promise<
    ResponseObj<{
        level: number;
        validStartTime: string;
        validEndTime: string;
    }>
> =>
    request.get(`${loginUrl(locale)}/integral/web/rewardUserLevel`, {
        needToken: true,
    });

/**
 * 会员已获得权益列表查询
 */
export const getRightsLogList = (
    locale: string,
): Promise<
    ResponseObj<{
        inBlackList: boolean;
    }>
> =>
    request.get(`${loginUrl(locale)}/integral/web/rewardUserLevel/rightsLogList`, {
        needToken: true,
    });

/**
 * 会员等级变更日志查询
 */
export const getLevelLogList = (locale: string): Promise<ResponseObj<LevelLogItem[]>> =>
    request.get(`${loginUrl(locale)}/integral/web/rewardUserLevel/listForLevelLog`, {
        needToken: true,
    });

/**
 * 获取行为积分
 */
export const getPoints = (locale: string) =>
    request.get(`${loginUrl(locale)}/integral/web/behaviorRewardEarn/getPoints`, {
        needToken: true,
    });

/**
 * 修改行为积分
 */
export const getUserPointsByActions = (params: any, locale: string) =>
    request.post(`${loginUrl(locale)}/integral/web/behaviorRewardEarn/attention`, { data: params, needToken: true });

interface PlusMemberCustomerServiceReq {
    agreed: boolean;
    content: string;
    customerName: string;
    email: string;
    fileUrlList?: string[];
    orderId?: string;
    site: string;
}

/**
 * 日本plusMember客服 咨询表单
 */
export const plusMemberCustomerService = (locale: string, data: PlusMemberCustomerServiceReq) =>
    request.post(`${loginUrl(locale)}/website/customer/service/form/plusMember`, {
        data,
        needToken: true,
    });

/**
 * 获取行为积分
 */
export const getListCoupon = (locale: string, params: { ruleTypeList: string; useStatus: string }) => {
    // const queryString = new URLSearchParams({ ruleTypeList: params.ruleTypeList }).toString();
    return request.get(`${loginUrl(locale)}/integral/web/rewardRedeemCoupon/listCoupon`, {
        needToken: true,
        params,
    });
};

/**
 * 获取 new Discount
 */
export const getListnewPageDiscount = (locale: string, data: { page: number; size: number }) => {
    // const queryString = new URLSearchParams({ ruleTypeList: params.ruleTypeList }).toString();
    return request.post(`${loginUrl(locale)}/integral/web/referralPlan/newPageDiscount`, {
        needToken: true,
        data,
    });
};

/**
 * 获取 new gift card
 */
export const getListnewPageGiftCard = (locale: string, data: { page: number; size: number }) => {
    // const queryString = new URLSearchParams({ ruleTypeList: params.ruleTypeList }).toString();
    return request.post(`${loginUrl(locale)}/integral/web/referralPlan/newPageGiftCard`, {
        needToken: true,
        data,
    });
};

/**
 * 获取 newPagePoints
 */
export const getListnewPagePoints = (locale: string, data: { page: number; size: number }) => {
    return request.post(`${loginUrl(locale)}/integral/web/referralPlan/newPagePoints`, {
        needToken: true,
        data,
    });
};

/**
 * 获取 newPageCash
 */
export const getListnewPageCash = (locale: string, data: { page: number; size: number }) => {
    return request.post(`${loginUrl(locale)}/integral/web/referralPlan/newPageCash`, {
        needToken: true,
        data,
    });
};

/**
 * 查询站点最小提现金额配置 已重复三处
 */
export const getWalletMinAmount = (
    locale: string,
): Promise<
    ResponseObj<{
        withdrawalCurrency: string;
        withdrawalCurrencySymbol: string;
        withdrawalMinAmount: number;
    }>
> =>
    request.get(`${loginUrl(locale)}/order/payment/queryWithdrawalMinAmount`, {
        needToken: true,
    });

/**
 * 年度会员查询普通产品列表
 */
export const getMemberListProducts = (params: { variantIds: string[] }, locale: string) =>
    request.post(`${loginUrl(locale)}/website/ProductCaculation/productInfoList`, { data: params, needToken: true });

/**
 * 25年度会员查询福利中心列表接口
 */
export const getDiscountCouponListNew = (locale: string, cid?: string) => {
    const requestUrl = cid
        ? `${loginUrl(locale)}/integral/web/rewardRedeemCoupon/discountCouponListNew?campaignId=${cid}`
        : `${loginUrl(locale)}/integral/web/rewardRedeemCoupon/discountCouponListNew`;

    return request.get(requestUrl, {
        needToken: true,
    });
};

/**
 * 25年度会员查询福利中心控制接口
 */
export const getIsShowIcon = (cid: string, locale: string) => request.get(`${loginUrl(locale)}/integral/web/rewardCampaign/campaign/showIcon/${cid}`);

/**
 * 25年度会员查询福利中心控制接口2
 */
export const getIsShowIcon1 = (locale: string) => request.get(`${loginUrl(locale)}/integral/web/rewardCampaign/credits/showIcon`);

/**
 * 25年度会员查询福利中心列表接口2
 */
export const getDiscountCouponListNew1 = (locale: string, cid?: string) => {
    const requestUrl = cid
        ? `${loginUrl(locale)}/integral/web/rewardRedeemCoupon/discountCouponListNew1?campaignId=${cid}`
        : `${loginUrl(locale)}/integral/web/rewardRedeemCoupon/discountCouponListNew1`;

    return request.get(requestUrl, {
        needToken: false,
    });
};

export const getWebsiteTimezoneConfig = (locale: string, params: any) => {
    return request.get(`${loginUrl(locale)}/integral/web/websiteTimezoneConfig/list`, {
        params,
    });
};
