'use client';
import Image, { ImageProps } from 'next/image';
import { getFileInfo } from '@/utils';
import { useGlobal } from '@/utils/useGlobal';
import { handleDynamicPath } from '@/utils/handleDynamicPath';
import { useState } from 'react';

const getCheckStr = (url1: string) => {
    const index1 = url1.lastIndexOf('/');
    const index2 = url1.lastIndexOf('.');
    const name = url1.slice(index1 + 1, index2) || '';

    let str = '';
    if (name.length === 32) {
        str += '1';
    } else {
        str += name;
    }

    return str;
};

const CdnImage = ({
    src,
    alt,
    noUseSrcset = false,
    ...rest
}: ImageProps & {
    src: string | string[];
    noUseSrcset?: boolean;
}) => {
    //return <span>水合错误临时注释</span>
    const { isSupportWebp, isMobile } = useGlobal();

    // 如果是数组则判断是否支持webp，且需传一个备用图片
    if (Array.isArray(src)) isSupportWebp ? (src = src[0]) : (src = src[1]);

    const [loading, setLoading] = useState(true);
    const isAbsoluteUrl = /^(http|blob)/i.test(String(src));
    const info = getFileInfo(String(src));
    const props: any = {
        src: String(src),
        loading: 'lazy',
        ...rest,
    };

    const classList = info.ext === 'jpg' && loading ? ['imgLoadingBg', 'ef-old-image'] : ['ef-old-image'];
    if (!isAbsoluteUrl && ['png', 'jpg', 'jpeg', 'webp'].includes(info.ext) && !noUseSrcset) {
        const dpr = isMobile ? 3 : 2;
        const srcsetImage = handleDynamicPath(`${info.excludeExt}@${dpr}x.${info.ext}`, true);
        props.src = srcsetImage;
        classList.push('ef-old-image-build'); //用于脚本检测
    } else if (!isAbsoluteUrl) {
        classList.push('ef-old-image-build'); //用于脚本检测
        props.src = handleDynamicPath(String(src));
    }
    props.datasrc = getCheckStr(props.src); //用于脚本检测

    if (rest.className) {
        classList.push(rest.className);
    }
    props.className = classList.join(' ');

    props.priority && delete props.loading;

    return <Image alt={alt || 'EcoFlow'} unoptimized lazyBoundary="200px" onLoad={() => setLoading(false)} {...props} />;
};

export default CdnImage;
