import merge from 'lodash/merge';
import { useIntl, useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
export * from './I18nProvider';

// const isPrd = process.env.NEXT_PUBLIC_MODY === 'prd';

export const useLang = (key?: string, mode = false, globalLangAll: any = {}) => {
    const { pathname, locale } = useRouter();
    const intl = useIntl();
    let pathToLocale = pathname === '/' ? '/index' : pathname;

    pathToLocale = pathToLocale?.replace(/(\[|\]|\.\.\.)/g, '');
    const tempReg = /^\/[\w-]*/g;
    const tempPath = tempReg.exec(pathToLocale);
    if (tempPath) {
        pathToLocale = tempPath[0];
    } else {
        pathToLocale = '/index';
    }
    const langKey = key || pathToLocale?.replace(/[\W_]+/g, '');
    const t: any = useTranslations(langKey);
    const deepClone = (obj: any) => {
        if (typeof obj !== 'object') {
            return obj; //原始类型直接返回
        }
        const o = {} as any;
        for (const i in obj) {
            if (obj.hasOwnProperty(i)) {
                o[`${i}__edit__`] = deepClone(obj[i]);
            }
        }
        return o;
    };

    const langObj = globalLangAll.langObj || {};
    const langSharedObj = globalLangAll.langSharedObj || {};
    const langCustomObj = globalLangAll.langObjCustom || {};
    const langSharedObjCustom = globalLangAll.langSharedObjCustom || {};
    let langAll = {} as any;

    langAll = merge({}, langSharedObj['shared'], langObj[langKey], langCustomObj[langKey], langSharedObjCustom['shared']);

    // 用于查找缺失的文案,请不要删除
    // const tTemp: any = (k: string, options = {}) => {
    //     const val = t(k, options);

    //     if (!k || !val || typeof val !== 'string') {
    //         return '---------------';
    //     }
    //     return val;
    // };

    // 用于文案编辑,请不要删除
    // const tTemp: any = (k: string, options = {}) => {
    //     if (typeof get(langAll, k) !== 'undefined') {
    //         return mode ? t.rich(k, options) || '' : t(k, options) || '';
    //     } else {
    //         return '';
    //     }
    // };
    // Object.keys(t)?.forEach((key: any) => {
    //     tTemp[key] = t[key];
    // });
    // if (isPrd) {
    return {
        t,
        intl,
    };
    // }
    // return {
    //     t: tTemp,
    //     intl,
    // };
};

export const getAllLocaleMessage = async (pathname: string, locale: string, isTest = false) => {
    let pathToLocale = pathname === '/' ? 'index' : pathname;
    pathToLocale = pathToLocale?.replace(/(\[|\]|\.\.\.)/g, '');
    const tempReg = /^\/[\w-]*/g;
    const tempPath = tempReg.exec(pathToLocale);
    if (tempPath) {
        pathToLocale = tempPath[0];
    } else {
        pathToLocale = 'index';
    }

    const langSharedObjCustom = {};
    let langObjCustom = {};

    const [langSharedObj, langObj] = await Promise.all([
        dynamicImportLocales('locales/shared', locale),
        // 如果当前页当前语言有自定义的词条值，即 key 相同但内容与 en.json 不一致，比如电压、瓦数等
        dynamicImportLocales(`locales/${pathToLocale}`, locale),
    ]);
    if (isTest) {
        try {
            langObjCustom = dynamicImportLocales(`localesCustom/${pathToLocale}`, locale);
        } catch (e) {}
    }

    return {
        langSharedObj,
        langObj,
        langObjCustom,
        langSharedObjCustom,
    };
};

export const mergeLocales = (globalLangAll: any) => {
    return merge({}, globalLangAll.langObj, globalLangAll.langObjCustom, globalLangAll.langSharedObj, globalLangAll.langSharedObjCustom);
};

// 动态加载词条
export const dynamicImportLocales = async (path: string, locale: string) => {
    let data = {};

    // 去除多余斜杠
    const newPath = path
        .split('/')
        .filter((i) => i)
        .join('/');

    try {
        data = await import(`/src/${newPath}/${locale}.json`).then((item) => {
            return item.default;
        });
    } catch (e) {
        data = {
            _loadLangTextFail: true,
        };
        console.log('load locales error', e);
    }

    return { ...data };
};
